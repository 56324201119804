@import url("./Component/assets/css/common.css");
@import url("./Component/assets/css/layout.css");
@import url("./Component/assets/css/auth.css");
@import url("./Component/assets/css/dashboard.css");
.fullresponsive{
  overflow: hidden;
}
:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono",
    "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro",
    "Fira Mono", "Droid Sans Mono", "Courier New", monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(
      to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
      #ffffff80,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10,
      #ffffff10,
      #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}





.lazy-image-container {
  position: relative;
  width: 100%;
  height: auto;
}

.blur-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blur-image.hide {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.lazy-image-container img {
  width: 100%;
  height: auto;
}







.ps-section__content .col-6.col-sm-3{
  padding-right:4px !important;
  padding-left:4px !important;
}
.neww .job-table .view-btn {
  padding: 8px 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease, transform 0.5s ease-in-out;
  animation: blink 1.5s infinite alternate;
  }
 .bgnew{
  background-color: #f3f6fa !important;
}
.joblistcard .me-4 {
  margin-right: 1.5rem !important;
}
.joblistcard .py-30 {
  padding: 30px !important;
}
.blgDtl .banner-slider {
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  height: 245px;
  position: relative;
}

.blgDtl .bannder-table {
  display: table;
  width: 100%;
  height: 100%;
}

.blgDtl .bg {
  position: absolute;
  background-color: #313131;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.75;
}

.blgDtl .bannder-table {
  display: table;
  width: 100%;
  height: 100%;
}

.blgDtl .banner-text {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  position: relative;
}

.blgDtl .banner-text h1 {
  font-size: 34px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  margin: 0;
}

.joblistcard .card-img {
  width: 50px;
  height: 50px;
  min-width: 50px;
  background-color: transparent !important;
  border-radius: 10px;
  padding: 0 !important;
  margin: 0 auto;
}
.joblistcard .card .card-img {
  width: 60px;
  height: 60px;
  object-fit: cover;
}


.ppr .product-title {
  font-size: 24px;
  font-weight: bold;
}

.ppr .product-price {
  color: #B12704;
  font-size: 28px;
  margin-top: 10px;
}

.ppr .product-rating {
  margin-top: 10px;
}
.similorJobBox {
  border-radius: 5px;
  margin-bottom: 10px;
  background: #ffffff;
  padding: 5px;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
}
.userd{
  backface-visibility: hidden;
  transform-style: preserve-3d;
  position: relative;
  /* background: #00c0ef ; */
  z-index: 0;
  height: 100%;
  min-height: 67px;

  /* width: 100%; */
  border-radius: 5px;
  /* transform: rotateX(-180deg); */
  transition: all 0.5s ease-out 0s;
}
.userd1{
 
  background: #00c0ef ;
 
}
.userd2{
 
  background: #00bf86 ;
 
}
.userd3{
 
  background: #ffa000  ;
 
}
.userd4{
 
  background: #ff8086   ;
 
}
.userd5{
 
  background: #015CFF   ;
 
}
.userd6{
 
  background: #0B8591   ;
 
}
.userd7{
 
  background: #798D8F   ;
 
}
.userd8{
 
  background: #4F4D4D   ;
 
}


/* ps-cart--mini */
.ps-cart--mini .sidebar {
  position: fixed;
  top: 0px;
  right: 50px;
  transform: translateX(50px);
}

.ps-cart--mini .sidebar_icon {
  position: absolute;
    top: 0;
    right: 0px;
  background-color: transparent;
  color: blue;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.ps-cart--mini .sidebar_icon:hover {
  background-color: transparent;
}

.ps-cart--mini .sidebar_icon i {
  font-size: 1.7rem;
}

.ps-cart--mini .sidebar_items {
 
  background-color: #ffffff;
  color: white;
  width: 50vw;
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-top: 50px;
  
height: 100vh;
}

.s2 {
  background-color: transparent !important;
}

.ps-cart--mini .sidebar_item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.ps-cart--mini .sidebar_item:hover {
  background-color: #555;
}

.ps-cart--mini .sidebar_item i {
  font-size: 1.3rem;
  margin-right: 10px;
}

.ps-cart--mini .sidebar_item p {
  font-size: 1rem;
}

.weather_section {
  background: radial-gradient(ellipse at center, #b5e2e5 0%, #49c8d1 100%);
  margin-top: 25px;
  min-height: 206px;
}
.weather_section2  {
  background: radial-gradient(ellipse at center, #ffffff 0%, #e4fdff  100%);
  margin-top: 25px;
  min-height: 206px;
  box-shadow: 4px 4px 11px rgba(0, 0, 0, 0.2);
}

/* .weather_section2  {
  background: linear-gradient(to right, #fff, #e4fdff);
  margin-top: 25px;
  min-height: 206px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
} */
.dashbordTextnew{
  font-size: 1.5rem;
  font-weight: 600;
  color: #FFFFFF;
}

.ppr .product-description {
  margin-top: 20px;
}

.ppr .btn-buy {
  margin-top: 20px;
}

.ppr .thumbnail-img {
  width: 100%;
  height: 100px;
}

.ppr .thumbnail-list img {
  cursor: pointer;
  margin-bottom: 10px;
}

.ppr .offer-box {
  border: 1px solid #ddd;
  padding: 15px;
  margin-bottom: 10px;
}

.ppr .color-box {
  width: 25px;
  height: 25px;
  display: inline-block;
  border: 1px solid #ddd;
  margin-right: 5px;
  cursor: pointer;
}

.ppr .img-fluid {
  max-width: 100%;
  height: 350px;
}

.neww .jbhead h1{
  font-size: 3.5rem ;
  color: #000000;
  font-weight: 600;
  margin-top: 20px;
}
.neww  h1{
  font-size: 3.5rem !important;
  color: #000000;
  font-weight: 600;
}
.neww .jbhead p{
 
  color: #000000;
 
}

.animated_text {
  font-size: 2em;
  animation: color-change 3s infinite;
  text-align: center;
}

.gradient-bg {
  background-image: linear-gradient(to right, rgba(41, 128, 185, 1), rgba(246, 185, 59, 1));
  color: #fff;
  background-size: 200% auto;
  transition: background-position 0.3s ease;
  position: relative;
  z-index: 1;
  border-radius: 20px;
}
@keyframes color-change {
  0% { color: #ff0000; }   /* Red */
  25% { color: #00ff00; }  /* Green */
  50% { color: #0000ff; }  /* Blue */
  75% { color: #ffff00; }  /* Yellow */
  100% { color: #ff0000; } /* Red */
}

.lost_fount_form {
  box-shadow: 0px 0px 13px 7px #FFEB3B, 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background: #5f9ea04f;
}

.lost_fount_form2 {
  box-shadow: 0px 0px 13px 7px #FFEB3B, 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background: #ffffff;
}
.jbimg{
  margin: auto;
}
.ht-350{
  height: 380px;
}

html,
body {
  max-width: 100vw;
  /* overflow-x: hidden; */
}
.recentlisting {
  max-width: 300px;
  
}


.seal {
  position: absolute;
  top: -1%;
  right: 0%;
  width: 75px;
  height: 80px;
  color: white;
  /* background-color: rgb(14, 206, 62); */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}
.aboutusimgevender {
  width: 100%;
  height: 300px;
}


/* neeraj */
.menu_p_tag {
  font-size: 12px;
}

.table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 3D effect */
}
.table th,
.table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.apply_button {
  background-color: #28a745;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  animation: dim 1.5s linear infinite; /* Continuous dimming effect */
}
@keyframes dim {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
.buttons_backgroun_row {
  background: #3dd60042;
}
.jobportal .search-job .form-control {
  margin: 3px 0px;
}
.jobportal .bannerimages {
  height: 220px !important;
 
}
.my_buttons_zise {
  font-size: 13px;
}
  
.job_date_size {
  font-size: 9px;
  text-align: center;
}

.jobportalInputsSearch{
  height:30px !important;
}

.jobportalInputs {
  padding: 10px;
  border: 3px solid;
  height:30px !important;
  border-radius:0% !important;
  outline: none;
  background: transparent;
  animation: border-move 3s linear infinite;
}


@keyframes border-move {
  0% {
      border-image: linear-gradient(45deg, #f3ec78, #af4261) 1;
  }
  100% {
      border-image: linear-gradient(405deg, #af4261, #f3ec78) 1;
  }
}
.my_icons_list {
  font-size: 25px;
}

.animated_card {
  max-width: 300px;
  width: 300px;
  height: 270px;
  position: relative;
  border: 5px solid transparent;
  border-radius: 10px;
  animation: border-animation 2s infinite;
}

.blankcard {
  width: 300px;
  height: 330px;
  position: relative;
  border: 5px solid transparent;
  border-radius: 10px;
}

@keyframes border-animation {
  0% {
    border-color: orange;
  }

  25% {
    border-color: blue;
  }

  50% {
    border-color: green;
  }

  75% {
    border-color: yellow;
  }

  100% {
    border-color: blueviolet;
  }
}

.icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 30%;
  color: white;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.icon-button:before {
  content: "";
}

.card.lost_found .card-title{
  text-transform: capitalize;
  font-weight: 700;
  font-size: 25px;
}
.vendershopimage {
  width: 100%;
  height: 250px;
}



/* .seal {
  position: absolute;
  top: -1%;
  left: -2%;
  width: 100px;
  height: 80px;
  color: white;
  
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
} */

.mevenderfetureproduct {
  width: 100%;
  height: 280px;
}

.aboutusimgevender {
  width: 100%;
  height: 300px;
}
/* .teammemberimage {
  width: 100%;
  height: 300px;
  border-radius:5%;
  border: 5px solid #ffd11a;
} */
.my_card_image {
  width: 100%;
  height: 150px;
}

.my_videos {
  width: 600;
  height: 300px;
}



.teammemberimage {
  width: 100%;
  height: 300px;
  border-radius: 5px;
  object-fit: cover; /* Ensures the image covers the area without being cut off */
  margin-bottom: 10px;
}

.team-member {
  text-align: center;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.21);
  transition: transform 0.3s ease-in-out;
  margin-bottom: 20px;
}

.team-member:hover {
  transform: translateY(-10px);
}

.team-member h3 {
  font-weight: 700;
  margin: 0;
}

.team-member .role {
  margin-bottom: 10px;
}

.fa {
  font-size: 12px;
  margin: 0 3px;
  /* color: #000; */
  transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
  text-decoration: none;
}

.team-member:hover .fa {
  transform: scale(1.2);
  color: #007AFF;
}










.all_cat_line {
  border-right: 2px solid black;
}
.all_category-image {
  width: 100%;
  height: auto;
  border-radius: 50%;
  object-fit: cover;
}

.cat_baner_image {
  height: 350px;
  width: 100%;
}
.my_all_cat_images {
  width: 100%;
  height: 100px;
  border-radius: 10px 10px 0px 0px;
}
.shop_by_cat {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("https://4.bp.blogspot.com/-EwGV_6WAEo4/UFR7fA8lMHI/AAAAAAAAAzE/K_wTOb6yiNA/s400/Wave5-1200x800-.jpg");
  background-size: cover; /* Make sure the image covers the entire background */
  background-position: center; /* Center the image */
  padding: 16px;
  border: 2px solid #dc3545; /* Border danger */
}
.sheller_card_image {
  background: #8e154a;
}
.subcategcard_back {
  /* background: linear-gradient(45deg, #e785e8, #18edef); */
}
.sub_cat_product_image {
  width: 100%;
  height: 250px;
}
.all_product_image_size {
  width: 100%;
  height: 250px;
}

#product-info {
  display: flex;
  width: 100%;
  margin-top: 3%;
  background: #f6efefd9;
}

.item-image-parent {
  order: 1;
  width: 40%;
  display: flex;
}

.item-info-parent {
  order: 2;
  width: 50%;
}

.item-list-vertical {
  order: 1;
  width: 10%;
  overflow-y: auto;
  margin-top: 50px;
}

.item-image-main {
  order: 2;
  width: 91%;
  height: 400px;
  margin-left: 5%;
}

/* sectioning completed */

.thumb-box {
  width: 75%;
  margin: 10px auto;
  background: white;
  border: 1px solid gray;
}
.thumb-box img {
  width: 100%;
  height: 100%;
}

.item-image-main img {
  width: 95%;
  height: 400px;
}

.thumb-box:hover {
  cursor: pointer;
  border-color: #e77600;
  box-shadow: 0px 1px 5px 1px #e77600;
}

/* Item Info */
.main-info h4 {
  font-size: 21px;
  margin-bottom: 0;
  font-weight: 400;
}

.main-info p {
  font-size: 15px;
  color: gray;
  margin-bottom: 30px;
}

#price {
  color: rgb(168, 14, 14);
  font-size: 21px;
}

.licebox {
  background: #ffffff;
  padding: 20px;
}
.liceboxnew {
  background: #ffffff;
  padding: 15px;
  box-shadow: 0px 0px 22px 0px #dcdcdc;
  border-radius: 5px;
}

.ps-form--search-mobile {
margin-bottom: 5px;
}


.all_categoryimagetest {
  margin: auto;
  width: 100px;
  display: block;
}


.all_category-image {
  margin: auto;
  width: 100px;
  height:100px;
  border-radius: 50%;
  object-fit: cover;
}
.ps-form--account2 {
  /* max-width: 785px; */
  /* margin: 0 auto; */
  margin-top: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.registerfm{
  padding:  10px 30px 30px 30px;
  background: #ffffff;
}
.liceninputvs{
  margin-top: 4px;
  height: 30px;
}
.liceninputvs input{
  height: 30px;
}
.liceninputvs .css-1nmdiq5-menu{
  /* height: 30px; */
  min-width: 350px;
}

.my_videos {
  width: 600;
  height: 300px;
}

@media screen and (max-width: 600px) {
  .my_videos {
    width: 600;
    height: 250px;
  }
}

.btn-dark1 {      
  color: #fff;
  background-color: #256C34;
  border-color: #343a40;
  font-size: 20px;
}

.btn-dark1:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.ps-section__content.catetext {
  text-align: center;
}

.ps-product__content {
  padding: 8px;
  padding-top: 2px;
  min-height: 70px;
}
.catetext .ps-product__content {
  padding: 8px;
  padding-top: 2px;
  min-height: 40px;
}
.ps-product .ps-product__thumbnail {
  border-radius: 25px;
}

.hometest .ps-deal-of-day .ps-product .ps-product__price {
  margin-bottom: 1px;
}
.ps-product .ps-product__vendor {
  margin-bottom: 1px;
}

.ps-product .ps-product__content > p {
  margin-bottom: 2px;
}

.lostDate {
  height: 20px;
  margin-bottom: 1px;
  font-size: 15px;
}
.allpro {
  overflow: hidden;
  background-image: linear-gradient(to right bottom, #ff8000, #fff, green);
}

/* body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
} */

a {
  color: inherit;
  text-decoration: none;
}

.productPage__left {
  /* height: 100vh; */
  top: 0px;
  /* width: 60vw; */
  display: flex;
  padding: 20px;
  margin-top: 10px;
  position: sticky;
  /* background-color: #e4e4e4; */
  text-align: center;
  align-items: center;
  flex-direction: column;
}

.headerboxnew {
  min-width: 190px !important;
  padding:5px;
  border: 1px solid rgb(183, 183, 183);
}
.ps-product-list .ps-section__header1 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding: 6px 8px;
  /* background-color: #f4f4f4; */
  /* border-bottom: 1px solid #e3e3e3; */
}

.ps-product-list .ps-section__header1 h3{
 margin-bottom: 0px;
 font-weight: 800;
}

.homeLogoicon{
  width: 230px;
  margin-left: 30px;
}
.homeLogoicon2{
  width: 230px;
  margin-left: 0px;
}
.homeLogoicon1 {
  width: 120px;
  margin-left: 11px;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

.adsboxcus {
  max-height: 200px;
}
.adsboxcus1 {
  max-height: 280px;
}

@media only screen and (max-width: 600px) {
  .seven {
    font-size: 5px;
    height: auto;
  }

  .animated_card .profile-name{
font-size: 15px;
  }
  .animated_card h5{
font-size: 12px;
  }
  .gradient-color-text {
    background-size: 400% 400%;
    background-image: linear-gradient(to right, #ff8000, #fff, green, white, #ff8000, white, green);
    -webkit-background-clip: text;
    animation: text-color-gradient 15s ease infinite;
    color: transparent;
    font-size: 22px;
    font-family: impact;
}

.ps-block--user-header .ps-block__left a{
  /* width: 15px !important; */
}
.ps-block--user-header .ps-block__left .lgn{
  /* width: 38px !important; */
}
.header--mobile .header__actions i {
  font-size: 22px !important;
}

.lgn1 {
  background: rgb(47, 0, 255);
  padding: 5px 8px;
  margin-right: 3px;
  color: #ffffff;
  width: 50px !important;
}
a.lgn2 {
  background: rgb(1, 152, 41);
  padding: 5px 8px;
  margin-right: 3px;
  color: #ffffff;
  width: 65px !important;
}
 
.header .header__extra span {
  position: absolute;
  bottom: 17px;
  right: -6px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  vertical-align: top;
  width: 15px;
  height: 15px;
  color: #ffffff;
  background-color: #000000;
  border-radius: 50%;
}

.ps-search--mobile {
  max-width: 450px;
  margin: 0 auto;
  padding: 0px 20px;
}

.btn-dark1 {
  color: #fff;
  background-color: #256C34;
  border-color: #343a40;
  font-size: 9px;
}
.header--mobile .navigation--mobile {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding:0px 10px;
  border-bottom: none;
}
.header--mobile .header__extra span i {
  color: #ffffff;
  font-weight: 600;
  font-size: 13px !important;
}
}
.seven {
  background: #fcb800;
  color: white;
  border-radius: 5px;
  transition: all 300ms;
  border: none;
  width: 100%;
  height: 07vh;
  font-size: 13px;
}

.seven:hover {
  transform: scale(1.1);
  letter-spacing: 2px;
  background: white;
  color: #fcb800;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.57);
  width: 100%;
  border-bottom: 5px solid;
  border-top: 5px solid;
}

.ps-product:hover {
  border: none;
}

/* .ps-product .ps-product__thumbnail img {
  width: 100px;
  height: 100px;
  margin: auto;
} */

.hometest .ps-home-banner {
  /* padding-top: 30px;
  margin-bottom: 30px; */
}

.hometest .ps-home-banner .ps-banner {
  min-height: 480px;
}
.hometest .ps-home-banner .ps-banner.cateslider {
  min-height: 364px;
}

.hometest .ps-home-banner .ps-collection {
  display: block;
  margin-bottom: 30px;
}

.hometest .ps-home-banner .ps-collection:last-child {
  margin-bottom: 0;
}

.hometest .ps-home-banner {
  max-width: 100%;
  height: 100%;
}

.hometest .ps-home-banner .owl-stage-outer {
  height: 100%;
}

.hometest .ps-home-banner .owl-stage-outer .owl-stage {
  height: 100%;
}

.hometest .ps-home-banner .owl-stage-outer .owl-stage .owl-item {
  height: 100%;
}

.hometest .ps-home-banner .ps-banner {
  height: 100%;
}

.hometest .ps-home-banner .ps-section__left {
  /* padding-right: 30px; */
  /* max-width: calc(100% - 360px); */
}

.hometest .ps-home-banner .ps-section__right {
  max-width: 396px;
  max-height: 369px;
}

.hometest .ps-home-banner .ps-container {
  display: flex;
  flex-flow: row nowrap;
}

.rainbow-btn {
  margin: 2px auto;
  width: 200px;
  text-align: center;
  position: relative;
  color: #ffffff;
  text-decoration: none;
  border-radius: 6px;
  box-sizing: border-box;
  display: block;
  z-index: 2;
  overflow: hidden;
  padding: 8px;
  height: 51px;
}
.rainbow-btn:hover {
  margin: 2px auto;
  width: 200px;
  text-align: center;
  position: relative;
  color: #ffffff;
  text-decoration: none;
  border-radius: 6px;
  box-sizing: border-box;
  display: block;
  z-index: 2;
  overflow: hidden;
  padding: 8px;
  height: 51px;
}
.rainbow-btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 100%;
  background: linear-gradient(
    115deg,
    #4fcf70,
    #fad648,
    #a767e5,
    #12bcfe,
    #44ce7b
  );
  background-size: 50% 100%;
}
.rainbow-btn span {
  position: relative;
  z-index: 2;
  padding: 2px 0;
  font-size: 1.1rem;
  text-decoration: none;
  align-items: center;
  background: linear-gradient(
    115deg,
    #0c0a02,
    #174a9d,
    #fe5812,
    #12bcfe,
    #e5093e
  );
  border-radius: 3px;
  display: block;
  justify-content: center;
  box-sizing: border-box;
  height: 100%;
  font-size: 20px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.rainbow-btn:focus:before,
.rainbow-btn:hover:before {
  animation: rainbow-btn 0.75s linear infinite;
}
.wkim{
  display: block;
  border-radius: 50%;
  border: 6px solid transparent;
  animation: border-animation2 2s infinite;
}


 .wkim::before,  .wkim::after {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  border: 2px solid darkgreen;
  transition: all 0.6s;
  -webkit-animation: clippath 6s infinite linear;
          animation: clippath 6s infinite linear;
  border-radius: 50%;
}

.wkim::after {
  -webkit-animation: clippath 3s infinite -1.6s linear;
          animation: clippath 3s infinite -1.6s linear;
}
@keyframes border-animation2 {
  0% {
    border-color: #FBC215;
  }

  25% {
    border-color: #02B453;
  }

  50% {
    border-color: #492DA1;
  }

  75% {
    border-color: #FAD2C9;
  }

  100% {
    border-color: #ED343A;
  }
}

.blankcard {
  width: 300px;
  height: 330px;
  position: relative;
  border: 5px solid transparent;
  border-radius: 10px;
}


@keyframes rainbow-btn {
  to {
    transform: translateX(-50%);
  }
}
.hometest .ps-home-banner .ps-container > * {
  width: 100%;
}

@media (max-width: 1199px) {
  .hometest .ps-home-banner {
    display: block;
  }
  .hometest .ps-home-banner .ps-collection {
    width: 100%;
    /* max-width: 50%; */
    padding: 0 15px;
  }
  .hometest .ps-home-banner .ps-collection img {
    width: 100%;
  }
  .hometest .ps-home-banner .ps-banner {
    min-height: 320px;
}
.hometest .ps-home-banner .ps-banner.cateslider {
  min-height: 300px;
}
  .hometest .ps-home-banner .ps-section__left {
    max-width: 100%;
    padding-right: 0;
    margin-bottom: 20px;
  }
  .hometest .ps-home-banner .ps-section__right {
    /* display: flex;
    flex-flow: row nowrap; */
    margin: 0;
    max-width: 100%;
  }
  .hometest .ps-home-banner .ps-container {
    flex-flow: column nowrap;
  }
}
.win_pra p:nth-child(1) {
  color: rgb(50, 0, 233);
}

.win_pra p:nth-child(2) {
  color: rgb(0, 0, 0);
}

.win_pra p:nth-child(3) {
  color: rgb(255, 2, 2);
}
.categorycom img {
  width: 100px;
  height: 100px;
  margin: auto;
  margin-top: 4px;
}

.gradient-bg:hover, .gradient-bg.active {
  background-position: right center;
  color: #fff;
}
.ps-form--account .ps-tab-list li {
  display: inline-block;
  padding: 0 15px;
}
.tbbtn {
  border: 4px solid transparent;
  transition: border-color 0.3s ease;
  background-color: #fff;
  border-radius: 20px;
}
.active.tbbtn{
  background-color: #000000;
 
}
.active.tbbtn a{
 
  color: #ffffff !important;
}


.animated_card {
  width: 300px;
  height: 270px;
  position: relative;
  border: 5px solid transparent;
  border-radius: 10px;
  animation: border-animation 2s infinite;
}




@keyframes border-animation {
  0% {
    border-color: orange;
  }

  25% {
    border-color: blue;
  }

  50% {
    border-color: green;
  }

  75% {
    border-color: yellow;
  }

  100% {
    border-color: blueviolet;
  }
}



.my_card_image {
  width: 100%;
  height: 150px;
}


.hometest .ps-site-features {
  padding-bottom: 90px;
}

.hometest .ps-home-banner {
  /* max-height: 372px; */
  overflow: hidden;
}

.hometest .ps-deal-of-day {
  padding-bottom: 80px;
  padding: 10px 0px;
}

.hometest .ps-deal-of-day .ps-product .ps-product__price {
  color: #690;
  font-size: 18px;
  font-weight: 600;
}

.shewBox .ps-product {
  margin-bottom: 10px;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.hometest .ps-deal-of-day .ps-product .ps-product__price del {
  margin-left: 10px;
  font-size: 14px;
}

.hometest .ps-deal-of-day .ps-product .ps-product__price small {
  margin-left: 10px;
  color: red;
}

.hometest .ps-top-categories {
  padding: 80px 0 50px;
}

.hometest .ps-top-categories h3 {
  text-transform: capitalize;
}

@media (min-width: 1440px) {
  .hometest .ps-top-categories .row .col-xl-2 {
    max-width: calc(100% / 8);
  }
}

@media (max-width: 1199px) {
  .hometest .ps-top-categories {
    padding: 60px 0;
  }
}

@media (max-width: 991px) {
  .hometest .ps-top-categories {
    padding: 50px 0;
  }

  .hometest .ps-home-banner .ps-banner {
    min-height: 259px;
}
.hometest .ps-home-banner .ps-banner.cateslider {
  min-height: 230px;
}
.lostdetailsads_section {
  display: none;
}
}
.lost-option {
  background-color: red;
  color: #ffffff;
}
.found-option {
  background-color: green;
  color: #ffffff;
}
@media (max-width: 767px) {
  .hometest .ps-top-categories {
    padding: 40px 0;
  }
  .hometest .ps-home-banner .ps-banner {
    min-height: 227px;
}

.hometest .ps-home-banner .ps-banner.cateslider {
  min-height: 215px;
}
.lostdetailsads_section {
  display: none;
}
  /* .btn-dark1 {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
    font-size: 14px;
} */
}

@media (max-width: 600px) {
  .hometest .ps-home-banner .ps-banner.cateslider {
    min-height: 160px;
  } 
}
@media (max-width: 479px) {
  .hometest .ps-top-categories {
    padding: 35px 0;
  }
  .vendershopimage{
    width:100%;
    height:115px;
  }
  
  .input_padding {
    padding-right: 2%;
  }
  .rainbow-btn {
    margin: 2px auto;
    width: 120px;
    text-align: center;
    position: relative;
    color: #ffffff;
    text-decoration: none;
    border-radius: 6px;
    box-sizing: border-box;
    display: block;
    z-index: 2;
    overflow: hidden;
    padding: 7px;
    height: 45px;
  }

  .animated_card {
    height: 270px !important;
    height: 200px;
    position: relative;
    border: 5px solid transparent;
    border-radius: 10px;
    animation: border-animation 2s infinite;
  }
  /* .slick-track {
    height: 280px;
  } */

  @keyframes border-animation {
    0% {
      border-color: orange;
    }

    25% {
      border-color: blue;
    }

    50% {
      border-color: green;
    }

    75% {
      border-color: yellow;
    }

    100% {
      border-color: blueviolet;
    }
  }
  .vendershopimage{
    width:100%;
    height:100px;
  }
  .aboutusimgevender {
    width: 100%;
    height: 200px;
  }
  .jobportalInputsSearch{
    height:30px !important;
  }
  .jobportalInputs {
    padding: 10px;
    border: 3px solid;
    height:30px !important;
    border-radius: 50%;
    outline: none;
    background: transparent;
    animation: border-move 3s linear infinite;
  }
  
  
  @keyframes border-move {
    0% {
        border-image: linear-gradient(45deg, #f3ec78, #af4261) 1;
    }
    100% {
        border-image: linear-gradient(405deg, #af4261, #f3ec78) 1;
    }
  }
  .teammemberimage {

    height: 150px;
    border-radius:5px;
    border: 5px solid #ffd11a;
  }
  .mevenderfetureproduct{
    width: 100%;
    height: 150px;
  }
  .my_videos {
    width: 600;
    height: 300px;
  }
  .all_category-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }

  @media screen and (max-width: 600px) {
    .my_videos {
      width: 600;
      height: 250px;
    }
  }
  .cat_baner_image {
    height: 200px;
    width: 100%;
  }
  .shop_by_cat {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("https://4.bp.blogspot.com/-EwGV_6WAEo4/UFR7fA8lMHI/AAAAAAAAAzE/K_wTOb6yiNA/s400/Wave5-1200x800-.jpg");
    background-size: cover; /* Make sure the image covers the entire background */
    background-position: center; /* Center the image */
    padding: 16px;
    border: 2px solid #dc3545; /* Border danger */
  }
  .my_all_cat_images {
    width: 100%;
    height: 100px;
    border-radius: 10px 10px 0px 0px;
  }
  .sub_cat_product_image {
    width: 100%;
    height: 100px;
  }
  .all_product_image_size {
    width: 100%;
    height: 200px;
  }


  #product-info {
    flex-direction: column;
    padding: 5%;
  }
  .hometest .ps-home-banner {
    /* max-height: 126px; */
    overflow: hidden;
}
  .item-image-parent,
  .item-info-parent {
    width: 100%;
    text-align: center;
  }
  .mt-5, .my-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}
}

.hometest .ps-product-list {
  /* padding-bottom: 80px; */
}

@media (max-width: 991px) {
  .hometest .ps-product-list {
    /* padding-bottom: 50px; */
  }
}

@media (max-width: 767px) {
  .hometest .ps-product-list {
    /* padding-bottom: 40px; */
  }
}

@media (max-width: 479px) {
  .hometest .ps-product-list {
    /* padding-bottom: 0px; */
  }
  .frame {
    height: 110px;
    width: 110px;
    background-image: url(https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcRatlWz4sm2SK39eEsHp_SYLv4Ggc5G84yLrydRWMY1Ws4sjBN4);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin: auto;
  }

  .ht-350{
    height: 355px;
  }
  .winner-img {
    height: 55px;
    width: 55px;
    border-radius: 100px;
    margin-left: 1px;
    margin-top: 27px;
  }
}

.hometest .ps-download-app {
  margin-top: 80px;
  margin-bottom: 100px;
}

@media (max-width: 767px) {
  .hometest .ps-download-app {
    margin-bottom: 50px;
  }
}

.ps-tab-list {
  text-align: center;
  margin-bottom: 30px;
}

.ps-tab-list li {
  display: inline-block;
  padding: 0 15px;
}



.alljoblist .job-card {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  position: relative;
}

.alljoblist .job-card .badge {
  position: absolute;
  top: 20px;
  left: 20px;
}

.alljoblist .job-card .heart-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  color: green;
  font-size: 20px;
}

.alljoblist .job-card .apply-btn {
  width: 100%;
}

.alljoblist  .card {
  padding: 15px 5px 10px 5px;
  box-shadow: 0 5px 25px 0 rgba(41, 128, 185, 0.15);
}

.alljoblist .form-control:hover {
  border-color: green;
}

.alljoblist .card_image {
  width: 27%;
  border-radius: 50%;
  height: 27%;
}
.lostfdhe{
  font-weight: 700;
}
.alljoblist .myshedow {
  box-shadow: 0 5px 25px 0 rgba(41, 128, 185, 0.15);
}

.alljoblist  .my_font {
  font-size: 12px;
}

.alljoblist  .pagination {
  justify-content: flex-end;
}

.alljoblist .page-link {
  color: green;
}

.alljoblist .page-link:hover {
  color: white;
  background-color: green;
}

@media only screen and (max-width: 600px) {
  .alljoblist  .card_image {
    width: 40%;
    border-radius: 50%;
    height: 27%;
    margin-top: 21%;
  }
}

.ps-tab-list li.active a {
  color: #000;
}

.ps-tab-list li a {
  font-size: 30px;
  color: #666;
  font-weight: 600;
}

/*   section lister      */

.card {
  width: 100%;
  border-radius: 5px;
}
.over_img {
  height: 150px;
  width: 100%;
}

.profile {
  border-radius: 80px;
  padding: 18px 6px;
  border: none;
  color: white;
  font-size: 13px;
}
.like {
  border: none;
  border-radius: 80px;
  padding: 12px 13px;
  border: none;
  color: white;
  font-size: 13px;
}
.icon_lf {
  margin: -18px 0px 0px 0px;
}

.profile-name {
  font-size: 20px;
  margin: 11px 0 0 12px;
  color: black;
}
small {
  margin-left: 10px;
  color: grey;
  font-size: 15px;
  line-height: 25px;
}

.ps-product .ps-product__content:hover .ps-product__title {
  color: #fcb800;
}
.recentlistor {
  padding-right: 2px;
  padding-left: 2px;
}
.recentlostfound {
  padding-right: 7px;
  padding-left: 7px;
}
.videoBox {
  padding-right: 15px;
  padding-left: 15px;
}

.ps-section__header {
  /* margin-bottom: 65px; */
  padding-bottom: 10px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  border: 1px solid blue;

  padding: 2px 8px;
}
.ps-section__header2 {
  text-align: center;
  padding-bottom: 10px;
}


.cardvm {
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.cardvm .input-group {
  margin-bottom: 10px;
}

.cardvm .btn-dark {
  background-color: #343a40;
  border-color: #343a40;
}

.cardvm .btn-dark:hover {
  background-color: #23272b;
  border-color: #1d2124;
}
.ps-product__price.prDt{
  margin-bottom: 0px !important;
}
.prosection {
  margin-top: 10px;
    padding: 18px;
  border: 1px solid #0000006b;
}
.ps-section--shopping .ps-section__header2 h1 {
  font-size: 48px;
  font-weight: 600;
}
/* .ps-deal-of-day .ps-section__header {
  margin-bottom: 65px !important;
  padding-bottom: 10px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  ba
} */

.gradient-button {
  color: #212529;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 20px !important;
  text-align: center;
  border-radius: 10px;
  background: #fecc4e;
  cursor: pointer;
  outline: none;
  border: none;
  margin-top: 20px;
}

.transparent-button {
  color: #212529;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 16px !important;
  text-align: center;
  border-radius: 30px;
  background: transparent;
  cursor: pointer;
  outline: none;
  border: 1px solid;
}

.filter-button {
  color: #212529;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 20px !important;
  text-align: center;
  border-radius: 10px;
  background: #fecc4e;
  cursor: pointer;
  outline: none;
  border: none;
  /* height: 35px; */
}
.admin-button {
  color: #212529;
  text-transform: capitalize;
  font-size: 11px;
  font-weight: 500;
  padding: 3px 7px !important;
  text-align: center;
  border-radius: 5px;
  background: #fecc4e;
  cursor: pointer;
  outline: none;
  border: none;
  /* height: 35px; */
}

.clear-button {
  color: #212529;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  padding: 4px 20px !important;
  text-align: center;
  border-radius: 10px;
  background: transparent;
  cursor: pointer;
  outline: none;
  border: 1px solid #fecc4e;
}

.scale-icon {
  max-width: 18px;
  height: auto;
}

.ant-modal-title {
  color: #212529;
}

.ant-modal-content {
  background: #ffffff;
  color: #212529;
}

.ant-select {
  color: #212529 !important;
  outline: none !important;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: none;
}

.ant-select-selector {
  min-height: 35px !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #d4d4d4;
  border-radius: 0.5rem;
}

.status_active {
  font-size: 12px;
  font-weight: 700;
  color: #0e6f6b;
  background-color: #c9f7f5;
  border-color: #c9f7f5;
  border-radius: 8px;
  padding: 4px 10px 4px;
  cursor: pointer;
}

.status_inactive {
  font-size: 12px;
  font-weight: 700;
  color: #aa1c2b;
  background-color: #ffe2e5;
  border-color: #ffe2e5;
  border-radius: 8px;
  padding: 4px 10px 4px;
  cursor: pointer;
}

.status_inactive:hover {
  font-weight: 700;
  color: #aa1c2b;
  background-color: #ffe2e5;
  border-color: #ffe2e5;
  border-radius: 8px;
  cursor: pointer;
}

.edit-icon {
  max-width: 30px;
  height: auto;
  font-size: 25px;
  color: #1559a2;
}

.faq-icon {
  width: 50px;
  font-size: 25px;
  padding-left: 15px;
  color: #1559a2;
}

input[readonly] {
  background-color: #f7f7f7;
  border: unset;
}

input[readonly]:hover,
input[readonly]:focus {
  border: unset;
  box-shadow: unset;
}

.ant-menu-submenu-selected {
  fill: #1559a2;
}

.clear-button1 {
  z-index: 99;
}

.table tbody td {
  font-weight: 500;
  max-width: fit-content;
  min-width: auto;
  font-size: 13px !important;
}

.table thead th {
  font-size: 13px !important;
}

.ant-pagination-options {
  display: none;
}

.ant-form-item-control-input-content .wrapper {
  z-index: 999 !important;
}

.cmyNjn > .wrapper {
  /* height: 36px !important; */
  border: 1px solid #d4d4d4 !important;
  border-radius: 10px !important;
  min-height: 36px !important;
}

.cmyNjn > .wrapper:active {
  box-shadow: rgb(32 33 36 / 0%) 0px 1px 6px 0px !important;
}

.cmyNjn > .wrapper:hover {
  box-shadow: rgb(32 33 36 / 0%) 0px 1px 6px 0px !important;
}

.fDbOPw {
  min-height: 36px !important;
}

div#testid_erro {
  color: red;
}

.appli_view_label {
  font-size: 14px;
  font-weight: 500;
}

.appli_view {
  margin-top: 15px;
}

.job-post-add {
  position: unset;
  display: block;
  margin: auto;
  margin-right: 2%;
}

/* .tox.tox-silver-sink.tox-tinymce-aux {
  display: none;
} */

.ex-export span.anticon.anticon-file-excel {
  font-size: 30px;
  color: #109d59;
  display: inline-block;
  cursor: pointer;
}

.position-relative {
  position: relative;
}

.ex-export {
  position: absolute;
  right: 5%;
  bottom: 5px;
}

.btn-exl {
  background: none;
  border: 0px;
}

label {
  cursor: pointer;
  /* Style as you please, it will become the visible UI component. */
}

#upload-photo {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.view-attachment {
  width: 20px;
  font-size: 25px;
  margin: 0 4px;
  padding-top: 0;
}

.bg_gray {
  background: #f7f7f7;
  height: 200px;
  border: none;
}

.doc_icon {
  width: 40px;
}

.doc_icon-w {
  width: 25px;
}

.table_first_col {
  width: 70px !important;
}

.table_action_col {
  width: 150px !important;
}

.table_status_col {
  width: 150px !important;
}

.jop_btn {
  background-color: #1559a2;
  padding: 5px 15px;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
  display: flex;
  border-radius: 5px;
}

.jop_btn svg {
  width: 20px;
  margin-left: 2px;
  height: 22px;
  vertical-align: middle;
}

.char_count {
  float: right;
  font-size: 13px;
  position: absolute;
  right: 0;
  bottom: 0;
}
.d-none {
  display: none;
}
.c-bl {
  color: #1890ff;
}
.frameSet {
  padding: 20px 10px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
}

.success-button {
  color: #212529;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 20px !important;
  text-align: center;
  border-radius: 10px;
  background: #4efe4e;
  cursor: pointer;
  outline: none;
  border: none;
  /* height: 35px; */
}
.success-button {
  color: #212529;
  text-transform: capitalize;
  font-size: 11px;
  font-weight: 500;
  padding: 3px 7px !important;
  text-align: center;
  border-radius: 10px;
  background: #4efe4e;
  cursor: pointer;
  outline: none;
  border: none;
  /* height: 35px; */
}
.delete-button {
  color: #fdfeff;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 20px !important;
  text-align: center;
  border-radius: 10px;
  background: #ff1515;
  cursor: pointer;
  outline: none;
  border: none;
  /* height: 35px; */
}
.recentlisting .ps-product {
  padding-bottom: 10px;
}
.delete-button2 {
  color: #fdfeff;
  text-transform: capitalize;
  font-size: 11px;
  font-weight: 500;
  padding: 3px 7px !important;
  text-align: center;
  border-radius: 10px;
  background: #ff1515;
  cursor: pointer;
  outline: none;
  border: none;
  /* height: 35px; */
}

/* .form-select {
  width: 100%;
  height: 30px;
} */

.userDetailTable {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.userDetailTablet {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  background-color: #40189d;
  width: 100%;
}
.userDetailTablet2 {
  /* border: 1px solid #dddddd; */
  text-align: left;
  padding: 8px;
  background-color: #40189d;
  /* width: 100%; */
  color: #fff;
}

.companyName {
  font-size: 14px !important;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 5px;
}

.invotb tr:nth-child(odd) {
  background: rgba(0, 0, 0, 0.05);
}

.userDetailTable tr {
  border-color: inherit;
  border-style: solid;
  border-width: 1px;
}
.userDetailTable td {
  border-color: inherit;
  border-style: solid;
  border-width: 1px;
}

.coustomNevc {
  color: #009ef7 !important;
  cursor: pointer;
}
.coustomtrue {
  color: #00f742 !important;
  cursor: pointer;
}
.coustomfalse {
  color: #f70000 !important;
  cursor: pointer;
}

.code-box {
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 0 0 16px;
  background-color: #f0f2f5;
  border: 1px solid rgba(5, 5, 5, 0.06);
  border-radius: 6px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  padding: 15px;
}
.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100% !important;
}

.fs-5 {
  font-size: 1rem !important;
}

.fs-2 {
  font-size: calc(0.5rem + 0.9vw) !important;
}
.py-20 {
  padding: 2.7rem !important;
  /* padding-bottom: 5rem !important; */
}

.chatBoxHeight {
  height: 300px !important;
  /* overflow-y: scroll;
  position: relative; */
}

.scroll-y {
  overflow-y: scroll;
  position: relative;
  height: 300px !important;
}

.proadDImgContainer {
  position: relative;
  width: 100px;
}

.proadDImgBtn {
  position: absolute;
  top: -17px;
  right: -28px;
}

.dataTables_paginate.paging_simple_numbers.category {
  float: right;
}

.dataTables_wrapper .dataTables_paginate {
  padding: 10px 0;
}

.dataTables_paginate.paging_simple_numbers.category .previous a {
  /* color: #40189D; */
  color: var(--bs-pagination-color) !important;
}
.dataTables_paginate.paging_simple_numbers.category .next a {
  /* color: #40189D; */
  color: var(--bs-pagination-color) !important;
}
.dataTables_paginate.paging_simple_numbers.category li:nth-child(1) {
  background: transparent !important;
  color: var(--bs-pagination-color) !important;
}

.dataTables_paginate.paging_simple_numbers.category li {
  border: 0 !important;
  padding: 7px 16px;
  border: 0px solid #40189d !important;
  /* background: rgba(64, 24, 157, 0.3) !important; */
  border-radius: 1rem;
  color: var(--bs-pagination-color) !important;
  font-size: 16px;
  margin: 0;
  display: inline-block;
}
.bolgBox img {
  max-width: 100%;
}

.dataTables_paginate.paging_simple_numbers.category li:hover {
  background: rgba(18, 18, 209, 0.1) !important;
}
/* .dataTables_paginate.paging_simple_numbers.category li:nth-child(1){

  background: transparent !important;
  
 
} */
.dataTables_paginate.paging_simple_numbers.category li:last-child {
  background: transparent !important;
  color: var(--bs-pagination-color) !important;
}
.fw-semibold .text-gray-600 tr td {
  color: var(--bs-pagination-color) !important;
}

.dataTables_paginate.paging_simple_numbers.category li.selected {
  color: #fff !important;
  background: #009ef7 !important;
}

.rankrole table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.rankrole td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.rankrole tr:nth-child(even) {
  background-color: #dddddd;
}

.productPage__left {
  position: relative;
}

.productPage__container {
  overflow-x: none;
}

.productPage__container {
  position: relative;
  display: flex;
  width: 100vw;
  font-family: "Montserrat", sans-serif;
  background: #e4e4e4;
}

.productPage__sideImagesContainer {
  height: fit-content;
  background-color: white;
  border: none;
  justify-content: center;
  margin-top: 10px;
  display: flex;
}

.productPage__right {
  width: 55%;
  margin: 10px;
  margin-top: 13vh;
  overflow-y: scroll !important;

  background-color: white;
  margin-right: 39px;
}

.productPage__right::-webkit-scrollbar {
  display: none;
}

.sideImage {
  width: 50px;
  height: 50px;
  cursor: pointer;
  margin: 10px;
  background-size: contain;
  background-repeat: no-repeat;
  border: 2px solid #e4e4e4;
  background-position: center;
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.sideImageActive {
  width: 50px;
  height: 50px;
  cursor: pointer;
  margin: 10px;
  background-size: contain;
  background-repeat: no-repeat;
  border: 2px solid #3bb77e;
  background-position: center;
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.imgViewS1 {
  max-height: 400px;
  max-width: 100%;
  width: auto !important;
  margin: auto;
}

.sideImage img {
  max-height: 100%;
  max-width: 100%;
  vertical-align: -webkit-baseline-middle;
  display: inline-block;
}

.sideImageActive:hover {
  border: none;
  box-shadow: 2px 2px 14px -1px rgba(211, 210, 210, 0.66);
  -webkit-box-shadow: 2px 2px 14px -1px rgba(211, 210, 210, 0.66);
  -moz-box-shadow: 2px 2px 14px -1px rgba(228, 228, 228, 0.66);
}
.productPage__displayImageContainer {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 101.5%;
  background-color: white;
  margin-left: 18px;
  border-radius: 20px;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}

.product_page_top {
  background-color: #e4e4e4;
}

.productPage__displayImage {
  max-width: 80%;
  height: 80%;
  /* max-height: 400px; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
/* .imgViewS img:first-child{
  max-height: 400px;
    max-width: 100%;
    width: auto !important;
    margin: auto;
} */
.imgViewS1 {
  max-height: 400px;
  max-width: 100%;
  width: auto !important;
  margin: auto;
}
.sideImageActive img {
  max-height: 100%;
  max-width: 100%;
  vertical-align: -webkit-baseline-middle;
  display: inline-block;
}

.imgViewS1 {
  max-height: 400px;
  max-width: 100% !important;
  width: auto !important;
  margin: auto;
}

.selectedSize {
  border: 1px solid rgb(0, 0, 0);
  text-align: center;
  background: #000000;
  color: #fff;
}
.unselectedSize {
  border: 1px solid rgb(123, 123, 123);
  text-align: center;
}

.text-green-700 {
  color: #146c43 !important;
  font-weight: 700;
  display: inline;
}
.text-red-700 {
  color: #da102e !important;
  font-weight: 700;
  display: inline;
}

@import url(https://fonts.googleapis.com/css?family=Dancing+Script);
.certificate {
  height: auto;
  width: 100%;
}
.certificate_image {
  height: 600px;
  width: 100%;
}
.certificate_image_b {
  height: 600px;
  width: 100%;
  z-index: 3;
}

.rscertificate{
  width: 400px !important;
}
.btimg{
  position: relative;
  z-index: 1;
  margin-top: 25px;
  height: 135px;
}
.rscertificate .name{
 color: #ffffff !important;
 position: relative;
 margin-top: 5px;
 width: 300px !important;
  z-index: 4;
  margin: auto;
}
.rscertificate .name.vhmt{
 color: #CA0A27 !important;
 position: relative;
 margin-top: 5px;
 width: 300px !important;
  z-index: 4;
  margin: auto;
}
.rscertificate .achiver_pra{
 color: #ffffff;
 position: relative;
 
 width: 300px !important;
  z-index: 4;
}
.name {
  font-family: "Dancing Script";
  margin-top: 30px;
}

.wishby {
  position: absolute;
  bottom: 48px;
  left: 35px;
}
.wishby img{
  width: 85px;
    height: 85px;
}
.wishby span{
  position: relative;
  z-index: 5;
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
}
h1.card-title.cer {
  font-family: ui-sans-serif;
  color: transparent;
  background-image: url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSa2ynjUCpjYo48iVF0rVOMmrhol6fcovkNM_jlaF6vOborsJsObNozc6OI-bU6ccAm_Jw&usqp=CAU);
  -webkit-background-clip: text;
  margin-top: 80px;
}

.cer h6 {
  font-size: 16px;
  letter-spacing: 3px;
  margin-top: -18px;
  font-family: ui-sans-serif;
  color: transparent;
  background-image: url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSa2ynjUCpjYo48iVF0rVOMmrhol6fcovkNM_jlaF6vOborsJsObNozc6OI-bU6ccAm_Jw&usqp=CAU);
  -webkit-background-clip: text;
}
.btn-close {
  font-size: 10px;
  width: 25px;
  height: 20px;
}
.cer img {
  margin-top: 20px;
  margin-bottom: 15px;
}
.certificate {
  height: auto;
  width: 100%;
  border: 8px groove transparent;
  border-image: url(https://img.freepik.com/free-photo/magic-abstract-soft-colors-shining-yellow-gradient-studio-background_1258-71558.jpg?size=626&ext=jpg&ga=GA1.1.1413502914.1696377600&semt=ais)
    10 round;
}
.sign {
  border-top: 2px solid gold;
  width: 200px;
  margin-top: 40px;
}
.Achievers {
  color: transparent;
  background-image: url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTF8-mV-fHWER2EmJqAbKjaC8l-FVLLPG_NWQ&usqp=CAU);
  font-family: ui-sans-serif;
  -webkit-background-clip: text;
  font-size: 60px;
}
p.achiver_pra {
  width: 600px;
  margin: auto;
  /* text-align: center; */
}

@media only screen and (min-width: 100px) and (max-width: 900px) {
  h1.card-title.cer {
    font-size: 25px;
    margin-top: 10px;
  }
  .cer h6 {
    font-size: 13px;
  }
  .sign {
    border-top: 2px solid gold;
    width: 80px;
    margin-top: -22px;
  }
  .certificate_image {
    height: 400px;
    width: 100%;
  }
  h6.card-text.second_txt {
    font-size: small;
    margin-top: 10px;
  }
  p.achiver_pra {
    width: 400px;
    margin: auto;
    font-size: 10px;
  }
}
@media only screen and (min-width: 100px) and (max-width: 770px) {
  .sign {
    border-top: 2px solid gold;
    width: 80px;
    margin-top: -22px;
  }
  .certificate_image {
    height: 400px;
    width: 100%;
  }
  h1.card-title.cer {
    font-size: 20px;
    margin-top: 10px;
  }
  p.card-text.mt-5 {
    font-size: 10px;
  }
  .name {
    font-size: 20px;
  }
  p.achiver_pra {
    width: 340px;
    margin: auto;
    font-size: 10px;
  }

  .ps-product__content {
    padding: 5px;
  }
}

.abc {
  font-size: 17px;
  text-align: justify;
}

.cer_img {
  border-radius: 20px;
  height: 350px;
  width: 500px;
}
/* .troffy {
  height: 326px;
  width: 900px;
} */

/* @media only screen and (min-width: 100px) and (max-width: 1000px) {
  .troffy {
    display: none;
  }
} */
/* .btn {
font-family: 'Noto Sans', sans-serif;
cursor: pointer;
box-shadow: -1px 3px 3px 0 rgba(80, 80, 80, .2);
width: 200px;
height: 50px; 
border: none;
border-radius: 30px;
margin: 0 10px 40px;
color: #fff;
font-weight: bold;
flex: 0 0 auto;
} */

.btn-1 {
  background-image: linear-gradient(to bottom right, red, blue);
  transition: all 0.25s ease-out;
}

.btn-1:hover {
  position: relative;
  top: -3px;
  box-shadow: -6px 14px 12px 2px rgba(90, 90, 90, 0.12);
}

.btn-2 {
  background-image: linear-gradient(to bottom right, blue, red);
  transition: box-shadow 0.16s ease;
}

.btn-2:hover {
  background-image: linear-gradient(to bottom right, blue, red);
  box-shadow: none;
}

h4.link_topic {
  font-family: auto;
}
a.primary {
  color: blue;
  border-bottom: 2px solid blue;
}
.zzz {
  height: 590px;
  overflow-y: scroll;
}
.pen {
  font-size: 12px;
  color: currentcolor;
  font-family: math;
}
.tyt {
  color: orange;
}

/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #065c09;
}

/* FILTERS
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.onlineexam .filters {
  text-align: center;
  margin-bottom: 2rem;
}

.onlineexam .filters * {
  display: inline-block;
}

.onlineexam .filters label {
  padding: 0.5rem 1rem;
  margin-bottom: 0.25rem;
  border-radius: 2rem;
  min-width: 50px;
  line-height: normal;
  cursor: pointer;
  transition: all 0.1s;
  width: 190px;
}

.onlineexam .filters label:hover {
  background: var(--green);
  color: var(--white);
}

/* FILTERED ELEMENTS (POSTS)
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.onlineexam .posts {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(5, 1fr);
}

.onlineexam .posts .post {
  background-image: linear-gradient(to bottom right, white, white, white);
  width: 220px;
  border-radius: 10px;
  text-align: center;
  border-bottom: 7px solid green !important;
  border: 2px solid green;
  place-content: center;
}

.onlineexam .posts .post-title {
  font-size: 1.3rem;
}

.onlineexam .posts .post-title:hover {
  text-decoration: underline;
}

.posts .post-categories {
  margin-bottom: 0.75rem;
  font-size: 0.75rem;
}

.onlineexam .posts .post-categories * {
  display: inline-block;
}

.onlineexam .posts .post-categories li {
  margin-bottom: 0.2rem;
}

.onlineexam .posts .post-categories a {
  padding: 0.2rem 0.5rem;
  border-radius: 1rem;
  border: 1px solid;
  line-height: normal;
  transition: all 0.1s;
}

.onlineexam .posts .post-categories a:hover {
  background: var(--green);
  color: var(--white);
}

/* FILTERING RULES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
[value="All"]:checked ~ .filters [for="All"],
[value="SSC"]:checked ~ .filters [for="SSC"],
[value="BANK"]:checked ~ .filters [for="BANK"],
[value="RAILWAY"]:checked ~ .filters [for="RAILWAY"],
[value="POLICE"]:checked ~ .filters [for="POLICE"],
[value="TEACH"]:checked ~ .filters [for="TEACH"],
[value="DEFENCE"]:checked ~ .filters [for="DEFENCE"],
[value="ESIC"]:checked ~ .filters [for="ESIC"],
[value="STATE"]:checked ~ .filters [for="STATE"],
[value="STATEPSC"]:checked ~ .filters [for="STATEPSC"],
[value="UPSC"]:checked ~ .filters [for="UPSC"],
[value="OTHER"]:checked ~ .filters [for="OTHER"] {
  background: var(--green);
  color: var(--white);
}

[value="All"]:checked ~ .posts [data-category] {
  display: block;
}

[value="SSC"]:checked ~ .posts .post:not([data-category~="SSC"]),
[value="BANK"]:checked ~ .posts .post:not([data-category~="BANK"]),
[value="RAILWAY"]:checked ~ .posts .post:not([data-category~="RAILWAY"]),
[value="POLICE"]:checked ~ .posts .post:not([data-category~="POLICE"]),
[value="TEACH"]:checked ~ .posts .post:not([data-category~="TEACH"]),
[value="DEFENCE"]:checked ~ .posts .post:not([data-category~="DEFENCE"]),
[value="ESIC"]:checked ~ .posts .post:not([data-category~="ESIC"]),
[value="STATE"]:checked ~ .posts .post:not([data-category~="STATE"]),
[value="STATEPSC"]:checked ~ .posts .post:not([data-category~="STATEPSC"]),
[value="UPSC"]:checked ~ .posts .post:not([data-category~="UPSC"]),
[value="OTHER"]:checked ~ .posts .post:not([data-category~="OTHER"]) {
  display: none;
}

.onlineexam ol {
  list-style: none;
  font-size: 18px;
}

.onlineexam ol li label {
  background-color: green;
  margin: 5px;
  color: wheat;
}
.onlineexam a {
  text-decoration: none;
  color: inherit;
}

.onlineexam .author-archive {
  padding: 1.5rem;
}

/* .container {
  max-width: 1200px;
  margin: 0 auto;
} */

.onlineexam h1 {
  text-align: center;
  margin-bottom: 2rem;
}

.onlineexam h1 a {
  transition: all 0.1s;
}

.onlineexam h1 a:hover {
  background: var(--green);
  color: var(--white);
}

.onlineexam input[type="radio"] {
  position: absolute;
  left: -9999px;
}

.onlineexam .edu {
  overflow-x: scroll;
  width: 100%;
  display: flex;
}

.onlineexam #style-1::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: #f5f5f5;
}

.onlineexam #style-1::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
  height: 8px;
}

.onlineexam #style-1::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: gray;
}

@media screen and (max-width: 1200px) {
  .onlineexam .posts {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 650px) {
  html {
    font-size: 14px;
  }

  .onlineexam .posts {
    grid-template-columns: repeat(2, 1fr);
  }
  h6.card-text.second_txt {
    font-size: small;
    margin-top: 1px;
  }
  .cer img {
    margin-top: 20px;
    margin-bottom: 1px;
    width: 180px;
  }
  p.achiver_pra {
    width: 252px;
    margin: auto;
    font-size: 10px;
    color: #000000;
  }

  .sign {
    border-top: 2px solid gold;
    width: 120px;
    margin-top: 37px;
    font-size: 15px;
  }
  .Achievers {
    color: transparent;
    background-image: url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTF8-mV-fHWER2EmJqAbKjaC8l-FVLLPG_NWQ&usqp=CAU);
    font-family: ui-sans-serif;
    -webkit-background-clip: text;
    font-size: 37px;
    margin-bottom: 5px;
  }
}
.ps-product .ps-product__thumbnailms {
  min-height: 250px;
}
.ps-product .ps-product__thumbnailms2 {
  min-height: 200px;
}
.name {
  text-transform: capitalize;
  /* font-size: 20px; */
}
@media screen and (max-width: 700px) {
  .onlineexam .posts .post {
    width: 160px;
    height: 70px;
    font-size: 12px;
  }
  .onlineexam section.author-archive {
    padding: 0%;
  }
  .ps-product .ps-product__thumbnailms {
    min-height: 150px;
  }
  .ps-product .ps-product__thumbnailms2 {
    min-height: 130px;
  }
}

.hometest {
  overflow: hidden;
}

.text-end {
  text-align: right !important;
}
.lbsele {
  background: #28a745 !important;
}

.btn-11 {
  border: none;
  background: rgb(251, 33, 117);
  background: linear-gradient(
    0deg,
    rgb(60, 210, 92) 0%,
    rgb(212, 128, 72) 100%
  );
  color: #fff;
  overflow: hidden;
  width: 150px;
  height: 40px;
}
.btn-11:hover {
  text-decoration: none;
  color: #fff;
  border: 5px groove rgb(238, 235, 238);
}

.frame_top {
  height: 195px;
  width: 183px;
  /* background-image: url(img/tooper_back.png); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: auto;
}
.topper-img {
  height: 122px;
  width: 125px;
  border-radius: 100px;
  margin-left: 0px;
  margin-top: 31px;
  position: relative;
  z-index: -20px;
}
button.btn-11 {
  font-weight: 600;
  color: #fff;
  text-shadow: 1px 1px #111;
}
.card-body.exam {
  background: linear-gradient(0deg, rgb(178 233 189) 0%, rgb(242 141 8) 100%);
  font-weight: 600;
  color: #fff;
  text-shadow: 1px 1px #f7f0f0;
  border-bottom: 6px groove navy;
  border-radius: 5px;
}
h4.p-4 {
  color: #fff;
  text-shadow: 1px 1px #111;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
.myMarquee {
  width: 100%;
  height: 40px;
  overflow: hidden;
  position: relative;
  border: 1px solid #aaa;
  -o-box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7);
  -webkit-box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7);
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7);

  -o-border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  background: linear-gradient(rgba(255, 255, 255, 0.7), rgb(68 170 221));
  /* background:#4ad; */
  /*  background: -webkit-gradient(linear, 0% 0%, 0% 95%, from(rgba(255, 255, 255, 0.7)), to(rgba(255, 255, 255, 0)));
            background: -moz-linear-gradient(-90deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0)); */
}

.scroller {
  display: block;
  width: 1240px;
  height: 40px;
  position: absolute;
  left: 0;
  top: 0;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -moz-animation-duration: 10s;
  -moz-animation-name: scroll;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -webkit-animation-duration: 10s;
  -webkit-animation-name: scroll;
}
.scroller div {
  font-family: georgia, serif;
  font-size: 16px;
  line-height: 40px;
  float: left;
  width: 600px;
  color: #000;
  font-weight: bold;
  padding: 0 10px;
}
.scroller div a {
  color: #c00;
}

.scroller:hover {
  -moz-animation-play-state: paused;
}

@-webkit-keyframes scroll {
  0% {
    left: 0;
  }
  100% {
    left: -620px;
  }
}
.trigger{
  color: #ffffff !important;
}
.site-layout .site-layout-background1 {
  background: #191352 !important;
}
.site-layout .site-layout-backgroundr {
  background: #089dc3  !important;
  min-width: 340px;
}
.sllertitleh {
  text-transform: capitalize;
  position: relative;
  color: #000;
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: 700 !important;
  font-size: 36px !important;
}
.becomevvv .ps-section__header {
  border: none !important;
}

.layout_sidebar1 li{
  border-bottom: 1px #5b5b5b solid;
}

.paddcopm {
  padding: 5px;
  margin-top: 15px;
}

.loginsection {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background: url("https://wallpapercave.com/wp/wp2150006.png") no-repeat;
  background-size: cover;
  background-position: center;
  animation: animateBg 5s linear infinite;
}

@keyframes animateBg {
  100% {
    filter: hue-rotate(360deg);
  }
}

.loginsection .login-box {
  position: relative;
  width: 600px;
  /* height: 450px; */
  background: transparent;
  border: 2px solid rgba(119, 92, 92, 0.5);
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(15px);
}

.loginsection h2 {
  font-size: 2.5rem;
  color: #fff;
  text-align: center;
}

.loginsection .input-box2 {
  position: relative;
  width: 310px;
  margin: 30px 0;
  border-bottom: 2px solid #fff;
}

.loginsection .input-box2 label {
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  font-size: 1.5rem;
  color: #fff;
  pointer-events: none;
  transition: 0.5s;
}

.loginsection .input-box2 input:focus ~ label,
.loginsection .input-box2 input:valid ~ label {
  top: -5px;
}

.loginsection .input-box2 input {
  width: 100%;
  height: 50px;
  background: transparent;
  border: none;
  outline: none;
  font-size: 1.5rem;
  color: #fff;
  padding: 0 35px 0 5px;
}

.loginsection .input-box2 .icon {
  position: absolute;
  right: 8px;
  color: #fff;
  font-size: 1.2rem;
  line-height: 57px;
}

.loginsection .remember-forgot {
  margin: -15px 0 15px;
  font-size: 0.9em;
  color: #fff;
  display: flex;
  justify-content: space-between;
}

.loginsection .remember-forgot label input {
  margin-right: 3px;
}

.loginsection .remember-forgot a {
  color: #fff;
  text-decoration: none;
}

.loginsection .remember-forgot a:hover {
  text-decoration: underline;
}

.loginsection button {
  width: 100%;
  height: 40px;
  background: #fff;
  border: none;
  outline: none;
  border-radius: 40px;
  cursor: pointer;
  font-size: 1.5rem;
  color: #000;
  font-weight: 500;
}

.loginsection .register-link {
  font-size: 0.9rem;
  color: #fff;
  text-align: center;
  margin: 25px 0 10px;
}

.loginsection .register-link p a {
  color: #fff;
  text-decoration: none;
  font-weight: 600;
}

.loginsection .register-link p a:hover {
  text-decoration: underline;
}

@media (max-width: 360px) {
  .loginsection .login-box {
    width: 100%;
    /* height: 100vh; */
    border: none;
    border-radius: 0;
  }

  .loginsection .input-box2 {
    width: 290px;
  }
  .manh {
    font-size: 18px;
  }
}

.cusp {
  cursor: pointer;
}

.vdstore .ps-product--detail {
  margin-bottom: 0.5rem;
}

.serachtypebox {
  min-width: 110px;
}

.form-control2 {
  height: 40px !important;
  border-radius: 0px;
}

@media (max-width: 560px) {
  .form-control {
    height: 35px;
    margin-top: 5px;
  }

  .manh {
    font-size: 18px;
  }

  .serachtypebox {
    min-width: 80px;
  }

  .btn-secondary {
    font-size: 12px;
    padding: 5px;
  }

  .b2s {
  
    min-width: 260px; 
   
  }
}

.dashboardBoxShad2 {
  min-height: 70px;
  min-width: 280px; 
overflow: auto;
}

.site-layout .site-layout-background .hometest {
  
  min-width: 320px; 
 
}

.ant-layout{
  min-height: 100vh !important;
}
.ant-layout.site-layout{
  /* min-width: 340px; */
  background-color: #ffffff;
  overflow: auto !important;
}
.site-layout .site-layout-background1{
  min-width: 340px;
  /* overflow: auto !important; */
}
.site-layout .site-layout-background{
  min-width: 280px;
  background-color: #ffffff;
  /* overflow: auto !important; */
}
.dashboardBoxShad2 .priceText {
  color: #1559a2;
  font-size: 24px;
  font-weight: bold;
  margin-right: 20px;
}

.comingSoonC {
  height: 80vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.comingSoonC h3 {
  font-weight: 800;
  font-size: 40px;
}





/* .mobile-header-wrapper-style.sidebar-visible {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
} */

.mobile-header-top .mobile-header-logo a {
  display: block;
}

.mobile-header-content-area .mobile-menu-wrap nav {
  height: 100%;
}

.mobile-header-content-area .main-categori-wrap a i {
  margin-right: 15px;
}

.mobile-header-content-area
  .main-categori-wrap
  .categori-dropdown-active-small {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0;
}

.mobile-header-wrapper-style {
  position: fixed;
  top: 0;
  width: 360px;
  min-height: 100vh;
  bottom: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-transform: translate(-200px, 0);
  transform: translate(-200px, 0);
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.09);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.09);
  z-index: 99999;
}

/* .mobile-header-wrapper-style .mobile-header-wrapper-inner {
  padding: 0px 0px 30px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
} */

.mobile-header-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 20px 30px;
  background-color: #ffffff;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.mobile-header-top .mobile-header-logo a img {
  width: 100px;
}

.mobile-header-content-area {
  padding: 30px 30px 30px;
}

.mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li {
  display: block;
  position: relative;
  padding: 13px 0;
  border-bottom: 1px solid #ececec;
}

.mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li:last-child {
  border-bottom: none;
}

.mobile-header-content-area
  .mobile-menu-wrap
  nav
  .mobile-menu
  li.menu-item-has-children
  .menu-expand {
  right: 0;
  position: absolute;
  cursor: pointer;
  z-index: 9;
  text-align: center;
  font-size: 12px;
  display: block;
  width: 30px;
  height: 30px;
  line-height: 38px;
  top: 5px;
  color: #253d4e;
}

.mobile-header-content-area
  .mobile-menu-wrap
  nav
  .mobile-menu
  li.menu-item-has-children
  .menu-expand
  i {
  font-size: 14px;
  font-weight: 300;
}

.mobile-header-content-area
  .mobile-menu-wrap
  nav
  .mobile-menu
  li.menu-item-has-children.active
  > .menu-expand {
  background: rgba(255, 255, 255, 0.2);
}

.mobile-header-content-area
  .mobile-menu-wrap
  nav
  .mobile-menu
  li.menu-item-has-children.active
  > .menu-expand
  i::before {
  content: "\f112";
}

.mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li a {
  font-size: 14px;
  line-height: 1;
  text-transform: capitalize;
  font-weight: 700;
  position: relative;
  display: inline-block;
  color: #253d4e;
}

.mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li a i {
  margin-right: 5px;
}

.mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul {
  padding: 10px 0 0 10px;
}

.mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li {
  padding: 10px 0;
  border-bottom: none;
}

.mobile-header-content-area
  .mobile-menu-wrap
  nav
  .mobile-menu
  li
  ul
  li.menu-item-has-children
  .menu-expand {
  top: 0px;
}
.recentlostfound {
  max-width: 300px;
}
.footico {
  color: #ffffff !important;
}
.pdcon {
  min-height: 800px;
}

.mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li a {
  font-size: 14px;
  display: block;
  font-weight: 500;
}

.mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li ul {
  margin-top: 0;
}

.mobile-header-content-area
  .mobile-menu-wrap
  nav
  .mobile-menu
  li
  ul
  li.menu-item-has-children.active {
  padding-bottom: 0;
}

.mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li:hover > a {
  color: #ff0000;
}

.mobile-header-content-area .categories-dropdown-wrap ul li a {
  padding: 5px 15px;
}

.mobile-header-content-area .mobile-header-info-wrap {
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #ececec;
  margin: 17px 0 30px 0;
}

.mobile-header-content-area
  .mobile-header-info-wrap
  .single-mobile-header-info {
  position: relative;
  margin-bottom: 13px;
}

.mobile-header-content-area
  .mobile-header-info-wrap
  .single-mobile-header-info:last-child {
  margin-bottom: 0;
}

.mobile-header-content-area
  .mobile-header-info-wrap
  .single-mobile-header-info
  a {
  font-size: 14px;
  display: block;
  font-weight: 500;
  color: #253d4e;
}

.mobile-header-content-area
  .mobile-header-info-wrap
  .single-mobile-header-info
  a:hover {
  color: #ff0000;
}

.mobile-header-content-area
  .mobile-header-info-wrap
  .single-mobile-header-info
  a
  i {
  font-size: 14px;
  color: #ff0000;
  margin-right: 8px;
}

.mobile-header-content-area
  .mobile-header-info-wrap
  .single-mobile-header-info
  .lang-curr-dropdown {
  margin-top: 5px;
  display: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 10px 0 0 0;
  width: 100%;
  z-index: 11;
}

.mobile-header-content-area
  .mobile-header-info-wrap
  .single-mobile-header-info
  .lang-curr-dropdown
  ul
  li {
  padding-bottom: 10px;
}

.mobile-header-content-area
  .mobile-header-info-wrap
  .single-mobile-header-info
  .lang-curr-dropdown
  ul
  li:last-child {
  padding-bottom: 0px;
}

.mobile-header-content-area
  .mobile-header-info-wrap
  .single-mobile-header-info
  .lang-curr-dropdown
  ul
  li
  a {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 400;
}

.mobile-header-content-area
  .mobile-header-info-wrap
  .single-mobile-header-info
  .lang-curr-dropdown
  ul
  li
  a:hover {
  color: #ff0000;
}

.mobile-header-content-area
  .mobile-header-info-wrap
  .single-mobile-header-info:hover
  > a {
  color: #253d4e;
}

.mobile-header-content-area .mobile-header-border {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.mobile-header-content-area .site-copyright {
  font-size: 13px;
  color: #b6b6b6;
}

/* .mobile-header-wrapper-style {
  width: 380px;
} */
.mobile-header-top {
  padding: 15px 30px 13px 30px;
  border-bottom: 1px solid #ececec;
}

.mobile-header-top .mobile-header-logo a img {
  width: 140px;
}

.mobile-header-content-area {
  padding: 30px;
}

.loginsection .input-box2 select {
  width: 100%;
  height: 50px;
  background: transparent;
  border: none;
  outline: none;
  font-size: 1.5rem;
  color: #fff;
  padding: 0 35px 0 5px;
}
.loginsection .input-box2 option {
  color: #000000;
}

.ps-block--category {
  max-width: 240px;
}

.pagination li.selected a {
  /* display: inline-block;
  margin-right: 10px;
  vertical-align: top; */
  background-color: #fcb800;
}

.notfound {
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.saved-add.active {
  background-color: white;
  border: 5px solid #ff9528;
  border-radius: 15px;
  padding: 10px;
}
.save-edit {
  background: #ff9528;
  mix-blend-mode: normal;
  box-shadow: 0px 5.76277px 17.2883px rgba(255, 149, 40, 0.25);
  border-radius: 25.9325px;
  color: #ffffff;
  border-color: #ff9528;
  width: 130px;
}
.save-del {
  border: 1.44069px solid #ff9528;
  box-sizing: border-box;
  box-shadow: 0px 5.76277px 17.2883px rgba(255, 149, 40, 0.25);
  border-radius: 25.9325px;
  width: 100px;
}

.add-add {
  border: 1px solid #717171;
  height: 100%;
  position: relative;
  border-radius: 15px;
  text-align: center;
  vertical-align: middle;
}

.add-add {
  background-color: white;
  border: 2.94269px dashed #ff9528 !important;
  cursor: pointer;
  box-shadow: 0px 6px 20px 0 rgba(255, 149, 40, 0.25);
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: inherit;
  overflow: auto;
}

.bankdetails .card {
  border-color: #f4f4f4;
  box-shadow: 0px 5px 10px rgba(51, 66, 87, 0.05);
  transition: all 0.3s ease;
}
.bankdetails .bg-img {
  background-size: cover;
  background-repeat: no-repeat;
}
.bankdetails .bank-info-card {
  background-color: rgba(0, 106, 255, 0.05);
}
.bankdetails .bank-card-img {
  position: absolute;
  inset-block-start: 1.5rem;
  inset-inline-end: 1.875rem;
}

.bankdetails .gap-4 {
  gap: 24px;
}
.bankdetails .card:hover {
  box-shadow: 0px 5px 24px rgba(7, 59, 116, 0.15);
}

.ps-btn2 {
  display: inline-block;
  padding: 7px 7px;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  color: #000;
  border: none;
  font-weight: 600;
  border-radius: 4px;
  background-color: #fcb800;
  transition: all 0.4s ease;
  cursor: pointer;
}
.ps-btn3 {
  display: inline-block;
  padding: 7px 7px;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  color: #f6f6f6;
  border: none;
  font-weight: 600;
  border-radius: 4px;
  background-color: #0065fc;
  transition: all 0.4s ease;
  cursor: pointer;
}
.ps-btn3:hover {
  display: inline-block;
  padding: 7px 7px;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  color: #f6f6f6;
  border: none;
  font-weight: 600;
  border-radius: 4px;
  background-color: #0e479d;
  transition: all 0.4s ease;
  cursor: pointer;
}
.ps-btn4 {
  display: inline-block;
  padding: 7px 7px;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  color: #f6f6f6;
  border: none;
  font-weight: 600;
  border-radius: 4px;
  background-color: #279602;
  transition: all 0.4s ease;
  cursor: pointer;
}
.ps-btn4:hover {
  display: inline-block;
  padding: 7px 7px;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  color: #f6f6f6;
  border: none;
  font-weight: 600;
  border-radius: 4px;
  background-color: #1e7003;
  transition: all 0.4s ease;
  cursor: pointer;
}
.ps-btn5 {
  display: inline-block;
  padding: 7px 7px;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  color: #f6f6f6;
  border: none;
  font-weight: 600;
  border-radius: 4px;
  background-color: #069ec2;
  transition: all 0.4s ease;
  cursor: pointer;
}
.ps-btn5:hover {
  display: inline-block;
  padding: 7px 7px;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  color: #f6f6f6;
  border: none;
  font-weight: 600;
  border-radius: 4px;
  background-color: #0788a5;
  transition: all 0.4s ease;
  cursor: pointer;
}
.unishareIcon {
  text-align: center;
}

.shareLinkInModel {
  padding: 20px;
}
.smbr {
  flex-direction: column !important;
}

.smbr a {
  color: #000;
  font-weight: 700;
}

.cth {
  position: relative;
}
.closebtnn {
  position: absolute;
  top: 10px;
  right: 20px;
  width: 20px;
}
.closebtnn img {
  width: 100%;
}
.playIcon {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url("./play-button.png");
  width: 50px;
  height: 50px;
  background-size: cover;
  mix-blend-mode: luminosity;
  cursor: pointer;
}

.liceninputv2 .css-1nmdiq5-menu{
z-index: 9999999;
/* position: relative; */
}
.playIcon5 {
  position: absolute;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url("./play-button.png");
  width: 50px;
  height: 50px;
  background-size: cover;
  mix-blend-mode: luminosity;
  cursor: pointer;
}

.scroll-to-top {
  position: fixed;
  bottom: 80px;
  right: 20px;
  padding: 10px 15px;
  background-color: #ffaa00;
  color: #fff;
  border: none;
  border: 1px solid #1f1e1e;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 1000;
}

.scroll-to-top.show {
  opacity: 1;
}

.scroll-to-top.hide {
  opacity: 0;
  pointer-events: none;
}
.scroll-to-top:hover {
  background-color: #ececec;
  color: #000;
}


 
.sokakul {
  /* width: 80%; */
  margin: 0 auto;
  border: 2px solid black;
  /* padding: 5px; */
  background: #FFFFFF;
}
.sokakul .headernns {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0px;
  background: #000000;
  color: #fff;
}
.sokakul .sub-header {
  text-align: center;
  font-size: 13px;
  margin-bottom: 0px;
  min-height: 45px;
}
.sokakul .content {
  /* text-align: center; */
  font-size: 14px;
  padding: 5px;
  
  min-height: 150px;
}
.sokakul .content img {
  margin-bottom: 20px;
}
.sokakul .content p {
  margin: 5px 0;
}
.sokakul .footer {
  padding: 5px;
  margin-top: 2px;
  /* text-align: center; */
  height: 70px;

}
.sokakul .footer p {
  margin: 5px 0;
}
.sokakul .address {

  background-color: #000;
   
  /* text-align: center; */
  font-size: 14px;
  margin-top: 5px;
  height: 50px;
}
.sokakul .address p{
  color: #fff;
  margin-bottom: 0px;
  padding: 5px;
}
.sokakul hr {
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  border: 0;
  background-color: black;
  border-top: 1px solid rgba(1, 0, 0, 0 );
}
 
.sokakul1 {
  /* width: 80%; */
  margin: 0 auto;
  border: 2px solid black;
  /* padding: 5px; */
  background: #FFECCB;
}
.sokakul1 .headernns {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0px;
  background: #fb0101;
  color: #fff;
}
.sokakul1 .sub-header {
  text-align: center;
  font-size: 13px;
  margin-bottom: 0px;
  min-height: 45px;
}
.sokakul1 .content {
  /* text-align: center; */
  font-size: 14px;
  padding: 5px;
  min-height: 150px;
}
.sokakul1 .content img {
  margin-bottom: 20px;
}
.sokakul1 .content p {
  margin: 5px 0;
}
.sokakul1 .footer {
  padding: 5px;
  margin-top: 2px;
  background: #FCEDF0;
  /* text-align: center; */

  height: 70px;
}
.sokakul1 .footer p {
  margin: 5px 0;
}
.sokakul1 .address {

  background-color: #000;
   
  /* text-align: center; */
  font-size: 14px;
  margin-top: 5px;
  height: 50px;
   
}
.sokakul1 .address p{
  color: #fff;
  margin-bottom: 0px;
  padding: 5px;
}
.sokakul1 hr {
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  border: 0;
  background-color: black;
  border-top: 1px solid rgba(1, 0, 0, 0 );
}
 
.sokakul2 {
  /* width: 80%; */
  margin: 0 auto;
  border: 2px solid black;
  /* padding: 5px; */
  background: #FFECCB;
}
.sokakul2 .headernns {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0px;
  background: #ffcf09;
  color: #020202;
}
.sokakul2 .sub-header {
  text-align: center;
  font-size: 13px;
  margin-bottom: 0px;
  min-height: 45px;
}
.sokakul2 .content {
  /* text-align: center; */
  font-size: 14px;
  padding: 5px;
  min-height: 150px;
}
.sokakul2 .content img {
  margin-bottom: 20px;
}
.sokakul2 .content p {
  margin: 5px 0;
}
.sokakul2 .footer {
  padding: 5px;
  margin-top: 2px;
  background: #FCEDF0;
  /* text-align: center; */

  height: 70px;
}
.sokakul2 .footer p {
  margin: 5px 0;
}
.sokakul2 .address {

  background-color: #000;
   
  /* text-align: center; */
  font-size: 14px;
  margin-top: 5px;
  height: 50px;
   
}
.sokakul2 .address p{
  color: #fff;
  margin-bottom: 0px;
  padding: 5px;
}
.sokakul2 hr {
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  border: 0;
  background-color: black;
  border-top: 1px solid rgba(1, 0, 0, 0 );
}
.joblistcard{
    
}
@media (max-width: 600px) {


  .joblistcard .card-title {
    font-size: 13px;
    
  }
  .neww .htt {
    width: 142px;
    margin-left: 0px !important;
    margin-top: 0px;
}

.neww .job-table .view-btn{
  padding: 5px 8px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 11px;
  transition: background-color 0.3s ease, transform 0.5s ease-in-out;
  animation: blink 1.5s infinite alternate;
}


.joblistcard .card .card-img {
  width: 40px !important;
  min-width: 40px;
  height: 40px;
  object-fit: cover;
}
  /* .sokakul .footer {
   
  
    height: 170px;
  }
  .sokakul1 .footer {
   
  
    height: 170px;
  }
  .sokakul2 .footer {
    
  
    height: 200px;
  } */
}
.imgflot {
  float: left;
  margin-right: 20px;
}

.vhmt{
  margin-top: 80px !important;
  color: red !important;
}
.area{
  min-width: 152px;
}



.jobportal .dropdown:hover .dropdown-content {
  display: block;
}

/* section starts */
.jobportal .nav-tabs .nav-item {
  margin-bottom: -1px;
  text-align: center;
  width: 174px;
  margin: auto;
  font-size: 19px;
}
/* neeraj */
.jobportal .banner {
  height: 300px;
  background-image: url("https://images.unsplash.com/photo-1541746972996-4e0b0f43e02a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60");
  background-position: center;
  background-size: cover;
  color: #fff;
  margin-top: 7px;
}

.jobportal .banner h1 {
  font-size: 50px;
  font-weight: 600;
  text-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
}

.jobportal .banner p {
  padding-top: 15px;
  line-height: 150%;
  font-size: larger;
  text-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
}



@media (max-width: 321px) {
  .jobportal .banner p {
    display: none;
  }

  .jobportal .search-job {
    margin-top: -20px;
  }
}

/* neeraj */
.jobportal .search-job {
  padding: 10px 0;
  background-image: linear-gradient(to right, #efefef, #efedea);
  width: 100%;
  box-shadow:
    0px -10px 10px -5px #fcb800,
    /* Orange shadow on top */ 0px 10px 10px -5px #fcb800; /* Green shadow on bottom */
}

/* neeraj */
@media (max-width: 514px) {
  .jobportal .search-job {
    margin-top: 6px;
    /* margin-top: -15px; */
  }

  .buttons_backgroun_row {
    background: #3dd60042;
  }
  .jobportal .jobb {
    font-size: 20px;
    /* margin-left: -159px; */
  }

  .jobportal .view-btn {
    /* margin-top: -50px; */
    /* margin-left: 244px;  */
    width: 98px !important;
  }
}

.ltjob {
  justify-content: space-between;
  align-items: center;
  height: 60px;
}

.jobportal .search-job .form-control {
  /* max-width: 250px; */
  display: inline-flex;
  margin: 10px 5px;
  height: 50px;
  border-radius: 25px;
  box-shadow: none !important;
  border: none !important;
  padding-left: 30px;
}

.jobportal .search-job .btn-primary {
  width: 130px;
  border-radius: 25px;
  box-shadow: none !important;
  border: none;
  height: 50px;
  margin: 5px;
  padding: 5px 20px;
  display: table-cell;
  vertical-align: middle;
}

.jobportal .search-job .btn-primary:hover {
  border: 1px solid yellow;
}

/* top recuruiters */
/* neeraj */
.jobportal #recruiters {
  padding-top: 12px;
  padding-bottom: 100px;
}

.jobportal #recruiters h3 {
  text-align: center;
  padding-bottom: 40px;
}

.jobportal h3::before {
  content: "";
  background-image: linear-gradient(to right, #6db9ef, #7ce08a);
  width: 50px;
  height: 30px;
  position: absolute;
  z-index: -10;
  margin-top: -15px;
  border-top-left-radius: 70px;
  border-bottom-right-radius: 90px;
}

.jobportal #recruiters img {
  max-width: 100px;
  margin: 10px 20px;
}

.jobportal .col-md-3 {
  display: inline-block;
  margin-left: -4px;
}

.jobportal .col-md-3 img {
  width: 100%;
  height: auto;
}

.jobportal body .carousel-indicators li {
  background-color: red;
}

.jobportal body .carousel-control-prev-icon,
body .carousel-control-next-icon {
  background-color: red;
}

.jobportal body .no-padding {
  padding-left: 0;
  padding-right: 0;
}

/* category wise jobs */

.jobportal .category ul li {
  list-style: none;
}

.jobportal .category {
  padding: 30px;
  border-radius: 10px;
  margin: 20px 0;
  box-shadow: 0 2px 40px 0 rgba(110, 130, 208, 0.18);
}

.jobportal .jobcategory {
  width: 20%;
}

.jobportal .categories {
  display: inline-flex;
  justify-content: space-evenly;
}

.jobportal h5 {
  border-bottom: 1px solid black;
}

@media only screen and (max-width: 1000px) {
  .jobportal .categories {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .jobportal .jobcategory {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}

/* recent jobs */

.jobportal #jobs {
  /* background: #f8f8fa; */
  padding: 50px 0;
}
.htgg {
  margin-bottom: 0px;
}
.jobportal .company-details {
  box-shadow: 0 2px 70px 0 rgba(110, 130, 208, 0.18);
  border-radius: 5px;
  margin-bottom: 20px;
}

.monitoe .detail-section {
  position: relative;
  color: #fff;
  background-size: cover !important;
  background-position: center !important;
  height: 510px;
}
.monitoe .detail-section .overlay {
  -webkit-backface-visibility: hidden;
  position: absolute;
  width: 100%;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  opacity: .4;
  background-color: #242429;
  transition: opacity .3s ease-in-out;
}
.monitoe .profile_detail_block{
  background:#ffffff;
  padding:25px 15px;
  border:2px solid #f1f3f4;
  border-radius:6px;
  display:inline-block;
  box-shadow:0 3px 10px rgba(0, 0, 0, 0.04);
}

/* Define keyframes for border animation */
@keyframes multiColorBorder {
  0% {
    border-color: #ff5f6d;
  }
  25% {
    border-color: #ffc371;
  }
  50% {
    border-color: #4cb8c4;
  }
  75% {
    border-color: #00d2ff;
  }
  100% {
    border-color: #ff5f6d;
  }
}

/* Apply animation to active tabs */
.tbbtn.active {
  animation: multiColorBorder 2s infinite;
}
.monitoe .job-owner-detail {
  text-align: left;
  margin-left: 0px;
  padding: 16px 0 8px 90px;
  border-radius: 2px;
  height: 80px;
  background: #ffffff;
  margin-top: 0px;
  border: 1px solid #eaeff5;
  box-shadow: 0px 0px 10px 1px rgba(71, 85, 95,0.08);
  -webkit-box-shadow: 0px 0px 10px 1px rgba(71, 85, 95,0.08);
  -moz-box-shadow: 0px 0px 10px 1px rgba(71, 85, 95,0.08);
}
.monitoe .job-owner-detail h4 {
  margin-bottom: 1px;
  font-size: 17px;
  margin-top: 0;
}
.monitoe .job-owner-detail span {
  font-size: 13px;
}
/* /--------- Job Detail Page: Detail Wrapper ------------------/ */
.monitoe .detail-wrapper {
  width: 100%;
  display: table;
  border-radius: 6px;
  margin-bottom: 40px;
  border: 1px solid #eaeff5;
}
.monitoe .white-box {
  background: #ffffff;
  border-radius: 6px;
}
.monitoe .white-shadow {
  border: 1px solid #eaeff5;
  box-shadow: 0px 0px 10px 1px rgba(71, 85, 95,0.08);
  -webkit-box-shadow: 0px 0px 10px 1px rgba(71, 85, 95,0.08);
  -moz-box-shadow: 0px 0px 10px 1px rgba(71, 85, 95,0.08);
}
.monitoe .detail-wrapper-body {
  padding: 1.5em 1em 1.5em 1.5em;
}
.monitoe .detail-wrapper-body p:last-child, .detail-list:last-child, .detail-list li:last-child{
  margin-bottom:0;
}
.monitoe .detail-wrapper-body h4 {
  color:#26ae61;
  background:rgba(38, 174, 97, 0.2);
  display: inline-block;
  padding: 5px 10px;
  font-size:16px;
  border-radius: 4px;
  text-align: center;
}
.monitoe .detail-wrapper-body span {
  width:100%;
  display:block
}
.monitoe .user_job_detail span{
  width:auto;
  display:inline-block;
}
.monitoe .detail-info span{
  display:inline-block;
  width:auto;
}
.detail-wrapper-body span.skill-tag{
    width: auto;
    display: inline-block;
}
.monitoe .detail-wrapper-header {
  background:#424242;
  border-radius: 6px 6px 0 0;
  padding:20px 25px;
  border-bottom: 1px solid #eaeff5;
}
.monitoe .detail-wrapper-header h4 {
  margin: 0;
  font-weight:500;
  color:#ffffff;
}
.monitoe .detail-list-rating {
  float: left;
  margin-right: 15px;
}
.monitoe .detail-list-rating i {
  color: #ffc107;
}
.monitoe span.job-tag {
  font-size: 12px;
  padding: 5px 15px;
  border-radius: 2px;
  top: -3px;
  position: relative;
}
.monitoe .detail-wrapper-body ul.detail-check {
  margin: 0;
  padding: 0;
  width: 100%;
  display: inline-table;
}
.monitoe .detail-wrapper-body ul.detail-check li {
  list-style: none;
  display: inline-block;
  width: 33.333333%;
  padding: 6px 0 5px 30px;
  position: relative;
}
.monitoe .detail-wrapper-body ul.detail-check li:before {
  width: 19px;
  height: 19px;
  position: relative;
  content: "\f00c";
  font-family: "FontAwesome";
  font-size: 12px;
  color: #ffffff;
  display: inline-block;
  left: 0;
  top: 8px;
  text-align: center;
  line-height: 16px;
  background-color: #26ae61;
  border: 2px solid transparent;
  transition: border-color 0s;
  border-radius: 4px;
  position: absolute;
  border: 1px solid #eaeff5;
  box-shadow: 0px 0px 10px 1px rgba(71, 85, 95,0.08);
  -webkit-box-shadow: 0px 0px 10px 1px rgba(71, 85, 95,0.08);
  -moz-box-shadow: 0px 0px 10px 1px rgba(71, 85, 95,0.08);
}
.monitoe button.btn.remove-field.light-gray-btn {
  margin-left: 15px;
}
.monitoe .detail-list {
  margin: 0 0 20px 0;
  padding: 0;
  display: inline-block;
  width: 100%;
}
.monitoe .detail-list li {
  list-style: none;
  float: left;
  width: 100%;
  margin: 0;
  margin-bottom: 0px;
  position: relative;
  padding-left: 23px;
  line-height: 22px;
  margin-bottom: 12px;
  font-size: 14px;
}
.monitoe .detail-list li::before {
  position: absolute;
  left: 0;
  top: 11px;
  width: 10px;
  height: 2px;
  content: "";
  background: #929292;
}
/* /------------- Job Detail Page: Resume Detail Timeline ---------------/ */
.monitoe .edu-history {
  float: left;
  width: 100%;
  display: table;
  position: relative;
  padding-left: 40px;
  margin-bottom: 24px;
  padding-bottom: 0;
}
.monitoe .edu-history:before {
  position: absolute;
  left: 7px;
  top: 25px;
  width: 2px;
  height: 100%;
  content: "";
  background: #eaeff5;
}
.monitoe .edu-history:last-child:before {
  display: none;
}
.monitoe .edu-history .detail-info h3{
  background:rgba(38, 174, 97, 0.2);
  padding:4px 8px;
  font-weight:500;
  border-radius:4px;
  display: inherit;
  float: none;
}

.monitoe .detail-info {
  display: table-cell;
  vertical-align: top;
}
.monitoe .detail-info > h3 {
  float: left;
  width: 100%;
  font-size: 16px;
  color: #26ae61;
  margin: 0;
  margin-top: 0px;
}
.monitoe .detail-info > i {
  float: left;
  width: 100%;
  font-style:normal;
  font-weight:500;
  font-size: 14px;
  margin-top: 8px;
  margin-bottom:8px;
}
.monitoe .detail-info > p {
  float: left;
  width: 100%;
  margin: 0;
  font-size: 14px;
  font-style: normal;
  line-height: 24px;
  margin-top: 10px;
}
/* /------------ Job Detail Page: Popup Style --------------/ */
.monitoe .modal-content img {
  margin-bottom: 25px;
}
/* /----------- Job Detail Page: Sidebar --------------/ */
.monitoe .widget-boxed {
  background-color: #fff;
  border-radius: 6px;
  padding:25px 20px;
  transform: translate3d(0, 0, 0);
  z-index: 1;
  margin-bottom: 25px;
  position: relative;
  border: 1px solid #eaeff5;
  box-shadow: 0 4px 10px 0 rgba(41,128,185,0.07);
  -webkit-box-shadow: 0 4px 10px 0 rgba(41,128,185,0.07);
}
.monitoe .widget-boxed .btn{
  margin-bottom:0;
}
.monitoe .btn-success:hover, .btn-success:focus {
    color: #fff;
    background-color: #449d44;
    border-color: #398439;
  outline: 0px auto -webkit-focus-ring-color;
}
.monitoe .widget-boxed-header {
  padding:0 0 15px 0;
  border-bottom: 1px solid #eaeff5;
  margin-bottom:10px;
}
.monitoe .widget-boxed-header h4 {
  margin: 0;
  font-size:18px;
}
.monitoe .widget-boxed-header h4 i{
  background:#26ae61;
  color:#fff;
  width:36px;
  height:36px;
  line-height:34px;
  text-align:center;
  border-radius:30px;
  padding:0;
  font-size:18px;
  display: inline-block;
  margin-right: 10px;
}
.monitoe .widget-boxed-header h4 a i{
  background:#26ae61;
  color:#fff;
  width:36px;
  height:36px;
  line-height:34px;
  text-align:center;
  border-radius:30px;
  padding:0;
  font-size:18px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: -8px;
  right: -15px;
}
.monitoe .side-list ul {
  margin: 0;
  padding: 0;
  margin-bottom:0px;
}
.monitoe .side-list ul li {
  list-style: none;
  padding: 7px 5px;
  display: inline-block;
  width: 100%;
  border-bottom: 1px dashed #eaeff5;
}
.monitoe ul.side-list-inline.social-side li {
  display: inline-block;
  width: auto;
  padding: 10px 0 10px 3px;
}
.monitoe .side-list ul.social-side li{
  border-bottom:0;
  padding-top:0;
  padding-bottom:0;
}
.monitoe .side-list ul li span {
  float: right;
}
.monitoe .side-list ul li:last-child{
  border-bottom: none;
}
.monitoe ul.side-list-inline.social-side li a i {
  width: 40px;
  height: 40px;
  display: inline-block;
  line-height: 38px;
  text-align: center;
  border-radius: 50%;
  transition: all ease .4s;
  border: 1px solid #eaeff5;
  box-shadow: 0 0 10px 1px rgba(71, 85, 95, .05);
  -webkit-box-shadow: 0 0 10px 1px rgba(71, 85, 95, .05);
  -moz-box-shadow: 0 0 10px 1px rgba(71, 85, 95, .05);
}
.monitoe ul.side-list-inline.social-side li a :hover, ul.side-list-inline.social-side li a :focus {
  background: #26ae61;
  color: #ffffff;
  border-color:#26ae61;
}
.monitoe .widget-boxed-body form{
  margin-top:25px;
}
 .monitoe .widget-boxed-body a.full-width i {
  margin-right: 5px;
}
/*===========================================================
        UI Element Style 
============================================================*/
/* /-------------- UI Element Style: Testimonial Style ------------------------/ */
.monitoe .testimonial-detail {
  padding: 40px;
  border-radius: 6px;
  margin-bottom: 40px;
  position: relative;
  margin: 20px 50px;
  box-shadow: 0 5px 25px 0 rgba(41,128,185,0.15);
  -webkit-box-shadow: 0 5px 25px 0 rgba(41,128,185,0.15);
}
.monitoe .testimonial-detail .pic {
  position: absolute;
  top: -10px;
  left: -10px;
}
.monitoe .testimonial-detail h3.user-testimonial-title {
  font-size: 18px;
  margin-bottom: 10px;
}
.monitoe .testimonial-detail .user-description {
  line-height: 1.7;
  font-size: 16px;
  font-weight: 300;
  font-style: italic;
}
.monitoe .testimonial-detail .client-rating {
  position: absolute;
  top: 20px;
  right: 20px;
}
.monitoe .testimonial-detail .client-rating i {
  font-size: 12px;
  color: #26ae61;
}
.monitoe .slick-initialized .slick-slide:focus {
  outline: none;
}


.jobportal .job-update {
  background: white;
  padding: 10px 20px;
  border-radius: 5px;
}

.jobportal .job-update h4 strong {
  font-size: 30px;
  font-family: "Oswald", sans-serif;
}

.jobportal .job-update .fa {
  margin-top: 5px;
  margin-right: 10px;
  color: #77d1b1;
}

.jobportal .price {
  color: #018f62;
}

.jobportal .location {
  color: red;
}

/* .description {
  display: none;
}
.company-details :hover .description {
  display: block;
}
 */

.jobportal .description {
  overflow: hidden;
  height: 0;
  opacity: 0;
  transition:
    height 0ms 400ms,
    opacity 400ms 0ms;
}

.jobportal .company-details :hover .description {
  height: auto;
  opacity: 1;
  transition:
    height 0ms 0ms,
    opacity 600ms 0ms;
}

.jobportal .bookmark {
  color: #000 !important;
  float: right;
  display: inline-flex;
  font-size: 20px;
  cursor: pointer;
}

.jobportal .bookmark p {
  margin-left: 5px;
  font-size: 20px;
  cursor: pointer;
}

.jobportal .fa-heart-o:before {
  content: "\f08a";
  margin-right: 8px;
  font-size: 20px;
  cursor: pointer;

  color: rgb(33, 33, 226) !important;
}

.jobportal .fa-heart-o:before .bookmark {
  color: rgb(33, 33, 226) !important;
}

.jobportal .fa-heart:before {
  content: "\f004";
  margin-right: 8px;
  font-size: 20px;
  cursor: pointer;
  color: rgb(33, 33, 226) !important;
}

.jobportal .job-update small {
  background: #efefef;
  padding: 1px 5px;
  margin: 0 5px;
}

.jobportal .job-update small:hover {
  cursor: pointer;
  background: #23c0e9;
  z-index: 2;
  transition: all 200ms ease-in;
  transform: scale(1.1);
  color: white;
}

.jobportal .job-update p {
  margin-bottom: 0 !important;
}

.jobportal .job-update a {
  text-decoration: none !important;
  color: #b6bed2;
  float: right;
}

.jobportal .job-update a:hover {
  text-decoration: none !important;
  color: rgb(0, 95, 238);
  float: right;
}

.jobportal .apply-btn {
  height: 40px;
  background-image: linear-gradient(to right, #6db9ef, #111136);
  padding: 5px;
  border-bottom-left-radius: 5px;
}

.jobportal .apply-btn .btn-primary {
  padding: 0 30px;
  float: right;
  box-shadow: none !important;
  background: transparent;
  border: 1px solid #fff;
  width: 100px;
  height: 30px;
  line-height: 30px;
  background: #f36969;
  color: #ffffff;
  font-size: 16px;
  text-transform: uppercase;
  border-radius: 10px;
}

.jobportal .pagelink li {
  height: 25px;
  list-style: none;
  width: 25px;
  display: inline-block;
  cursor: pointer;
}

.jobportal .pagelink li:hover,
.activa {
  color: #fff;
  background-image: linear-gradient(to right, #6db9ef, #7ce08a);
}

.jobportal .left-arrow {
  color: #999;
}

.jobportal .right-arrow {
  color: #29b929;
}

/* site-stats */

.jobportal #site-stats {
  padding: 100px 0;
}

.jobportal #site-stats {
  margin-bottom: 30px;
}

.jobportal .stats-box {
  padding: 30px;
  border-radius: 10px;
  margin: 20px 0;
  box-shadow: 0 2px 40px 0 rgba(110, 130, 208, 0.18);
}

.jobportal .stats-box:hover {
  transition: all 0.2s ease-out;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  border: 1px solid #cccccc;
  background-color: #00838d;
  transform: scale(1.1);
}

.jobportal .stats-box:hover p {
  transition: all 0.3s ease-out;
  color: #ffffff;
}

.jobportal .stats-box:hover i {
  transition: all 0.3s ease-out;
  color: #ffffff;
}

.jobportal .stats-box:hover small {
  transition: all 0.3s ease-out;
  color: #ffffff;
}

.jobportal .stats-box .fa {
  font-size: 30px;
  margin-right: 10px;
  margin-bottom: 10px;
}

/* footer */

.jobportal .page-footer {
  background-color: rgb(37, 28, 28);
}

.jobportal .icon_name {
  padding-left: 10px;
  cursor: pointer;
}

.jobportal .icon_name:hover {
  color: #00ff00;
}

.jobportal .dark-grey-text {
  color: #fff;
}

.jobportal .marqueess {
  white-space: nowrap;
  overflow: hidden;
  margin: 0 auto;
  padding: 5px 0;
  /* background-color: #e1a6ad; */
  color: #00ff00;
}

/* Basic Styles for Job Cards */
.jobportal .job-card {
  background-color: #fff;
  border: 1px solid #ebebeb;
  margin-bottom: 30px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.jobportal .job-card .job-type {
  background-color: #007bff;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.jobportal .job-card .job-like {
  position: absolute;
  top: 10px;
  right: 10px;
}

.jobportal .job-card .job-content {
  margin-top: 20px;
}

.jobportal .job-card .avatar {
  margin-bottom: 10px;
}

.jobportal .job-card h5 a {
  color: #333;
  font-size: 16px;
}

.jobportal .job-card p {
  color: #999;
  font-size: 14px;
  margin-bottom: 15px;
}

.jobportal .job-card .apply-btn {
  margin-top: 15px;
}

.jobportal .job-card .apply-btn .btn {
  background-color: #28a745;
  color: #fff;
  border: none;
}

.jobportal .job-card .apply-btn .btn:hover {
  background-color: #218838;
}

/* Additional Styling for Navigation Tabs (if needed) */
.jobportal .nav-tabs {
  background-color: #f8f9fa;
  border-bottom: 2px solid #dee2e6;
  padding-top: 10px;
  margin-bottom: 30px;
}

.jobportal .nav-tabs .nav-link {
  color: #333;
  font-weight: bold;
}

.jobportal .nav-tabs .nav-link.active {
  background-color: #007bff;
  color: #fff;
  border: 1px solid #007bff;
  border-bottom-color: transparent;
}

.jobportal #utf_job_category_area {
  /* background: #f8fafb; */
  padding: 80px 0;
}

.jobportal .utf_category_box_area {
  position: relative;
  background: #fff;
  box-shadow: 0 5px 25px 0 rgba(41, 128, 185, 0.15);
  border-radius: 10px;
  text-align: center;
  padding: 30px 15px;
  box-sizing: border-box;
  margin-bottom: 30px;
  overflow: hidden;
  transition: all 0.4s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.jobportal .utf_category_box_area:hover {
  transform: scale(1.06);
}

.jobportal .utf_category_box_area .utf_category_desc {
  z-index: 2;
}

.jobportal .utf_category_box_area .utf_category_desc .utf_category_icon {
  background: #ad297052;
  font-size: 40px;
  color: #ffffff;
  width: 80px;
  height: 80px;
  line-height: 72px;
  border: 2px solid #26ae61;
  border-radius: 50px;
  text-align: center;
  margin: 0 auto 20px auto;
  transition: 0.6s cubic-bezier(0.45, 0.01, 0.1, 1.49);
}


.lostBy .col-4{
  padding-right: 5px !important;
  padding-left: 5px !important;
}




.liceninput1  .css-13cymwt-control {
  background: transparent;
  border: none;
  /* border-bottom: 3px solid #ffffff; */
}
.liceninput1  input {
  background: transparent;
  color: #ffffff;
  
}
.liceninput1   .css-1dimb5e-singleValue {
  background: transparent;
  color: #ffffff;

}
.liceninput1 .css-t3ipsp-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  background: transparent;
  border-color: #2684FF;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 0 0 1px #2684FF;
  box-sizing: border-box;
}

.liceninput1 .css-13cymwt-control:hover {
  
  border: none;
  /* border-bottom: 3px solid #ffffff; */
}




.liceninput .css-1dimb5e-singleValue {
  /* grid-area: 1 / 1 / 2 / 3; */
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: hsl(0, 0%, 20%);
  margin-left: 2px;
  margin-right: 2px;
  margin-top: 17px;
  box-sizing: border-box;
}




/* css jaishree */
:root {
  --c1: #0177cd;
  --c2: #ff6767;
  --title-color: #334257;
  --text-color: #5b6777;
  --border-color: rgba(180, 208, 224, 0.5);
  --font: "Inter", sans-serif;
}

body {
  font-family: var(--font);
  color: var(--text-color);
  line-height: 1.4;
  background-color: #f9f9fb;
  -webkit-font-smoothing: antialiased;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--title-color);
  line-height: 1.2;
}

.list-unstyled,
.option-select-btn {
  margin: 0;
  padding: 0;
  list-style: none;
}

a:hover {
  color: var(--c1);
}

.d--none {
  display: none;
}

.radius-5 {
  border-radius: 5px !important;
}

.radius-10 {
  border-radius: 10px !important;
}

.radius-20 {
  border-radius: 20px !important;
}

.radius-50 {
  border-radius: 50px !important;
}

.badge-soft-dark {
  background: #e0e7ee;
}

.btn-ghost-secondary {
  color: rgb(7, 59, 116);
}

.navbar-brand img {
  height: 48px !important;
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: left center;
  object-position: left center;
}

.navbar-brand-wrapper {
  overflow: hidden;
}

.max-h-30 {
  max-height: 30px !important;
}

.h-30 {
  height: 30px !important;
}

.navbar-vertical-aside-mini-mode .navbar-brand {
  display: none;
}

.navbar-vertical-content {
  background: #073b74;
  padding: 10px 15px;
}

.navbar-vertical-content .nav-link {
  border-radius: 5px !important;
}

.navbar-vertical-content .nav-link:hover {
  color: #8ce98a;
  background: rgba(239, 246, 255, 0.1);
}

.navbar .active > .nav-link,
.navbar .nav-link.active,
.navbar .nav-link.show,
.navbar .show > .nav-link {
  color: #8ce98a;
  background-color: rgba(239, 246, 255, 0.1);
}

.navbar .has-sub-item.active > .nav-link {
  background-color: transparent;
}

.navbar-vertical .active .nav-indicator-icon,
.navbar-vertical .nav-link:hover .nav-indicator-icon,
.navbar-vertical .show > .nav-link > .nav-indicator-icon {
  color: #8ce98a;
}

.navbar-vertical .navbar-nav.nav-tabs .active .nav-link,
.navbar-vertical .navbar-nav.nav-tabs .active.nav-link {
  border-left: none;
}

.navbar-dropdown-account-wrapper:focus,
.navbar-dropdown-account-wrapper:hover {
  background-color: transparent;
}

@media (min-width: 1200px) {
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
      .navbar-vertical-content
      > .navbar-nav
      > .navbar-vertical-aside-has-menu
      > .nav {
      background-color: #073b74;
  }
}
.cmn--media {
  font-size: 12px;
}

.cmn--media .card-title {
  font-size: 14px;
  font-weight: 700;
  color: #334257;
}

.right-dropdown-icon {
  position: relative;
}

.right-dropdown-icon::before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  border: 4px solid #334257;
  border-top-color: transparent;
  border-left-color: transparent;
  transform: rotate(45deg);
  left: -17px;
  top: 2px;
}

.navbar-vertical-aside-mini-mode .navbar-brand-wrapper {
  justify-content: center !important;
}

.navbar-vertical-aside-mini-mode aside .navbar-brand {
  display: none !important;
}

/*Design Panel Updated CSS*/
.navbar__brand-wrapper {
  padding: 5px 20px !important;
}

.cmn--media {
  font-size: 12px;
}

.cmn--media .card-title {
  font-size: 14px;
  font-weight: 700;
  color: #334257;
}

.right-dropdown-icon {
  position: relative;
}

.right-dropdown-icon::before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  border: 4px solid #334257;
  border-top-color: transparent;
  border-left-color: transparent;
  transform: rotate(45deg);
  left: -17px;
  top: 2px;
}

.navbar-vertical-aside-mini-mode .navbar-brand-wrapper {
  justify-content: center !important;
}

.navbar-vertical-aside-mini-mode aside .navbar-brand {
  display: none !important;
}

.d--xl-none {
  display: none !important;
}

@media screen and (max-width: 1199.99px) {
  .d--xl-none {
      display: block !important;
  }
}
.navbar-vertical-aside .nav-link {
  color: #e9f3ff;
}

aside .navbar-vertical-aside-has-menu .nav-sub .nav-link {
  font-size: 0.8rem;
}

aside .navbar-nav > .nav-item:not(:first-child) {
  margin-top: 15px !important;
}

aside li .nav-link > span:not(:first-child) {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
}

.navbar-vertical-aside-mini-mode
  .navbar-vertical:not([class*="container"])
  .navbar-nav
  .nav-link {
  padding-left: 1.75rem !important;
}

.navbar-vertical-aside-mini-mode .search--form-group {
  display: none;
}

.swal2-popup .swal2-styled.swal2-cancel {
  color: #fff !important;
  background: var(--c2) !important;
}

.swal2-popup .swal2-styled:focus,
.swal2-popup .swal2-styled.swal2-confirm {
  box-shadow: none !important;
}

.swal2-popup {
  border-radius: 10px !important;
  padding: 30px !important;
  width: 100% !important;
  max-width: 500px !important;
  font-size: 1em !important;
}

.swal2-popup .swal2-icon {
  margin-top: 0 !important;
  margin-bottom: 1.5em !important;
}

.swal2-popup .swal2-styled {
  min-width: 170px;
  text-align: center;
}

@media screen and (min-width: 575px) {
  .swal2-popup .swal2-styled {
      margin: 0.3125em 0.8em !important;
  }
}
.swal2-popup .swal2-title {
  font-size: 1.5em !important;
}

.swal2-popup .swal2-actions {
  margin-top: 1.5em !important;
}

.swal2-popup .swal2-close {
  color: var(--title-color) !important;
  right: 15px !important;
  top: 15px !important;
}

.dropdown-item {
  padding: 0.75rem 1.5rem;
}

.nav-subtitle {
  color: #a3b9d2;
  font-weight: 700;
}

.table {
  color: var(--title-color);
}

.table .thead-50 {
  block-size: 50px;
}

.table .thead-50 th {
  vertical-align: middle;
}

.table .thead-light th {
  color: var(--title-color);
  background-color: #f7faff;
  border-color: rgba(1, 119, 205, 0.1);
}

thead.text-capitalize th {
  text-transform: capitalize;
  font-size: 14px;
}

.font-weight-medium {
  font-weight: 500;
}

.side-logo {
  background-color: #ffffff;
}

.grid-card-wrap {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
}

.grid-card {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  background-color: #fff;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.grid-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  background-color: rgba(110, 137, 175, 0.05);
  padding: 10px 14px;
  border-radius: 10px;
}

.grid-item .shop-name,
.grid-item .shop-sell {
  margin-bottom: 0;
  font-weight: 400;
}

.grid-item .shop-name.c2,
.grid-item .shop-sell.c2 {
  color: rgb(255, 109, 109);
}

.grid-item .shop-sell {
  color: var(--c1);
  font-weight: 700;
}

.grid-item-wrap {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}

.orders-count {
  background-color: var(--c1);
  color: #fff;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  padding: 4px 10px;
  white-space: nowrap;
}

label {
  text-transform: capitalize;
}

.label_1 {
  /*position: absolute;*/
  font-size: 10px;
  background: #370665;
  color: #ffffff;
  width: 80px;
  padding: 2px;
  font-weight: bold;
  border-radius: 6px;
  text-align: center;
}

.center-div {
  text-align: center;
  border-radius: 6px;
  padding: 6px;
  border: 2px solid #eeeeee;
}

.icon-card {
  background-color: #22577a;
  border-width: 30px;
  border-style: solid;
}

/* app blade internal */
.flex-start {
  display: flex;
  justify-content: flex-start;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.row-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.row-center {
  display: flex;
  justify-content: center;
}

.navbar-vertical-content .scroll-bar {
  max-height: calc(100vh - 100px);
  overflow-y: auto !important;
}

.navbar-vertical-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px #cfcfcf;
}

.navbar-vertical-content::-webkit-scrollbar {
  width: 3px !important;
  height: 3px !important;
}

.navbar-vertical-content::-webkit-scrollbar-thumb {
  background: #c1c1c1;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .map-warper {
      height: 250px;
      padding-bottom: 10px;
  }
}
.deco-none {
  color: inherit;
  text-decoration: inherit;
}

.qcont:first-letter {
  text-transform: capitalize;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

@media only screen and (min-width: 768px) {
  .view-web-site-info {
      display: none;
  }
}
/* Color */
.title-color {
  color: var(--title-color);
}

.light-bg {
  background-color: #fcfcfc;
}

.c1 {
  color: var(--c1) !important;
}

.text-color {
  color: var(--text-color) !important;
}

/* Font Size */
.fz-45 {
  font-size: 45px !important;
}

.fz-30 {
  font-size: 30px !important;
}

.fz-24 {
  font-size: 24px !important;
}

.fz-22 {
  font-size: 22px !important;
}

.fz-18 {
  font-size: 18px !important;
}

.fz-16 {
  font-size: 16px !important;
}

.fz-14 {
  font-size: 14px !important;
}

.fz-12 {
  font-size: 12px !important;
}

.fz-10 {
  font-size: 10px !important;
}

/* Direction */
.ltr {
  direction: ltr;
}

.rtl {
  direction: rtl;
}

/* Gap */
.gap-1 {
  gap: 4px;
}

.gap-2 {
  gap: 8px;
}

.gap-3 {
  gap: 16px;
}

.gap-4 {
  gap: 24px;
}

.gap-5 {
  gap: 48px;
}

.gap-10 {
  gap: 10px;
}

.gap-20 {
  gap: 20px;
}

/* Cursor */
.cursor-pointer {
  cursor: pointer;
}

/* Switcher */
.switch,
.switcher {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  inline-size: 36px;
  block-size: 18px;
  margin-bottom: 0;
}

.switch .slider,
.switch_control,
.switcher .slider,
.switcher_control {
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  inline-size: 36px;
  block-size: 18px;
  transition: background-color 0.15s ease-in;
  background-color: #ced7dd;
  border-radius: 50px;
}

.switch .slider::after,
.switch_control::after,
.switcher .slider::after,
.switcher_control::after {
  content: "";
  position: absolute;
  inset-block-start: 1px;
  inset-inline-start: 1px;
  inline-size: 16px;
  block-size: 16px;
  transition: left 0.15s ease-in;
  background-color: #fff;
  border-radius: 100%;
}

.switch .status,
.switch_input,
.switcher .status,
.switcher_input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.switch .status:checked ~ .slider,
.switch_input:checked ~ .switcher_control,
.switcher .status:checked ~ .slider,
.switcher_input:checked ~ .switcher_control {
  background-color: var(--c1);
}

.switch .status:checked ~ .slider:after,
.switch_input:checked ~ .switcher_control:after,
.switcher .status:checked ~ .slider:after,
.switcher_input:checked ~ .switcher_control:after {
  inset-inline-start: 19px;
}

.show-status-text .switcher_control::before {
  content: "OFF";
  -webkit-margin-start: -40px;
  margin-inline-start: -40px;
  color: #9e9e9e;
  font-weight: 700;
}

.show-status-text .switcher_input:checked + .switcher_control::before {
  content: "ON";
  color: var(--c1);
}

/* Business Analytics */
.business-analytics {
  background-color: #fff;
  border-radius: 10px;
  padding: 40px 30px 20px;
  position: relative;
  border: 1px solid rgba(180, 208, 224, 0.5);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  height: 100%;
  transition: all 0.3s ease;
}

.business-analytics__subtitle {
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 600;
}

.business-analytics__title {
  font-weight: 700;
  font-size: 24px;
}

.business-analytics__img {
  position: absolute;
  inset-block-start: 16px;
  inset-inline-end: 16px;
}

/* Order Stats */
.order-stats {
  background-color: rgba(110, 137, 175, 0.0509803922);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  height: 100%;
  transition: all 0.3s ease;
}

.order-stats__content {
  display: flex;
  align-items: center;
  gap: 10px;
}

.order-stats__title {
  color: #0661cb;
  font-size: 18px;
  font-weight: 700;
}

.order-stats__subtitle {
  font-weight: 600;
  font-size: 14px;
  color: #334257;
  margin-bottom: 0;
}

.order-stats_out-for-delivery .order-stats__title,
.order-stats_confirmed .order-stats__title {
  color: #00aa6d;
}

.order-stats_failed .order-stats__title,
.order-stats_canceled .order-stats__title,
.order-stats_packaging .order-stats__title {
  color: #ff6d6d;
}

.order-stats:hover {
  box-shadow: 2px 2px 15px rgba(7, 59, 116, 0.15);
}

/* Option Select */
.option-select-btn {
  display: flex;
  background: #ffffff;
  border: 1px solid #b4d0e0;
  border-radius: 5px;
  padding: 3px;
}

.option-select-btn label {
  margin: 0;
}

.option-select-btn label span {
  color: var(--c1);
  border-radius: 5px;
  text-transform: capitalize;
  padding: 6px 12px;
  cursor: pointer;
  display: block;
  font-size: 12px;
}

.option-select-btn label input:checked ~ span {
  color: #fff;
  background-color: var(--c1);
  font-weight: 700;
}

/* Margin */
.mb-30 {
  margin-bottom: 30px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

/* Button */
.btn--primary {
  background-color: var(--c1);
  color: #fff;
}

.btn--primary:hover {
  background-color: #04a3e7;
  color: #fff;
}

.btn--bordered {
  border: 1px solid #d0dbe9;
  border-radius: 3px;
  color: var(--title-color);
}

.btn--bordered:hover {
  background-color: var(--c1);
  border-color: var(--c1);
  color: #fff;
}

.btn--bordered-black {
  border: 1px solid #d0dbe9;
  border-radius: 3px;
  color: var(--title-color);
}

.btn--bordered-black:hover {
  background-color: #334257;
  border-color: #334257;
  color: #fff;
}

.btn-outline--primary {
  color: var(--c1);
  border-color: var(--c1);
}

.btn-outline--primary:hover {
  background-color: var(--c1);
  color: #fff;
}

.btn svg path,
.btn svg stroke {
  transition: all 0.3s ease;
}

.btn:hover svg path {
  fill: #fff;
}

.card-header {
  border-bottom: 1px solid #f4f4f4;
  box-shadow: 0px 3px 3px rgba(51, 66, 87, 0.05);
}

.card-header__title {
  margin-bottom: 0;
  font-weight: 600;
}

.dropdown-divider {
  margin: 0;
}

.amount-of-user {
  position: absolute;
  inset-block-start: 80px;
  inset-inline-start: 50%;
  transform: translateX(-50%);
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .amount-of-user {
      display: none;
  }
}
.amount-of-user span {
  display: block;
  font-weight: 400;
}

.user-overview-wrap {
  display: grid;
  grid-template-columns: 1fr 300px;
  gap: 8px;
}

@media only screen and (max-width: 767px) {
  .user-overview-wrap {
      grid-template-columns: 1fr;
  }
}
.rating-color {
  color: rgb(240, 111, 0);
}

/* Customizations */
.btn.rounded-circle .btn-sm-status {
  top: -12px;
  right: -12px;
  padding: 2px;
}

.navbar-vertical .badge-soft-danger {
  background-color: rgba(237, 76, 120, 0.3);
}

.navbar-vertical .badge-soft-info {
  background-color: rgba(0, 201, 219, 0.2);
}

#updatingData {
  height: 20rem;
}

.nav-indicator-icon {
  font-size: 6px;
}

.dropdown-toggle-left-arrow {
  padding-left: 20px;
}

.dropdown-toggle-left-arrow:after {
  position: absolute;
  left: 0;
}

.dropdown-toggle-left-arrow:not(.dropdown-toggle-empty)::after {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 6px solid var(--title-color);
}

.dropdown-menu {
  width: 16rem;
}

.search-card {
  position: absolute;
  background: white;
  z-index: 999;
  width: 100%;
  display: none;
}

.z-index-1 {
  z-index: 1;
}

.search-result-box {
  overflow: scroll;
  overflow-x: hidden;
}

.navbar-nav-wrap-content-right a.btn-ghost-secondary {
  padding: 0;
  width: auto;
  height: auto;
}

.navbar-nav-wrap-content-right a.btn-ghost-secondary:hover {
  color: var(--c1);
  background-color: transparent;
}

.navbar-nav-wrap-content-left .navbar-nav > .nav-item:not(:last-child),
.navbar-nav-wrap-content-right .navbar-nav > .nav-item:not(:last-child) {
  margin-right: 16px;
}

.btn-sm-status {
  top: -0.3125rem;
  right: -0.3125rem;
  line-height: 18px;
  font-size: 8px;
}

.btn-sm-status {
  width: 18px;
  height: 18px;
}

.avatar-60 {
  width: 60px !important;
  min-width: 60px !important;
  height: 60px !important;
}

.avatar-70 {
  width: 70px !important;
  min-width: 70px !important;
  height: 70px !important;
}

.card {
  border-color: #f4f4f4;
  box-shadow: 0px 5px 10px rgba(51, 66, 87, 0.05);
  transition: all 0.3s ease;
}

.card:hover {
  box-shadow: 0px 5px 24px rgba(7, 59, 116, 0.15);
}

.btn-link {
  color: #24bac3;
  white-space: nowrap;
}

.btn-link:hover {
  color: #24bac3;
  text-decoration: underline;
}

.avatar-bordered {
  border: 1px solid rgba(7, 59, 116, 0.3);
}

.navbar-vertical:not([class*="container"]) .navbar-nav .nav-subtitle,
.navbar-vertical:not([class*="container"]) .navbar-nav .nav-subtitle-replacer,
.navbar-vertical:not([class*="container"]) .navbar-nav .nav-title,
.navbar-vertical:not([class*="container"]) .navbar-nav .nav-link {
  padding-left: 10px;
  padding-right: 10px;
}

.navbar-vertical:not([class*="container"]) .navbar-nav .nav .nav-link {
  padding-left: 39px;
}

.sidebar--search-form .search--form-group {
  position: relative;
  transition: all ease 0.3s;
}

.sidebar--search-form .search--form-group .btn {
  position: absolute;
  left: 5px;
  top: 0;
  color: #99a7ba !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.sidebar--search-form .form--control {
  padding-left: 40px;
}

.form-control {
  border-color: rgb(208, 219, 233);
}

.form-control:focus {
  box-shadow: none;
  border-color: var(--c1);
}

.form-control_color {
  width: 140px;
  height: 90px;
  padding: 5px 7px;
}

input.qty {
  width: 50px;
  height: 30px;
  text-align: center;
}

.form--control {
  background: rgba(153, 167, 186, 0.2);
  border: 1px solid rgba(233, 243, 255, 0.4);
  border-radius: 5px;
  color: #99a7ba !important;
}

.form--control:focus {
  color: #fff !important;
  border-color: var(--c1);
  background: rgba(153, 167, 186, 0.2);
}

/* .h1,
h1 {
  font-size: 20px;
} */

.form-floating {
  position: relative;
}

.form-floating > .form-control:not(:-moz-placeholder-shown) {
  padding-top: 1rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:not(:-ms-input-placeholder) {
  padding-top: 1rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control-plaintext::-moz-placeholder,
.form-floating > .form-control::-moz-placeholder {
  color: transparent !important;
}

.form-floating > .form-control-plaintext:-ms-input-placeholder,
.form-floating > .form-control:-ms-input-placeholder {
  color: transparent !important;
}

.form-floating > .form-control-plaintext::-moz-placeholder,
.form-floating > .form-control::-moz-placeholder {
  color: transparent !important;
}

.form-floating > .form-control-plaintext:-ms-input-placeholder,
.form-floating > .form-control:-ms-input-placeholder {
  color: transparent !important;
}

.form-floating > .form-control-plaintext::placeholder,
.form-floating > .form-control::placeholder {
  color: transparent !important;
}

.form-floating > label,
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 0.625rem 1.25rem;
}

.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

.form-floating > label {
  background-color: #fff;
  padding: 0.5rem 0.75rem;
  inline-size: -webkit-min-content;
  inline-size: -moz-min-content;
  inline-size: min-content;
  block-size: auto;
  inset-inline-start: 0.5rem;
  inset-block-start: 0.25rem;
  line-height: 1;
}

.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:not(:-ms-input-placeholder) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  opacity: 1;
  transform: scale(1) translateY(-1.3rem) translateX(0rem);
}

.form-floating > .form-control:not(:-ms-input-placeholder) ~ label {
  opacity: 1;
  transform: scale(1) translateY(-1.3rem) translateX(0rem);
}

.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 1;
  transform: scale(1) translateY(-1.3rem) translateX(0rem);
}

.input-group-custom {
  border: 1px solid var(--c1);
  border-radius: 5px;
}

.input-group-custom input,
.input-group-custom .btn {
  border-radius: 0;
  border: none;
}

.page-item.active .page-link {
  background-color: var(--c1);
}

.page-link {
  background-color: #f4f5f7;
  border-color: #f4f5f7;
}

.page-item.disabled .page-link {
  background-color: #f4f5f7;
  border-color: #f4f5f7;
  opacity: 0.8;
}

.w-max-content {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.w-fit-content {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.w-160 {
  width: 160px;
}

.upload-img-view {
  block-size: 162px;
  inline-size: 162px;
  max-inline-size: 162px;
  border-radius: 5px;
  border: 0.5px solid #99a7ba;
  aspect-ratio: 1/1;
  -o-object-fit: cover;
  object-fit: cover;
}

.upload-img-view__banner {
  aspect-ratio: 3/1;
  inline-size: auto;
  max-inline-size: 100%;
}

.custom-file {
  cursor: pointer;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: var(--c1);
  border-color: transparent transparent var(--c1);
}

.nav-link:hover {
  color: var(--c1);
}

.max-50 {
  max-width: 50px;
}

.w-200 {
  width: 160px;
}

.hover-c1:hover {
  color: var(--c1) !important;
}

.editor-textarea {
  display: none;
}

.product-form .select2-container .select2-selection--single {
  display: flex;
  align-items: center;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow,
.product-form
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 42px !important;
  top: 0 !important;
}

.select2-container .select2-selection--single {
  display: flex !important;
  align-items: center;
}

.customer_choice_options .row [class*="col-"] {
  margin-bottom: 16px;
}

/* upload file */
.upload-file {
  position: relative;
  cursor: pointer;
}

.upload-file__input {
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 0;
  inline-size: 100%;
  block-size: 100%;
  opacity: 0;
  cursor: pointer;
}

.upload-file__img {
  block-size: 140px;
  inline-size: 140px;
  min-inline-size: 140px;
}

.upload-file__img img {
  border-radius: 10px;
  background-color: #fff;
  max-width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.upload-file__img_drag {
  inline-size: 100%;
  max-inline-size: 682px;
  block-size: auto;
}

@media only screen and (max-width: 767px) {
  .rest-part {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
  }
}
#update-quantity .form-control {
  margin-bottom: 0;
}

#update-quantity .card-header {
  padding: 0;
  box-shadow: none;
  border: none;
  padding-left: 20px;
}

#update-quantity .card-body {
  padding: 0;
  padding-left: 10px;
  padding-right: 10px;
}

@media only screen and (max-width: 767px) {
  #update-quantity form > .modal-body {
      overflow-x: auto;
  }
}
#update-quantity form input {
  min-width: 80px;
}

.banner_form .select2 {
  width: 100% !important;
}

 
  img {
   max-width: 100% ;
}

.productPage__displayImage  img {
  max-width: none;
  /* width: 100%; */
}

.text-ellipsis {
  text-overflow: ellipsis !important;
}

.text--primary {
  color: var(--c1) !important;
}

.bg-soft--primary {
  background-color: rgba(0, 144, 205, 0.1);
}

/* Seller waller */
.commission_given {
  background-color: #22577a;
}

.pending_withdraw {
  background-color: #595260;
}

.delivery_charge_earned {
  background-color: #a66f2e;
}

.collected_cash {
  background-color: #6e85b2;
}

.total_collected_tax {
  background-color: #6d9886;
}

.min-h-40 {
  min-height: 40px !important;
}

.company_web_logo {
  max-height: 55px;
}

.form-ellipsis + .select2,
.form-ellipsis,
.select2-selection {
  width: 100% !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: var(--c1);
  background-color: var(--c1);
}

.switcher_content {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: var(--title-color);
  margin-bottom: 0;
  cursor: pointer;
}

.dataTables_info,
.dataTables_length {
  -webkit-padding-start: 16px;
  padding-inline-start: 16px;
}

.dataTables_paginate,
#dataTable_filter {
  -webkit-padding-end: 16px !important;
  padding-inline-end: 16px !important;
}

.min-w-200 {
  min-width: 200px;
}

.loader-wrap {
  position: fixed;
  z-index: 9999;
  left: 40%;
  top: 37%;
  width: 100%;
}

.pos-item-wrap {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  gap: 15px;
  max-block-size: 61vh;
  overflow-y: auto;
}

.pos-product-item {
  cursor: pointer;
  overflow: hidden;
  border-radius: 5px !important;
  position: relative;
}

.pos-product-item_thumb {
  height: 152px;
}

.pos-product-item_content {
  text-align: center;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}

.pos-product-item_title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: var(--title-color);
  margin-bottom: 5px;
}

.pos-product-item_price {
  font-weight: 700;
  color: var(--c1);
}

.pos-product-item_hover-content {
  display: grid;
  place-items: center;
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 0;
  inline-size: 100%;
  block-size: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  transition: all 0.3s ease;
  opacity: 0;
  visibility: hidden;
}

.pos-product-item:hover .pos-product-item_hover-content {
  opacity: 1;
  visibility: visible;
}

.img-fit {
  max-height: 100%;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

#quick-view {
  background-color: rgba(0, 0, 0, 0.3);
}

/* POS Product Modal */
.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.addon-input {
  height: 7rem;
  width: 7rem;
}

.addon-quantity-input {
  height: 2rem;
  width: 7rem;
  z-index: 9;
  bottom: 1rem;
  visibility: hidden;
}

.check-label {
  background-color: #f3f3f3;
  color: #000000;
  border-width: 2px;
  border-color: #babfc4;
  font-weight: bold;
}

.btn-check:checked + .check-label {
  background-color: var(--c1);
  color: #ffffff;
}

.product-quantity-group {
  border: 1px solid rgb(208, 219, 233);
  max-inline-size: 96px;
  border-radius: 5px;
  display: flex;
}

.product-quantity-group button {
  background-color: #f3f3f3;
  height: 100%;
  padding: 6px;
  border: none;
}

.product-quantity-group input {
  border: none;
  height: auto;
  padding: 2px;
}

.color-select input:checked + label {
  border: 1px solid var(--c1);
}

.select2-container {
  max-width: 100%;
  width: 100% !important;
}

.font-imoji {
  font-family: "emoji";
}

.pos-cart-table {
  max-block-size: 45vh;
  overflow-y: auto;
}

.min-w-120 {
  min-width: 120px;
}

/* Inline Page Menu */
.inline-page-menu ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  -moz-column-gap: 30px;
  column-gap: 30px;
  row-gap: 10px;
}

.inline-page-menu ul li a {
  font-weight: 700;
  color: var(--title-color);
  border-bottom: 2px solid transparent;
  padding-bottom: 10px;
  display: block;
}

.inline-page-menu ul li.active a {
  color: var(--c1);
  border-color: var(--c1);
}

.border-color-c1 {
  border-color: var(--c1) !important;
}

.option-buttons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}

.option-buttons input:checked + label {
  background-color: #334257 !important;
  border-color: #334257 !important;
  color: #fff !important;
}

.message-box {
  background-color: var(--c1);
  color: #fff;
  max-inline-size: 515px;
  padding: 16px;
  border-radius: 5px;
}

.message-box_incoming {
  background-color: rgba(133, 148, 159, 0.1);
  color: var(--title-color);
}

ol li:not(:last-child) {
  margin-bottom: 7px;
}

.right-3 {
  left: auto;
  right: 16px;
}

.top-3 {
  top: 16px;
}

.social-media-login-logo {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}

/* Seller Panel */
.z-9999 {
  z-index: 9999;
}

.mt-10rem {
  margin-top: 10rem;
}

.btn-secondary {
  background-color: #f4f5f7;
  border-color: #f4f5f7;
  color: var(--title-color);
}

.btn-secondary:focus,
.btn-secondary:active,
.btn-secondary:hover {
  background-color: #ededed;
  border-color: #ededed;
  color: var(--title-color);
}

.h-400 {
  height: 400px;
}

.select2-container .select2-selection--multiple {
  min-height: 40px !important;
}

.max-w-400 {
  max-width: 400px;
}

.max-w-200 {
  max-width: 200px;
}

.avatar-lg {
  min-width: 3.36875rem;
}

.badge--primary {
  color: #fff;
  background-color: var(--c1);
}

/* Seller Chat */
.search-input-group {
  display: flex;
  align-items: center;
  background: rgba(153, 167, 186, 0.2);
  border: 1px solid rgba(233, 243, 255, 0.4);
  border-radius: 5px;
}

.search-input-group .search-icon,
.search-input-group i,
.search-input-group span,
.search-input-group svg {
  padding: 10px 0 10px 10px;
}

.search-input-group input {
  background-color: transparent;
  border: none;
  padding: 6px 14px;
  flex-grow: 1;
  height: 40px;
}

.badge-soft--primary {
  color: var(--c1);
  background-color: rgba(0, 144, 205, 0.1);
}

.bg-c1 {
  background-color: var(--c1);
}

.outgoing_msg,
.incoming_msg {
  width: 70%;
  margin-bottom: 16px;
}

@media only screen and (max-width: 767px) {
  .outgoing_msg,
  .incoming_msg {
      width: 95%;
      margin-bottom: 20px;
  }
}
.outgoing_msg {
  margin-left: auto;
}

.chat_list {
  transition: all 150ms ease;
  cursor: pointer;
}

.chat_list:hover,
.bg-chat {
  background-color: rgba(133, 148, 159, 0.1);
}

.card-chat {
  min-height: calc(100vh - 150px);
}

@media only screen and (max-width: 991px) {
  .card-chat {
      height: auto;
  }
}
.h-120 {
  height: 120px !important;
}

.text-start {
  text-align: start;
}

.text-end {
  text-align: end;
}

#dataTable_wrapper > .row:nth-child(1) {
  display: none;
}

select {
  background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png)
      no-repeat right #ddd;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 26px !important;
  background-size: 10px;
  background-position: calc(100% - 8px);
}

.square-btn,
.btn.btn-sm.edit,
.btn.btn-sm.delete {
  width: 30px;
  height: 30px;
  padding: 3px;
  display: grid;
  place-items: center;
}

.min-w-75 {
  min-width: 75px;
}

.navbar-vertical-aside .navbar-brand-wrapper {
  padding-right: 15px;
  padding-left: 15px;
}

.form-control:hover {
  border-color: var(--c1);
}

.btn-xs-status {
  right: -12px;
  width: 8px;
  height: 8px;
}

.list-footer-icon {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.list-footer-icon li a {
  display: flex;
  align-items: center;
  color: var(--title-color);
  gap: 15px;
}

.badge-soft-version {
  padding: 5px 10px !important;
  color: #24bac3;
  background-color: rgba(36, 186, 195, 0.1);
}

#user_overview {
  position: relative;
  z-index: 1;
}

.navbar-vertical:not([class*="container"]) .navbar-nav .nav .nav-link {
  padding-right: 10px;
}

.ratio-4\:1 {
  aspect-ratio: 4/1;
  -o-object-fit: cover;
  object-fit: cover;
}

.max-w-655px {
  max-width: 655px;
}

.max-w-500 {
  max-width: 500px;
}

.c1-light-bg {
  background-color: rgba(110, 137, 175, 0.0509803922);
}

.font-weight-semibold {
  font-weight: 600 !important;
}

.w-90 {
  width: 90% !important;
}

.input-icons i {
  position: absolute;
  cursor: pointer;
}

.input-icons {
  width: 100%;
  margin-bottom: 10px;
}

.icon {
  padding: 9% 0 0 0;
  min-width: 40px;
}

.input-field {
  width: 94%;
  padding: 10px 0 10px 10px;
  text-align: center;
  border-right-style: none;
}

.message-status {
  width: 8px;
  height: 8px;
  display: block;
  line-height: 1;
  border-radius: 50%;
}

.avatar-circle > img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.msg_history {
  max-height: 45vh;
  overflow-y: auto;
}

.bank-card-img {
  position: absolute;
  inset-block-start: 1.5rem;
  inset-inline-end: 1.875rem;
}

.bank-info-card {
  background-color: rgba(0, 106, 255, 0.05);
}

.bg-img {
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-contain {
  background-size: contain !important;
}

.bg-bottom {
  background-position: left bottom !important;
}

.profile-cover-img-wrapper {
  background-position: center;
}

.spartan_remove_row {
  background-color: rgba(255, 255, 255, 0.9) !important;
  border-radius: 100% !important;
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.1);
}

.text-info {
  color: #03b0c0 !important;
}

.billing-section-wrap {
  max-height: calc(100vh - 120px);
  overflow-y: auto;
  overflow-x: hidden;
}

.table-hover tbody tr:hover {
  color: var(--title-color);
}

.pos-search-card {
  border: none !important;
}

.input-group-merge .custom-select:not(:last-child),
.input-group-merge .form-control:not(:last-child) {
  padding-right: 10px;
}

.break-all {
  word-break: break-all !important;
}

.grid-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 30px;
}

[dir="rtl"] .nav-indicator-icon {
  text-align: start;
}

[dir="rtl"] .nav {
  padding-right: 0;
}

[dir="rtl"]
  .navbar-vertical:not([class*="container"])
  .navbar-nav
  .nav
  .nav-link {
  padding-left: 10px;
  padding-right: 39px;
}

[dir="rtl"] .input-group-merge .input-group-prepend {
  left: auto;
  right: 14px;
}

[dir="rtl"] .navbar-vertical .navbar-nav.nav-tabs .active .nav-link,
[dir="rtl"] .navbar-vertical .navbar-nav.nav-tabs .active.nav-link {
  border-right-color: transparent;
}

[dir="rtl"] .search-input-group .search-icon,
[dir="rtl"] .search-input-group i,
[dir="rtl"] .search-input-group span,
[dir="rtl"] .search-input-group svg {
  padding: 10px 10px 10px 0px;
}

[dir="rtl"] .custom-select {
  background: #fff
      url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
      no-repeat left 0.5rem center/1rem 1rem;
}

[dir="rtl"] select {
  background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png)
      no-repeat 14px;
  -moz-text-align-last: right;
  text-align-last: right;
  direction: rtl;
  padding-left: 35px;
}

[dir="rtl"] select option {
  direction: rtl;
}

[dir="rtl"] .business-analytics {
  text-align: right;
}

@media (min-width: 1200px) {
  [dir="rtl"] .navbar-vertical-aside-show-xl .footer {
      margin-left: auto;
      margin-right: 16.25rem;
  }
}
@media (min-width: 992px) {
  [dir="rtl"] .text-lg-left {
      text-align: right !important;
  }
}
[dir="rtl"] .input-group-merge .custom-select:not(:last-child),
[dir="rtl"] .input-group-merge .form-control:not(:last-child) {
  padding-right: 40px;
  padding-left: 10px;
}

[dir="rtl"]
  .navbar-nav-wrap-content-left
  .navbar-nav
  > .nav-item:not(:last-child),
[dir="rtl"]
  .navbar-nav-wrap-content-right
  .navbar-nav
  > .nav-item:not(:last-child) {
  margin-left: 16px;
  margin-right: 0;
}

[dir="rtl"] .select2-container {
  text-align: right;
}

[dir="rtl"] .close {
  float: left;
  margin-right: auto;
  margin-left: 0;
}

[dir="rtl"] .show-status-text .switcher_control::before {
  display: none;
}

[dir="rtl"] .instructions-list {
  text-align: right;
}

/*Inline CSS Remove CSS*/
.__inline-1 {
  height: 32rem;
}

.__inline-2 {
  z-index: 9;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
}

.__inline-3 .image-preview {
  height: 17px !important;
  display: inline-block !important;
  margin-right: 5px !important;
  margin-left: 3px !important;
  margin-top: -5px !important;
}

.__inline-4 {
  width: 75px !important;
  height: 42px !important;
}

.__inline-5 {
  max-height: 40px;
  width: auto;
}

.__inline-6 {
  /* Rounded sliders */
}

.__inline-6 .switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 23px;
}

.__inline-6 .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.__inline-6 .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.__inline-6 .slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

.__inline-6 input:checked + .slider {
  background-color: #377dff;
}

.__inline-6 input:focus + .slider {
  box-shadow: 0 0 1px #377dff;
}

.__inline-6 input:checked + .slider:before {
  transform: translateX(26px);
}

.__inline-6 .slider.round {
  border-radius: 34px;
}

.__inline-6 .slider.round:before {
  border-radius: 50%;
}

.__inline-6 #banner-image-modal .modal-content {
  width: 1116px !important;
  margin-left: -264px !important;
}

@media (max-width: 768px) {
  .__inline-6 #banner-image-modal .modal-content {
      width: 698px !important;
      margin-left: -75px !important;
  }
}
@media (max-width: 375px) {
  .__inline-6 #banner-image-modal .modal-content {
      width: 367px !important;
      margin-left: 0 !important;
  }
}
@media (max-width: 500px) {
  .__inline-6 #banner-image-modal .modal-content {
      width: 400px !important;
      margin-left: 0 !important;
  }
}
@media (max-width: 500px) {
  .__inline-7 #sign_in {
      margin-top: -23% !important;
  }
  .joblistcard .py-30 {
    padding: 10px !important;
  }
  .joblistcard .me-4 {
    margin-right: 0.5rem !important;
}
}
.__inline-8 #partitioned {
  padding-left: 2px;
  letter-spacing: 42px;
  border: 0;
  background-image: linear-gradient(
      to left,
      black 70%,
      rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 50px 1px;
  background-repeat: repeat-x;
  background-position-x: 35px;
  width: 220px;
  min-width: 220px;
}

.__inline-8 #divInner {
  left: 0;
  position: -webkit-sticky;
  position: sticky;
}

.__inline-8 #divOuter {
  width: 190px;
  overflow: hidden;
}

.__inline-9 .widget-list-link {
  color: white !important;
}

.__inline-9 .widget-list-link:hover {
  color: #999898 !important;
}

.__inline-9 .subscribe-border {
  border-radius: 5px;
}

.__inline-9 .subscribe-button {
  background: #1b7fed;
  position: absolute;
  top: 0;
  color: white;
  padding: 11px;
  padding-left: 15px;
  padding-right: 15px;
  text-transform: capitalize;
  border: none;
}

.__inline-9 .start_address {
  display: flex;
  justify-content: space-between;
}

.__inline-9 .address_under_line {
  width: 299px;
}

.__inline-9 .end-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 500px) {
  .__inline-9 .start_address {
      display: block;
  }
  .__inline-9 .footer-web-logo {
      justify-content: center !important;
      padding-bottom: 25px;
  }
  .__inline-9 .footer-padding-bottom {
      padding-bottom: 15px;
  }
  .__inline-9 .mobile-view-center-align {
      justify-content: center !important;
      padding-bottom: 15px;
  }
  .__inline-9 .last-footer-content-align {
      display: flex !important;
      justify-content: center !important;
      padding-bottom: 10px;
  }
}
@media only screen and (max-width: 800px) {
  .__inline-9 .end-footer {
      display: block;
      align-items: center;
  }
}
@media only screen and (max-width: 1200px) {
  .__inline-9 .start_address_under_line {
      display: none;
  }
  .__inline-9 .address_under_line {
      display: none;
  }
}
.__inline-10 .card-body.search-result-box {
  overflow: scroll;
  height: 400px;
  overflow-x: hidden;
}

.__inline-10 .active .seller {
  font-weight: 700;
}

.__inline-10 .for-count-value {
  position: absolute;
  right: 0.6875rem;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  font-size: 0.75rem;
  font-weight: 500;
  text-align: center;
  line-height: 1.25rem;
}

.__inline-10 .count-value {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  font-size: 0.75rem;
  font-weight: 500;
  text-align: center;
  line-height: 1.25rem;
}

@media (min-width: 992px) {
  .__inline-10 .navbar-sticky.navbar-stuck .navbar-stuck-menu.show {
      display: block;
      height: 55px !important;
  }
}
@media (min-width: 768px) {
  .__inline-10 .navbar-stuck-menu {
      line-height: 15px;
      padding-bottom: 6px;
  }
}
@media (max-width: 767px) {
  .__inline-10 .search_button {
      background-color: transparent !important;
  }
  .__inline-10
      .navbar-expand-md
      .dropdown-menu
      > .dropdown
      > .dropdown-toggle {
      position: relative;
  }
  .__inline-10 .mega-nav1 {
      background: white;
      border-radius: 3px;
  }
}
@media (max-width: 768px) {
  .__inline-10 .tab-logo {
      width: 10rem;
  }
}
@media (max-width: 360px) {
  .__inline-10 .mobile-head {
      padding: 3px;
  }
}
@media (max-width: 471px) {
  .__inline-10 .mega-nav1 {
      background: white;
      border-radius: 3px;
  }
}
.__inline-11 {
  height: 40px !important;
  width: auto;
}

.__inline-12 {
  height: 38px !important;
  width: auto;
}

.__inline-13 {
  position: absolute;
  background: white;
  z-index: 999;
  width: 100%;
  display: none;
}

.__inline-14 {
  width: 40px;
  height: 40px;
}

.__inline-15 {
  font-size: 8px !important;
  background: none !important;
  color: #4b5864;
}

.__inline-16 {
  font-size: 10px !important;
  background: none !important;
  color: #4b5864;
  font: bold;
}

.__inline-17 {
  border-bottom: 1px solid #e3e9ef;
  display: flex;
  justify-content: space-between;
}

.__inline-18 {
  font-size: 12px !important;
  color: grey !important;
}

.__inline-19 {
  position: fixed;
  z-index: 9999;
  left: 40%;
  top: 37%;
  width: 100%;
}

.__inline-20 #partitioned {
  padding-left: 2px;
  letter-spacing: 42px;
  border: 0;
  background-image: linear-gradient(
      to left,
      black 70%,
      rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 50px 1px;
  background-repeat: repeat-x;
  background-position-x: 35px;
  width: 220px;
  min-width: 220px;
}

.__inline-20 #divInner {
  left: 0;
  position: -webkit-sticky;
  position: sticky;
}

.__inline-20 #divOuter {
  width: 190px;
  overflow: hidden;
}

.__inline-21 {
  height: 8rem;
  width: 9rem;
}

.__inline-22 input[type="file"] {
  display: none;
}

.__inline-22 .custom-file-upload {
  margin-left: 38%;
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.__inline-23 th,
.__inline-23 td {
  border-bottom: 1px solid #ddd;
  padding: 5px;
}

.__inline-23 thead {
  color: white;
}

.__inline-23 .product-details-shipping-details {
  background: #ffffff;
  border-radius: 5px;
  font-size: 14;
  font-weight: 400;
  color: #212629;
}

.__inline-23 .shipping-details-bottom-border {
  border-bottom: 1px #f9f9f9 solid;
}

.__inline-23 .msg-option {
  display: none;
}

.__inline-23 .chatInputBox {
  width: 100%;
}

.__inline-23 .go-to-chatbox {
  width: 100%;
  text-align: center;
  padding: 5px 0px;
  display: none;
}

.__inline-23 .feature_header {
  display: flex;
  justify-content: center;
}

.__inline-23 .feature_header span {
  font-weight: 700;
  font-size: 25px;
  background-color: #ffffff;
  text-transform: uppercase;
}

.__inline-23 .flash-deals-background-image {
  border-radius: 5px;
  width: 125px;
  height: 125px;
}

@media (max-width: 768px) {
  .__inline-23 .feature_header span {
      margin-bottom: -40px;
  }
  .__inline-23 .font-for-tab {
      font-size: 11px !important;
  }
  .__inline-23 .pro {
      font-size: 13px;
  }
}
@media (max-width: 375px) {
  .__inline-23 .for-dicount-div {
      margin-top: -5%;
  }
}
@media (max-width: 500px) {
  .__inline-23 .for-dicount-div {
      margin-top: -4%;
  }
  .__inline-23 .view-btn-div {
      margin-top: -9%;
  }
  .__inline-23 .viw-btn-a {
      font-size: 10px;
      font-weight: 600;
  }
  .__inline-23 .feature_header span {
      margin-bottom: -7px;
  }
}
.__inline-24 {
  font-size: 22px;
  font-weight: 700;
}

.__inline-25 {
  width: 0px;
  height: 10px;
  border: 0.5px solid #707070;
  margin-top: 6px;
  font-weight: 400 !important;
}

.__inline-26 {
  background: #ffa825 !important;
  color: #ffffff;
}

.__inline-27 {
  color: black !important;
  font-weight: 400;
  font-size: 24px;
}

.__inline-28 {
  font-weight: 500;
  font-size: 50px;
}

.__inline-29 {
  padding: 0px !important;
  width: 40%;
  height: 25px;
}

.__inline-30 {
  display: block;
  width: 100%;
  text-align: center;
  background: #f3f4f5;
  border-radius: 5px;
  padding: 5px;
}

.__inline-31 {
  background: #ffffff;
  padding: 25px;
  border-radius: 5px;
  font-weight: 400;
  color: #212629;
  margin-top: 10px;
}

.__inline-32 {
  height: 65px;
  width: 65px;
  border-radius: 50%;
}

.__inline-33 {
  position: absolute;
  border-radius: 50%;
  margin-top: -25px;
}

.__inline-34 .headerTitle {
  font-size: 26px;
  font-weight: bolder;
  margin-top: 3rem;
}

.__inline-34 .for-count-value {
  position: absolute;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  color: black;
  font-size: 0.75rem;
  font-weight: 500;
  text-align: center;
  line-height: 1.25rem;
}

.__inline-34 .for-count-value {
  position: absolute;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 500;
  text-align: center;
  line-height: 1.25rem;
}

.__inline-34 .page-item.active > .page-link {
  box-shadow: 0 0 black !important;
}

.__inline-34 .for-shoting {
  font-weight: 600;
  font-size: 14px;
  color: #030303;
}

.__inline-34 .sidepanel {
  width: 0;
  position: fixed;
  z-index: 6;
  height: 500px;
  top: 0;
  background-color: #ffffff;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 40px;
}

.__inline-34 .sidepanel a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.__inline-34 .sidepanel a:hover {
  color: #f1f1f1;
}

.__inline-34 .sidepanel .closebtn {
  position: absolute;
  top: 0;
  font-size: 36px;
}

.__inline-34 .openbtn {
  font-size: 18px;
  cursor: pointer;
  background-color: transparent !important;
  color: #373f50;
  width: 40%;
  border: none;
}

.__inline-34 .openbtn:hover {
  background-color: #444;
}

.__inline-34 .for-display {
  display: block !important;
}

@media (max-width: 360px) {
  .__inline-34 .openbtn {
      width: 59%;
  }
}
@media (max-width: 500px) {
  .__inline-34 .openbtn:hover {
      background-color: #fff;
  }
  .__inline-34 .for-display {
      display: flex !important;
  }
  .__inline-34 .for-tab-display {
      display: none !important;
  }
  .__inline-34 .openbtn-tab {
      margin-top: 0 !important;
  }
}
@media screen and (min-width: 500px) {
  .__inline-34 .openbtn {
      display: none !important;
  }
}
@media screen and (min-width: 800px) {
  .__inline-34 .for-tab-display {
      display: none !important;
  }
}
@media (max-width: 768px) {
  .__inline-34 .headerTitle {
      font-size: 23px;
  }
  .__inline-34 .openbtn-tab {
      margin-top: 3rem;
      display: inline-block !important;
  }
  .__inline-34 .for-tab-display {
      display: inline;
  }
}
.__inline-35 {
  margin-bottom: -10px;
  border-radius: 5px;
}

.__inline-36 {
  padding: 14px;
  padding-top: 30px;
}

.__inline-37 {
  font-size: 10px;
  color: #ffffff;
}

.__inline-38 {
  background: #ffffff;
  padding: 22px;
  font-size: 13px;
  border-radius: 5px !important;
}

.__inline-39 {
  cursor: pointer;
  padding: 2px;
  padding-right: 15px;
}

.__inline-40 {
  background: whitesmoke;
  -webkit-appearance: auto;
  -moz-appearance: auto;
  appearance: auto;
  width: 100%;
}

.__inline-41 {
  border: 1px solid whitesmoke;
  margin-bottom: 14px;
  margin-top: -6px;
}

.__inline-42 {
  cursor: pointer;
  padding: 2px;
}

.__inline-43 {
  background: white;
  margin: 0px;
  border-radius: 5px;
}

.__inline-44 {
  background: white;
  -webkit-appearance: auto;
  -moz-appearance: auto;
  appearance: auto;
  border-radius: 5px;
  border: 1px solid rgba(27, 127, 237, 0.5);
  padding: 5px;
}

.__inline-45 {
  /* .modal-header{
        border-top: 20px solid #4884ea;
        border-bottom: none;
} */
}

.__inline-45 .headerTitle {
  font-size: 24px;
  font-weight: 600;
  margin-top: 1rem;
}

.__inline-45 .sidebar {
  max-width: 20rem;
}

.__inline-45 .custom-control-label {
  cursor: pointer;
}

.__inline-45 body {
  font-family: "Titillium Web", sans-serif;
}

.__inline-45 .product-qty span {
  font-size: 14px;
  color: #6a6a6a;
}

.__inline-45 .spanTr {
  color: #ffffff;
  font-weight: 600;
  font-size: 13px;
}

.__inline-45 .font-nameA {
  display: inline-block;
  font-size: 13px;
  color: #030303;
}

.__inline-45 .spandHead {
  color: #ffffff;
  font-weight: 600;
  font-size: 20px;
  margin-left: 25px;
}

.__inline-45 .spandHeadO {
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
}

.__inline-45 .font-name {
  font-weight: 600;
  font-size: 15px;
  padding-bottom: 6px;
  color: #030303;
}

.__inline-45 .btn-p {
  color: #fff;
  background-color: #1b7fed;
  border-color: #1b7fed;
  box-shadow: none;
}

.__inline-45 .btn-p:hover {
  color: #fff;
  background-color: #1b7fed;
  border-color: #1b7fed;
  box-shadow: none;
}

.__inline-45 .tdBorder {
  border-right: 1px solid #f7f0f0;
  text-align: center;
}

.__inline-45 .bodytr {
  border: 1px solid #dadada;
}

.__inline-45 .sellerName {
  font-size: 15px;
  font-weight: 600;
}

.__inline-45 .modal-footer {
  border-top: none;
}

.__inline-45 a {
  color: #030303;
  cursor: pointer;
}

.__inline-45 a:hover {
  color: #4884ea;
  cursor: pointer;
}

.__inline-45 .divider-role {
  border-bottom: 1px solid whitesmoke;
}

.__inline-45 .cz-sidebar-body h3:hover + .divider-role {
  border-bottom: 3px solid #1b7fed !important;
  transition: 0.2s ease-in-out;
}

.__inline-45 label {
  font-size: 15px;
  font-style: bold;
  margin-bottom: 8px;
  color: #030303;
}

.__inline-45 .nav-pills .nav-link.active {
  box-shadow: none;
  color: #ffffff !important;
}

.__inline-45 .modal-header {
  border-bottom: none;
}

.__inline-45 .nav-pills .nav-link {
  padding-top: 0.575rem;
  padding-bottom: 0.575rem;
  background-color: #ffffff;
  color: #050b16 !important;
  font-size: 0.9375rem;
  border: 1px solid #e4dfdf;
}

.__inline-45 .nav-pills .nav-link :hover {
  padding-top: 0.575rem;
  padding-bottom: 0.575rem;
  background-color: #ffffff;
  color: #050b16 !important;
  font-size: 0.9375rem;
  border: 1px solid #e4dfdf;
}

.__inline-45 .nav-pills .nav-link.active,
.__inline-45 .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #216fff;
}

.__inline-45 .iconHad {
  color: #1b7fed;
  padding: 4px;
}

.__inline-45 .modal-body {
  padding: none;
}

.__inline-45 .iconSp {
  margin-top: 0.7rem;
}

.__inline-45 .fa-lg {
  padding: 4px;
}

.__inline-45 .fa-trash {
  color: #ff4d4d;
}

.__inline-45 .namHad {
  color: #030303;
  position: absolute;
  padding-left: 13px;
  padding-top: 8px;
}

.__inline-45 .cardColor {
  border: 1px solid #92c6ff !important;
}

.__inline-45 .card-header {
  border-bottom: 1px solid #92c6ff !important;
}

.__inline-45 .closeB {
  border: 1px solid #ffd5a4 !important;
  padding: 10px 30px 9px 30px;
  border-radius: 7px;
  color: #ffd5a4;
  background: white;
}

.__inline-45 .donate-now {
  list-style-type: none;
  margin: 25px 0 0 0;
  padding: 0;
}

.__inline-45 .donate-now li {
  float: left;
  margin: 0 5px 0 0;
  width: 100px;
  height: 40px;
  position: relative;
}

.__inline-45 .donate-now label,
.__inline-45 .donate-now input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.__inline-45 .donate-now input[type="radio"] {
  opacity: 0.01;
  z-index: 100;
}

.__inline-45 .donate-now input[type="radio"]:checked + label,
.__inline-45 .Checked + label {
  background: #1b7fed;
}

.__inline-45 .donate-now label {
  padding: 5px;
  border: 1px solid #ccc;
  cursor: pointer;
  z-index: 90;
}

.__inline-45 .donate-now label:hover {
  background: #ddd;
}

.__inline-45 .price_sidebar {
  padding: 20px;
}

@media (max-width: 600px) {
  .__inline-45 .sidebar_heading {
      background: #1b7fed;
  }
  .__inline-45 .sidebar_heading h1 {
      text-align: center;
      color: aliceblue;
      padding-bottom: 17px;
      font-size: 19px;
  }
  .__inline-45 .sidebarR {
      padding: 24px;
  }
  .__inline-45 .price_sidebar {
      padding: 20px;
  }
  .__inline-45 .btn-b {
      width: 350px;
      margin-right: 30px;
      margin-bottom: 10px;
  }
  .__inline-45 .div-secon {
      margin-top: 2rem;
  }
}
.__inline-46 {
  height: 3em;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.__inline-47 .page-item.active > .page-link {
  box-shadow: 0 0 black !important;
}

.__inline-47 .widget-categories .accordion-heading > a:hover {
  color: #ffd5a4 !important;
}

.__inline-47 .widget-categories .accordion-heading > a {
  color: #ffd5a4;
}

.__inline-47 body {
  font-family: "Titillium Web", sans-serif;
}

.__inline-47 .card {
  border: none;
}

.__inline-47 .totals tr td {
  font-size: 13px;
}

.__inline-47 .footer span {
  font-size: 12px;
}

.__inline-47 .product-qty span {
  font-size: 14px;
  color: #6a6a6a;
}

.__inline-47 .spanTr {
  color: #ffffff;
  font-weight: 900;
  font-size: 13px;
}

.__inline-47 .spandHeadO {
  color: #ffffff !important;
  font-weight: 400;
  font-size: 13px;
}

.__inline-47 .font-name {
  font-weight: 600;
  font-size: 12px;
  color: #030303;
}

.__inline-47 .amount {
  font-size: 15px;
  color: #030303;
  font-weight: 600;
}

.__inline-47 a {
  cursor: pointer;
  text-decoration: none;
  background-color: transparent;
}

.__inline-47 a:hover {
  cursor: pointer;
}

@media (max-width: 600px) {
  .__inline-47 .sidebar_heading {
      background: #1b7fed;
  }
  .__inline-47 .sidebar_heading h1 {
      text-align: center;
      color: aliceblue;
      padding-bottom: 17px;
      font-size: 19px;
  }
}
@media (max-width: 768px) {
  .__inline-47 .for-tab-img {
      width: 100% !important;
  }
  .__inline-47 .for-glaxy-name {
      display: none;
  }
}
@media (max-width: 360px) {
  .__inline-47 .for-mobile-glaxy {
      display: flex !important;
  }
  .__inline-47 .for-glaxy-name {
      display: none;
  }
}
@media (max-width: 600px) {
  .__inline-47 .for-mobile-glaxy {
      display: flex !important;
  }
  .__inline-47 .for-glaxy-name {
      display: none;
  }
  .__inline-47 .order_table_tr {
      display: grid;
  }
  .__inline-47 .order_table_td {
      border-bottom: 1px solid #fff !important;
  }
  .__inline-47 .order_table_info_div {
      width: 100%;
      display: flex;
  }
  .__inline-47 .order_table_info_div_1 {
      width: 50%;
  }
  .__inline-47 .order_table_info_div_2 {
      width: 49%;
  }
  .__inline-47 .spandHeadO {
      font-size: 16px;
  }
  .__inline-47 .spanTr {
      font-size: 16px;
      margin-top: 10px;
  }
  .__inline-47 .amount {
      font-size: 13px;
  }
}
.__inline-48 {
  border-radius: 50px;
  width: 50px !important;
  height: 50px !important;
}

.__inline-49 .headerTitle {
  font-size: 24px;
  font-weight: 600;
  margin-top: 1rem;
}

.__inline-49 .widget-categories .accordion-heading > a:hover {
  color: #ffd5a4 !important;
}

.__inline-49 .widget-categories .accordion-heading > a {
  color: #ffd5a4;
}

.__inline-49 body {
  font-family: "Titillium Web", sans-serif;
}

.__inline-49 .card {
  border: none;
}

.__inline-49 .totals tr td {
  font-size: 13px;
}

.__inline-49 .product-qty span {
  font-size: 14px;
  color: #6a6a6a;
}

.__inline-49 .spandHeadO {
  color: #ffffff !important;
  font-weight: 600 !important;
  font-size: 14px !important;
}

.__inline-49 .amount {
  font-size: 15px;
  color: #030303;
  font-weight: 600;
}

.__inline-49 .tdBorder {
  text-align: center;
}

.__inline-49 .bodytr {
  text-align: center;
}

.__inline-49 .sellerName {
  font-size: 15px;
  font-weight: 400;
}

.__inline-49 .sidebarL h3:hover + .divider-role {
  transition: 0.2s ease-in-out;
}

.__inline-49 tr td {
  padding: 3px 5px !important;
}

.__inline-49 td button {
  padding: 3px 13px !important;
}

@media (max-width: 600px) {
  .__inline-49 .sidebar_heading h1 {
      text-align: center;
      color: aliceblue;
      padding-bottom: 17px;
      font-size: 19px;
  }
}
.__inline-50 .gallery {
  margin: 10px 50px;
}

.__inline-50 .gallery img {
  width: 100px;
  height: 100px;
  padding: 5px;
  filter: grayscale(100%);
  transition: 1s;
}

.__inline-50 .gallery img:hover {
  filter: grayscale(0);
  transform: scale(1.1);
}

.__inline-51 .headerTitle {
  font-size: 25px;
  font-weight: 700;
  margin-top: 2rem;
}

.__inline-51 .for-container {
  width: 91%;
  border: 1px solid #d8d8d8;
  margin-top: 3%;
  margin-bottom: 3%;
}

.__inline-51 .for-padding {
  padding: 3%;
}

.__inline-52 .active {
  color: gray !important;
}

.__inline-52 .active-category-text {
  color: white !important;
}

.__inline-52 .side-category-bar {
  border: 1px solid rgba(0, 0, 0, 0.1215686275);
  border-radius: 6px;
  cursor: pointer;
  background: white;
}

.__inline-53 {
  @import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
}

.__inline-53 body {
  font-family: "Montserrat", sans-serif;
}

.__inline-53 .card {
  border: none;
}

.__inline-53 .totals tr td {
  font-size: 13px;
}

.__inline-53 .footer span {
  font-size: 12px;
}

.__inline-53 .product-qty span {
  font-size: 14px;
  color: #6a6a6a;
}

.__inline-53 .spanTr {
  font-weight: 700;
}

.__inline-53 .spandHeadO {
  color: #030303;
  font-weight: 500;
  font-size: 20px;
}

.__inline-53 .font-name {
  font-weight: 600;
  font-size: 13px;
}

.__inline-53 .amount {
  font-size: 17px;
}

@media (max-width: 600px) {
  .__inline-53 .p-5 {
      padding: 2% !important;
  }
  .__inline-53 .spanTr {
      font-weight: 400 !important;
      font-size: 12px;
  }
  .__inline-53 .spandHeadO {
      font-weight: 300;
      font-size: 12px;
  }
  .__inline-53 .table th,
  .__inline-53 .table td {
      padding: 5px;
  }
}
.__inline-54 .nav-tabs .nav-link.active,
.__inline-54 .nav-tabs .nav-item.show .nav-link {
  border-radius: 6px;
  color: white !important;
}

.__inline-54 .nav-tabs .nav-link {
  border-radius: 6px;
  color: #f2f3ff !important;
  font-weight: 600 !important;
  font-size: 18px !important;
}

.__inline-55 {
  max-width: 150px;
  margin-top: -10px;
}

.__inline-56 .btn-outline {
  color: #020512;
}

.__inline-56 .btn-outline:hover {
  color: white;
}

.__inline-56 #location_map_canvas {
  height: 100%;
}

@media only screen and (max-width: 768px) {
  .__inline-56 #location_map_canvas {
      height: 200px;
  }
}
.__inline-57 {
  cursor: pointer;
  background: rgba(245, 245, 245, 0.51);
}

.__inline-58 .headerTitle {
  font-size: 25px;
  font-weight: 700;
  margin-top: 2rem;
}

.__inline-58 .for-contac-image {
  padding: 6%;
}

@media (max-width: 600px) {
  .__inline-58 .headerTitle {
      font-weight: 700;
      margin-top: 1rem;
  }
  .__inline-58 .sidebar_heading h1 {
      text-align: center;
      color: aliceblue;
      padding-bottom: 17px;
      font-size: 19px;
  }
}
.__inline-59 .for-banner {
  margin-top: 5px;
}

.__inline-59 .countdown-background {
  padding: 5px 5px;
  border-radius: 5px;
  color: #ffffff !important;
}

.__inline-59 .cz-countdown-days {
  color: white !important;
  background-color: rgba(255, 255, 255, 0.1882352941);
  padding: 8px 16px;
  border-radius: 3px;
  margin-right: 0px !important;
  display: flex;
  flex-direction: column; /* IE 10 */
  flex: 1;
}

.__inline-59 .cz-countdown-hours {
  color: white !important;
  background-color: rgba(255, 255, 255, 0.1882352941);
  padding: 8px 16px;
  border-radius: 3px;
  margin-right: 0px !important;
  display: flex;
  flex-direction: column; /* IE 10 */
  flex: 1;
}

.__inline-59 .cz-countdown-minutes {
  color: white !important;
  background-color: rgba(255, 255, 255, 0.1882352941);
  padding: 8px 16px;
  border-radius: 3px;
  margin-right: 0px !important;
  display: flex;
  flex-direction: column; /* IE 10 */
  flex: 1;
}

.__inline-59 .cz-countdown-seconds {
  color: white !important;
  background-color: rgba(255, 255, 255, 0.1882352941);
  padding: 8px 16px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  flex-direction: column; /* IE 10 */
  flex: 1;
}

.__inline-59 .flash_deal_title {
  font-weight: 700;
  font-size: 30px;
  text-transform: uppercase;
}

.__inline-59 .cz-countdown {
  font-size: 18px;
}

.__inline-59 .flex-center {
  display: flex;
  justify-content: space-between !important;
}

.__inline-59 .flash_deal_product_details .flash-product-price {
  font-weight: 700;
  font-size: 25px;
}

.__inline-59 .for-image {
  width: 100%;
  height: 200px;
}

@media (max-width: 600px) {
  .__inline-59 .flash_deal_title {
      font-weight: 600;
      font-size: 26px;
  }
  .__inline-59 .cz-countdown {
      font-size: 14px;
  }
  .__inline-59 .for-image {
      height: 100px;
  }
}
@media (max-width: 768px) {
  .__inline-59 .for-deal-tab {
      display: contents;
  }
  .__inline-59 .flex-center {
      display: flex;
      justify-content: center !important;
  }
}
.__inline-60 .headerTitle {
  font-size: 25px;
  font-weight: 700;
  margin-top: 2rem;
}

.__inline-60 body {
  font-family: "Titillium Web", sans-serif;
}

.__inline-60 .product-qty span {
  font-size: 14px;
  color: #6a6a6a;
}

.__inline-60 .btn-link {
  color: rgba(76, 80, 86, 0.8901960784);
}

.__inline-60 .btnF {
  display: inline-block;
  font-weight: normal;
  margin-top: 4%;
  color: #4b566b;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  font-size: 0.9375rem;
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out,
      border-color 0.25s ease-in-out, box-shadow 0.2s ease-in-out;
}

@media (max-width: 600px) {
  .__inline-60 .sidebar_heading h1 {
      text-align: center;
      color: aliceblue;
      padding-bottom: 17px;
      font-size: 19px;
  }
  .__inline-60 .headerTitle {
      font-weight: 700;
      margin-top: 1rem;
  }
}
.__inline-61 .media {
  background: white;
}

.__inline-61 .section-header {
  display: flex;
  justify-content: space-between;
}

.__inline-61 .cz-countdown-days {
  color: white !important;
  background-color: rgba(255, 255, 255, 0.1882352941);
  padding: 0px 6px;
  border-radius: 3px;
  margin-right: 0px !important;
  display: flex;
  flex-direction: column; /* IE 10 */
  flex: 1;
}

.__inline-61 .cz-countdown-hours {
  color: white !important;
  background-color: rgba(255, 255, 255, 0.1882352941);
  padding: 0px 6px;
  border-radius: 3px;
  margin-right: 0px !important;
  display: flex;
  flex-direction: column; /* IE 10 */
  flex: 1;
}

.__inline-61 .cz-countdown-minutes {
  color: white !important;
  background-color: rgba(255, 255, 255, 0.1882352941);
  padding: 0px 6px;
  border-radius: 3px;
  margin-right: 0px !important;
  display: flex;
  flex-direction: column; /* IE 10 */
  flex: 1;
}

.__inline-61 .cz-countdown-seconds {
  color: white !important;
  background-color: rgba(255, 255, 255, 0.1882352941);
  padding: 0px 6px;
  border-radius: 3px;
  display: flex;
  flex-direction: column; /* IE 10 */
  flex: 1;
}

.__inline-61 .flash_deal_product_details .flash-product-price {
  font-weight: 700;
  font-size: 18px;
}

.__inline-61 .featured_deal_left {
  height: 130px;
  padding: 10px 13px;
  text-align: center;
}

.__inline-61 .deal_of_the_day {
  border-radius: 3px;
}

.__inline-61 .deal-title {
  font-size: 12px;
}

.__inline-61 .for-flash-deal-img img {
  max-width: none;
}

.__inline-61 .best-selleing-image {
  width: 30%;
  display: flex;
  align-items: center;
  border-radius: 5px;
}

.__inline-61 .best-selling-details {
  padding: 10px;
  width: 50%;
}

.__inline-61 .top-rated-image {
  width: 30%;
  display: flex;
  align-items: center;
  border-radius: 5px;
}

.__inline-61 .top-rated-details {
  padding: 10px;
  width: 70%;
}

@media (max-width: 375px) {
  .__inline-61 .cz-countdown {
      display: flex !important;
  }
  .__inline-61 .cz-countdown .cz-countdown-seconds {
      margin-top: -5px !important;
  }
  .__inline-61 .for-feature-title {
      font-size: 20px !important;
  }
}
@media (max-width: 600px) {
  .__inline-61 .flash_deal_title {
      font-weight: 700;
      font-size: 25px;
      text-transform: uppercase;
  }
  .__inline-61 .cz-countdown .cz-countdown-value {
      font-size: 11px !important;
      font-weight: 700 !important;
  }
  .__inline-61 .featured_deal {
      opacity: 1 !important;
  }
  .__inline-61 .cz-countdown {
      display: inline-block;
      flex-wrap: wrap;
      font-weight: normal;
      margin-top: 4px;
      font-size: smaller;
  }
  .__inline-61 .view-btn-div-f {
      margin-top: 6px;
      float: right;
  }
  .__inline-61 .view-btn-div {
      float: right;
  }
  .__inline-61 .viw-btn-a {
      font-size: 10px;
      font-weight: 600;
  }
  .__inline-61 .for-mobile {
      display: none;
  }
  .__inline-61 .featured_for_mobile {
      max-width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
  }
  .__inline-61 .best-selleing-image {
      width: 50%;
      border-radius: 5px;
  }
  .__inline-61 .best-selling-details {
      width: 50%;
  }
  .__inline-61 .top-rated-image {
      width: 50%;
  }
  .__inline-61 .top-rated-details {
      width: 50%;
  }
}
@media (max-width: 360px) {
  .__inline-61 .featured_for_mobile {
      max-width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
  }
  .__inline-61 .featured_deal {
      opacity: 1 !important;
  }
}
@media (max-width: 375px) {
  .__inline-61 .featured_for_mobile {
      max-width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
  }
  .__inline-61 .featured_deal {
      opacity: 1 !important;
  }
}
@media (min-width: 768px) {
  .__inline-61 .displayTab {
      display: block !important;
  }
}
@media (max-width: 800px) {
  .__inline-61 .latest-product-margin {
      margin-left: 0px !important;
  }
  .__inline-61 .for-tab-view-img {
      width: 40%;
  }
  .__inline-61 .for-tab-view-img {
      width: 105px;
  }
  .__inline-61 .widget-title {
      font-size: 19px !important;
  }
  .__inline-61 .flash-deal-view-all-web {
      display: none !important;
  }
  .__inline-61 .seller-card {
      padding-left: 0px !important;
  }
  .__inline-61 .recomanded-product-card {
      background: #f8fbfd;
      margin: 20px;
      height: 535px;
      border-radius: 5px;
  }
}
@media (min-width: 801px) {
  .__inline-61 .flash-deal-view-all-mobile {
      display: none !important;
  }
  .__inline-61 .recomanded-product-card {
      background: #f8fbfd;
      margin: 20px;
      height: 475px;
      border-radius: 5px;
  }
}
.__inline-61 .featured_deal_carosel .carousel-inner {
  width: 100% !important;
}

.__inline-61 .badge-style2 {
  color: black !important;
  background: transparent !important;
  font-size: 11px;
}

.__inline-61 .countdown-card {
  height: 150px !important;
  border-radius: 5px;
}

.__inline-61 .flash-deal-text {
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  border-radius: 5px;
  margin-top: 25px;
}

.__inline-61 .countdown-background {
  padding: 5px 5px;
  border-radius: 5px;
  margin-top: 15px;
}

.__inline-61 .carousel-wrap {
  position: relative;
}

.__inline-61 .owl-nav {
  top: 40%;
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.__inline-61 .owl-prev {
  float: left;
}

.__inline-61 .owl-next {
  float: right;
}

.__inline-61 .flash-deals-background-image {
  border-radius: 5px;
  width: 125px;
  height: 125px;
}

.__inline-61 .feature-product-title {
  text-align: center;
  font-size: 22px;
  margin-top: 15px;
  font-style: normal;
  font-weight: 700;
}

.__inline-61 .feature-product .czi-arrow-left {
  font-size: 12px;
}

.__inline-61 .feature-product .owl-nav {
  top: 40%;
  position: absolute;
  display: flex;
  justify-content: space-between;
  /* width: 100%; */
  z-index: -999;
}

.__inline-61 .feature-product .czi-arrow-right {
  font-size: 12px;
}

.__inline-61 .shipping-policy-web {
  background: #ffffff;
  width: 100%;
  border-radius: 5px;
}

.__inline-61 .shipping-method-system {
  height: 130px;
  width: 70%;
  margin-top: 15px;
}

.__inline-61 .flex-between {
  display: flex;
  justify-content: space-between;
}

.__inline-62 {
  background: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.0509803922);
  border-radius: 5px;
}

.__inline-63 {
  width: 100%;
  border-radius: 5px;
  height: auto !important;
}

.__inline-64 {
  @import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
}

.__inline-64 body {
  font-family: "Montserrat", sans-serif;
}

.__inline-64 .card {
  border: none;
}

.__inline-64 .totals tr td {
  font-size: 13px;
}

.__inline-64 .footer span {
  font-size: 12px;
}

.__inline-64 .product-qty span {
  font-size: 14px;
  color: #6a6a6a;
}

.__inline-64 .spanTr {
  font-weight: 700;
}

.__inline-64 .spandHeadO {
  color: #030303;
  font-weight: 500;
  font-size: 20px;
}

.__inline-64 .font-name {
  font-weight: 600;
  font-size: 13px;
}

.__inline-64 .amount {
  font-size: 17px;
}

.__inline-65 .page-item.active > .page-link {
  box-shadow: 0 0 black !important;
}

.__inline-65 .btnF {
  cursor: pointer;
}

.__inline-65 .list-link:hover {
  color: #030303 !important;
}

.__inline-65 .seller_div {
  background: #fcfcfc no-repeat padding-box;
  border: 1px solid #e2f0ff;
  border-radius: 5px;
  opacity: 1;
  padding: 5px;
}

.__inline-66 {
  vertical-align: middle;
  height: 6rem;
  border-radius: 3%;
}

.__inline-67 {
  /***********************************/
}

.__inline-67 .headerTitle {
  font-size: 34px;
  font-weight: bolder;
  margin-top: 3rem;
}

.__inline-67 .page-item.active > .page-link {
  box-shadow: 0 0 black !important;
}

.__inline-67 .sidepanel {
  width: 0;
  position: fixed;
  z-index: 6;
  height: 500px;
  top: 0;
  left: 0;
  background-color: #ffffff;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 40px;
}

.__inline-67 .sidepanel a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.__inline-67 .sidepanel a:hover {
  color: #f1f1f1;
}

.__inline-67 .sidepanel .closebtn {
  position: absolute;
  top: 0;
  right: 0px;
  font-size: 36px;
}

.__inline-67 .openbtn {
  font-size: 18px;
  cursor: pointer;
  background-color: #ffffff;
  color: #373f50;
  width: 40%;
  border: none;
}

.__inline-67 .openbtn:hover {
  background-color: #444;
}

.__inline-67 .for-display {
  display: block !important;
}

@media (max-width: 360px) {
  .__inline-67 .openbtn {
      width: 59%;
  }
  .__inline-67 .for-shoting-mobile {
      margin-right: 0% !important;
  }
  .__inline-67 .for-mobile {
      margin-left: 10% !important;
  }
}
@media screen and (min-width: 375px) {
  .__inline-67 .for-shoting-mobile {
      margin-right: 7% !important;
  }
  .__inline-67 .custom-select {
      width: 86px;
  }
}
@media (max-width: 500px) {
  .__inline-67 .for-mobile {
      margin-left: 27%;
  }
  .__inline-67 .openbtn:hover {
      background-color: #fff;
  }
  .__inline-67 .for-display {
      display: flex !important;
  }
  .__inline-67 .for-shoting-mobile {
      margin-right: 11%;
  }
  .__inline-67 .for-tab-display {
      display: none !important;
  }
  .__inline-67 .openbtn-tab {
      margin-top: 0 !important;
  }
  .__inline-67 .seller-details {
      justify-content: center !important;
      padding-bottom: 8px;
  }
}
@media screen and (min-width: 500px) {
  .__inline-67 .openbtn {
      display: none !important;
  }
}
@media screen and (min-width: 800px) {
  .__inline-67 .for-tab-display {
      display: none !important;
  }
}
@media (max-width: 768px) {
  .__inline-67 .headerTitle {
      font-size: 23px;
  }
  .__inline-67 .openbtn-tab {
      margin-top: 3rem;
      display: inline-block !important;
  }
  .__inline-67 .for-tab-display {
      display: inline;
  }
}
.__inline-68 {
  width: 120px;
  border-radius: 5px;
}

.__inline-69 {
  border-left: 1px solid #c4c4c4;
  margin: 5px;
}

.__inline-70 {
  border-radius: 25px;
  border: 1px solid #1b7fed;
  color: #1b7fed;
}

.__inline-71 {
  border-bottom: 2px solid #eef6ff;
  background: none !important;
}

.__inline-72 {
  width: 20px;
  border-radius: 5px;
  height: 20px;
}

.__inline-73 {
  border: 1px solid whitesmoke;
  margin-bottom: 14px;
  margin-top: 5px;
}

.__dir-ltr {
  direction: ltr;
}

.__bg-F3F5F9 {
  background: #f3f5f9;
}

.__min-h-200px {
  min-height: 200px;
}

.__bg-e2f0ff {
  background: #e2f0ff;
}

.__bg-4bcc02 {
  background: #4bcc02;
}

.__closet {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1;
  color: #4b566b;
  text-shadow: none;
  opacity: 0.5;
}

.w-10rem {
  width: 10rem;
}

.__pt-20 {
  padding-top: 20px;
}

.__m-20-r {
  margin: 20px 20px -20px 20px;
}

.__mb-10px {
  margin-bottom: 10px;
}

.__color-crimson {
  color: crimson;
}

.__mt-n-10 {
  margin-top: -10px;
}

.__mt-n-11 {
  margin-top: -11px;
}

.__h-100px {
  height: 100px;
}

.__feature_header {
  background: #dcdcdc;
  line-height: 1px;
}

.__color-92C6FF {
  color: #92c6ff;
}

.__color-0f9d58 {
  color: #0f9d58;
}

.__text-100px {
  font-size: 100px;
}

.__text-10px {
  font-size: 10px;
}

.__text-17px {
  font-size: 17px;
}

.__vertical-middle {
  vertical-align: middle;
}

.__gap-6px {
  gap: 6px;
}

.__text-414141 {
  color: #414141 !important;
  padding-top: 5px;
}

.__w-45px {
  width: 45px;
}

.__h-45px {
  height: 45px;
}

.__pt-12 {
  padding-top: 12px;
}

.__color-6A6A6A {
  color: #6a6a6a;
}

.__color-92C6FF {
  color: #92c6ff;
}

.__mb-11 {
  margin-bottom: 11px;
}

.bg-aliceblue {
  background: aliceblue;
}

.__color-3498db {
  color: #3498db;
}

.__color-1B7FED {
  color: #1b7fed;
}

.__max-h-12rem {
  max-height: 12rem;
}

.__px-5p {
  padding-left: 5%;
  padding-right: 5%;
}

.__mb-n-10 {
  margin-bottom: -10px;
}

.__p-12 {
  padding: 12px;
}

.__min-h-70px {
  min-height: 70px;
}

.__text-30px {
  font-size: 30px;
}

.__text-26px {
  font-size: 26px;
}

.__h-79px {
  height: 79px;
}

.__h-5px {
  height: 5px;
}

.overflow-scroll {
  overflow: scroll;
}

.__color-FE961C {
  color: #fe961c;
}

.__max-h-323px {
  max-height: 323px;
}

.__max-h-515px {
  max-height: 515px;
}

.__pl-3 {
  padding-left: 3px;
}

.__dir-rtl {
  direction: rtl;
}

.__p-2p {
  padding: 2%;
}

.__w-8rem {
  width: 8rem;
}

.__h-32rem {
  height: 32rem;
}

.__close {
  z-index: 99;
  background: white !important;
  padding: 3px 8px !important;
  border-radius: 50%;
}

.__w-5p {
  width: 5%;
}

.__w-30p {
  width: 30%;
}

.__w-45p {
  width: 45%;
}

.__w-35p {
  width: 35%;
}

.__w-10p {
  width: 10%;
}

.__w-15p {
  width: 15%;
}

.__w-70p {
  width: 70%;
}

.__h-60p {
  height: 60%;
}

.__h-70p {
  height: 70%;
}

.__text-12px {
  font-size: 12px;
}

.__p-3px {
  padding: 3px !important;
}

.__p-1px {
  padding: 1px;
}

.__min-w-165px {
  min-width: 165px !important;
}

.__r-100 {
  right: 100%;
}

.__text-8px {
  font-size: 8px;
}

.__ml-50px {
  margin-left: 50px;
}

.px-10px {
  padding-left: 10px;
  padding-right: 10px;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.__min-w-7rem {
  min-width: 7rem;
}

.__mt-20 {
  margin-top: 20px;
}

.__w-100px {
  width: 100px;
}

.__w-18rem {
  width: 18rem;
}

.__h-18rem {
  height: 18rem;
}

.cursor-pointer {
  cursor: pointer;
}

.__empty-img {
  width: 100%;
  border: 1px solid;
  border-radius: 10px;
  max-height: 200px;
}

.__h-200px {
  height: 200px;
}

.__h-400px {
  height: 400px;
}

.__p-10 {
  padding: 10px;
}

.__h-20rem {
  height: 20rem;
}

.__w-20rem {
  height: 20rem;
}

.__h-15rem {
  height: 15rem;
}

.__w-15rem {
  width: 15rem;
}

.__w-7rem {
  width: 7rem;
}

.__w-5px {
  width: 5px;
}

.cursor-pointer {
  cursor: pointer;
}

.__color-8a8a8a {
  color: #8a8a8a;
}

.__w-30px {
  width: 30px;
}

.__w-50px {
  width: 50px;
}

.__center {
  margin: auto;
  width: 50%;
  padding: 10px;
}

.text-black {
  color: #000;
}

.__w-16rem {
  width: 16rem;
}

.__pb-13px {
  padding-bottom: 13px;
}

.__pb-10px {
  padding-bottom: 10px;
}

.__text-14px {
  font-size: 14px;
}

.__text-16px {
  font-size: 16px;
}

.__text-18px {
  font-size: 18px;
}

.__text-20px {
  font-size: 20px;
}

.__text-22px {
  font-size: 22px;
}

.__w-160px {
  width: 160px;
}

.__mt-35 {
  margin-top: 35px;
}

.__color-coral {
  color: coral;
}

.__color-1573ff {
  color: #1573ff;
}

.__color-01ff2cfa {
  color: rgba(1, 255, 44, 0.9803921569);
}

.__color-ff2a05fa {
  color: rgba(255, 42, 5, 0.9803921569);
}

.__ml-20 {
  margin-left: 20px;
}

.__img-40 {
  height: 40px;
  width: 40px;
  -o-object-fit: cover;
  object-fit: cover;
}

.__mr-2px {
  margin-right: 2px;
}

.__form-control {
  height: 45px;
}

.min-w-120 {
  min-width: 120px;
}

.inline-page-menu ul li:not(.active) a {
  font-weight: 400;
}

.__table tbody td {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.__table thead th {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.__table .badge {
  padding: 9px 17px 8px;
  border-radius: 14px;
}

.__table-refund tbody td {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.__table-refund thead th {
  padding-top: 1.15rem;
  padding-bottom: 1.15rem;
}

.__table-refund .badge {
  padding: 9px 17px 8px;
  border-radius: 14px;
}

.rating {
  color: #4153b3;
}

.coupon__discount {
  gap: 5px;
  color: #9eadc1;
}

.coupon__discount strong {
  font-weight: 700;
  font-size: 16px;
}

@media (width < 1450px) {
  .coupon__discount strong {
      font-size: 14px;
  }
}
@media (1200px < width < 1450px) {
  .coupon__discount {
      font-size: 12px;
  }
}
.store-report-content {
  display: flex;
  flex-wrap: wrap;
  gap: 11px;
}

.store-report-content .left-content,
.store-report-content .right-content {
  width: 265px;
  flex-grow: 1;
}

@media (min-width: 1500px) {
  .store-report-content .left-content.expense--content {
      max-width: 450px;
  }
}
.store-report-content .center-chart-area {
  flex-grow: 1;
  width: 520px;
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 10px 13px rgba(17, 38, 146, 0.05);
  border-radius: 8px;
}

/* @media (min-width: 1360px) {
.store-report-content .center-chart-area.size-lg {
  width: 900px;
}
} */
.store-report-content .center-chart-area .center-chart-header {
  display: flex;
  gap: 15px;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 24px;
}

.store-report-content .center-chart-area .center-chart-header .title {
  font-weight: 500;
  font-size: 19px;
  line-height: 175%;
  color: #232d42;
  margin: 0;
}

.store-report-content .center-chart-area .center-chart-header .subtitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 175%;
  color: #232d42;
  margin: 0;
}

@media (max-width: 575px) {
  .store-report-content .center-chart-area .center-chart-header {
      gap: 5px;
  }
}
@media (max-width: 1499px) {
  .store-report-content .center-chart-area {
      width: 450px;
  }
}
@media (max-width: 1335px) {
  .store-report-content .center-chart-area {
      width: 380px;
  }
}
@media (max-width: 1255px) {
  .store-report-content .center-chart-area {
      width: 300px;
  }
}
@media (max-width: 991px) {
  .store-report-content {
      flex-wrap: wrap;
  }
  .store-report-content .left-content,
  .store-report-content .center-chart-area {
      width: 100%;
  }
}
.store-report-content .left-content {
  gap: 11px;
  display: flex;
  flex-wrap: wrap;
}

.store-report-content .left-content-card {
  background: #ffffff;
  box-shadow: 0px 10px 13px rgba(17, 38, 146, 0.1);
  border-radius: 5px;
  padding: 20px 30px;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}

.store-report-content .left-content-card img {
  width: 35px;
}

.store-report-content .left-content-card .info {
  width: 0;
  flex-grow: 1;
  padding-left: 21px;
}

.store-report-content .left-content-card .info .subtitle {
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  font-family: "Roboto", sans-serif;
}

.store-report-content .left-content-card .info .subtitle sub {
  font-size: 80%;
  bottom: 0;
}

.store-report-content .left-content-card .info .subtext {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
}

.store-report-content .left-content-card .info .info-txt {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #00aa6d;
  text-align: right;
  gap: 5px;
}

@media (min-width: 992px) and (max-width: 1280px) {
  .store-report-content .left-content-card {
      padding: 20px;
  }
  .store-report-content .left-content-card .info .subtitle {
      font-size: 24px;
      line-height: 28px;
  }
}
@media (max-width: 991px) {
  .store-report-content .left-content {
      flex-direction: row;
  }
  .store-report-content .left-content-card {
      width: 100%;
  }
}
@media (max-width: 767px) {
  .store-report-content .left-content-card {
      width: 100%;
  }
}
@media (max-width: 374px) {
  .store-report-content .left-content-card {
      padding: 20px;
  }
  .store-report-content .left-content-card .info .subtitle {
      font-size: 24px;
      line-height: 28px;
  }
}
.store-report-content .right-content .card-header {
  box-shadow: none;
}

.store-report-content .right-content .card-header .card-title {
  font-size: 18px;
}

.pie-chart .apexcharts-canvas {
  margin: 0 auto;
}

.pie-chart .apexcharts-legend .apexcharts-legend-series {
  margin-top: 10px !important;
}

.apex-legends {
  color: #758590;
  display: flex;
  flex-wrap: wrap;
  max-width: 310px;
  margin: 0 auto;
  padding: 0 10px;
}

.apex-legends div {
  margin-top: 10px;
  display: flex;
  align-items: flex-start;
}

.apex-legends div::before {
  width: 9px;
  border-radius: 50%;
  height: 9px;
  content: "";
  margin-right: 6px;
  display: block;
  transform: translateY(5px);
}

.apex-legends div.before-bg-004188::before {
  background: #004188;
}

.apex-legends div.before-bg-A2CEEE::before {
  background: #a2ceee;
}

.apex-legends div.before-bg-0177CD::before {
  background: #0177cd;
}
.apex-legends div.before-bg-CDE6F5::before {
  background: #7b94a4;
}

.apex-legends div:not(:last-child) {
  margin-right: 15px;
}

.total--orders {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 12px;
}
@media screen and (width < 1650px) {
  .total--orders {
      font-size: 10px;
  }
}

.total--orders h3 {
  margin: 0;
  font-weight: 700 !important;
  font-size: 19px;
  line-height: 23px;
  color: #334257 !important;
}

@media screen and (max-width: 575px) {
  .total--orders h3 {
      font-size: 16px;
      line-height: 16px;
      margin-bottom: 5px;
  }
}
@media screen and (min-width: 1650px) {
  .total--orders h3 {
      font-size: 24px;
      line-height: 24px;
  }
}
.text-004188 {
  color: #004188;
}

.text-A2CEEE {
  color: #a2ceee !important;
}

.text-0177CD {
  color: #0177cd !important;
}

.text-F5A200 {
  color: #f5a200 !important;
}

.text-56b98f {
  color: #56b98f !important;
}

.payment-statistics-shadow {
  background: #ffffff;
  box-shadow: 0px 10px 13px rgba(17, 38, 146, 0.1);
  border-radius: 5px;
  border: none;
}

.store-center-chart {
  height: 340px !important;
}

.store-center-chart.style-2 {
  height: 300px !important;
}

@media (max-width: 1650px) {
  .store-center-chart {
      height: 300px !important;
  }
}
@media (max-width: 575px) {
  .store-center-chart {
      height: 250px !important;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1255px) {
  .store-center-chart {
      height: 250px !important;
  }
}
.earning-statistics-content {
  text-align: center;
}

.earning-statistics-content .subtitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 175%;
  color: #232d42;
  margin-bottom: 18px;
}

.earning-statistics-content .title {
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  color: #334257;
  display: inline-block;
  margin-bottom: 15px;
}

.earning-statistics-content .subtxt {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #8a92a6;
  margin-bottom: 30px;
}

@media (min-width: 1440px) {
  .earning-statistics-content .subtitle {
      font-size: calc(16px + 0.1vw);
  }
  .earning-statistics-content .title {
      font-size: calc(28px + 0.2vw);
  }
  .earning-statistics-content .subtxt {
      font-size: calc(12px + 0.1vw);
  }
}
.p-name {
  max-width: 150px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: initial;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mw-145 {
  max-width: 145px;
}

.wishlisted-p-name {
  min-width: 240px;
}

.__badge {
  padding: 6px 10px 5px;
  border-radius: 15px;
}

.__badge[class*="soft-success"] {
  color: #16b559;
  background: rgba(22, 181, 89, 0.1);
}

.__badge[class*="soft-primary"] {
  color: #0286ff;
  background: rgba(2, 134, 255, 0.1);
}

.__badge[class*="soft-danger"] {
  color: #ff6767;
  background: rgba(255, 103, 103, 0.1);
}

.white-space-initial {
  white-space: initial !important;
}

@media (width < 575px) {
  .filter-btn {
      text-align: right;
  }
}

.tooltip.fade.in {
  opacity: 1;
}

.trx-y-2 {
  transform: translateY(-2px);
}
.trx-y-2 img {
  width: 15px !important;
}

.info-img {
  width: 15px !important;
}

.label-hide .apexcharts-tooltip-text-y-value {
  display: none !important;
}

.__coba-aspect img {
  aspect-ratio: 1;
  -o-object-fit: cover;
  object-fit: cover;
}
.__coba-aspect .spartan_item_wrapper {
  margin-bottom: 0 !important;
  height: unset !important;
}
.__coba-aspect .file_upload {
  height: unset !important;
}

.p-5px {
  padding: 5px;
}

.aspect-1 {
  aspect-ratio: 1;
  -o-object-fit: cover;
  object-fit: cover;
}

.word-break {
  white-space: initial;
}

.max-w-360px {
  max-width: 360px;
}

.sidebar--search-form {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 9;
  background: #073b74;
}

aside .navbar-vertical-content {
  padding-top: 0;
} /*# sourceMappingURL=style.css.map */
.upload--icon{
  width: 36px;aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: absolute;
  inset-inline-end: 10px;
  inset-block-start: 10px;
  border-radius: 50%;
}





@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

@keyframes slideIn {
  from {
      transform: translateY(20px);
      opacity: 0;
  }
  to {
      transform: translateY(0);
      opacity: 1;
  }
}

body.job-application {
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
  color: #333;
}

.job-application .container {
  width: 60%;
  margin: 50px auto;
  background: #fff;
  padding: 30px 50px;
  border-radius: 10px;
  box-shadow: 0 5px 25px rgba(41, 128, 185, 0.15); /* Box shadow for container */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for transform and box-shadow */
  animation: fadeIn 1s ease-in-out;
}

.job-application .container:hover {
  transform: scale(1.01);
  box-shadow: 0 8px 30px rgba(41, 128, 185, 0.25); /* Adjusted box shadow on hover */
}

.job-application h1 {
  text-align: center;
  color: #444;
  font-size: 2.8em;
  margin-bottom: 20px;
  font-family: 'Georgia', serif;
}

.job-application section {
  margin-bottom: 30px;
  animation: slideIn 0.5s ease-in-out;
}

.job-application h2 {
  color: #444;
  font-family: 'Georgia', serif;
  border-bottom: 3px solid #4b79a1;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.job-application label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #555;
}

.job-application input[type="text"], 
.job-application input[type="email"], 
.job-application input[type="tel"], 
.job-application textarea {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for border-color and box-shadow */
}

.job-application input[type="text"]:focus,
.job-application input[type="email"]:focus,
.job-application input[type="tel"]:focus,
.job-application textarea:focus {
  border-color: #4b79a1;
  box-shadow: 0 0 8px rgba(41, 128, 185, 0.5); /* Box shadow when focused */
}

.job-application button {
  display: block;
  width: 100%;
  padding: 15px;
  background-color: #4b79a1;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.job-application button:hover {
  background-color: #283e51;
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .job-application .container {
      width: 90%;
      padding: 20px;
  }

  .job-application h1 {
      font-size: 2.2em;
  }

  .job-application h2 {
      font-size: 1.8em;
  }
}


.job-application input[type="text"], .job-application input[type="email"], .job-application input[type="tel"],.job-application input[type="date"], .job-application textarea {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}
.job-application .jobSelect {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  background: #ffffff;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}


.jobb html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}
.jobb body {
  margin: 0;
}
.jobb main {
  display: block;
}
.jobb h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
.jobb a {
  background-color: transparent;
}
.jobb code {
  font-family: monospace, monospace;
  font-size: 1em;
}
.jobb input {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  overflow: visible;
}
.jobb ::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
.jobb h1,
.jobb h2,
.jobb h3,
.jobb p,
.jobb ul {
  margin: 0;
}
.jobb ul {
  list-style: none;
  padding: 0;
}
.jobb html {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}
.jobb *,
.jobb :after,
.jobb :before {
  box-sizing: border-box;
  border: 0 solid #e2e8f0;
}
.jobb input::-moz-placeholder {
  color: #a0aec0;
}
.jobb input:-ms-input-placeholder {
  color: #a0aec0;
}
.jobb input::placeholder {
  color: #a0aec0;
}
.jobb h1,
.jobb h2,
.jobb h3 {
  font-size: inherit;
  font-weight: inherit;
}
.jobb a {
  text-decoration: inherit;
}
.jobb a,
.jobb input {
  color: inherit;
}
.jobb input {
  padding: 0;
  line-height: inherit;
}
.jobb code {
  font-family: Menlo, Monaco, Consolas, Liberation Mono, Courier New,
    monospace;
}
.jobb body {
  -webkit-font-smoothing: subpixel-antialiased;
}
.jobb .bg-gray-100 {
  --bg-opacity: 1;
  background-color: #f7fafc;
  background-color: rgba(247, 250, 252, var(--bg-opacity));
}
.jobb .bg-gray-250 {
  --bg-opacity: 1;
  background-color: #24262d;
  background-color: rgba(36, 38, 45, var(--bg-opacity));
}
.jobb .bg-gray-750 {
  --bg-opacity: 1;
  background-color: #343a40;
  background-color: rgba(52, 58, 64, var(--bg-opacity));
}
.jobb .border-gray-700 {
  --border-opacity: 1;
  border-color: #4a5568;
  border-color: rgba(74, 85, 104, var(--border-opacity));
}
.jobb .border-gray-750 {
  --border-opacity: 1;
  border-color: #343a40;
  border-color: rgba(52, 58, 64, var(--border-opacity));
}
.jobb .rounded-2xl {
  border-radius: 1rem;
}
.jobb .border-4 {
  border-width: 4px;
}
.jobb .border-b-2 {
  border-bottom-width: 2px;
}
.jobb .border-r-4 {
  border-right-width: 4px;
}
.jobb .border-b-4 {
  border-bottom-width: 4px;
}
.jobb .border-b-8 {
  border-bottom-width: 8px;
}
.jobb .block {
  display: block;
}
.jobb .inline-block {
  display: inline-block;
}
.jobb .flex {
  display: flex;
}
.jobb .inline-flex {
  display: inline-flex;
}
.jobb .flex-wrap {
  flex-wrap: wrap;
}
.jobb .items-baseline {
  align-items: baseline;
}
.jobb .justify-between {
  justify-content: space-between;
}
.jobb .justify-around {
  justify-content: space-around;
}
.jobb .flex-grow {
  flex-grow: 1;
}
.jobb .font-jost {
  font-family: Jost, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}
.jobb .font-normal {
  font-weight: 400;
}
.jobb .font-semibold {
  font-weight: 600;
}
.jobb .font-bold {
  font-weight: 700;
}
.jobb .text-lg {
  font-size: 1.125rem;
}
.jobb .text-xl {
  font-size: 1.25rem;
}
.jobb .text-2xl {
  font-size: 1.5rem;
}
.jobb .text-3xl {
  font-size: 1.85rem;
}
.jobb .text-5xl {
  font-size: 2.65rem;
}
.jobb .text-sm2 {
  font-size: 0.69rem;
}
.jobb .text-md {
  font-size: 1.7rem;
}
.jobb .leading-none {
  line-height: 1;
}
.jobb .leading-normal {
  line-height: 1.34;
}
.jobb .leading-relaxed {
  line-height: 1.625;
}
.jobb .leading-snugish {
  line-height: 1.25;
}
.jobb .list-inside {
  list-style-position: inside;
}
.jobb .m-0 {
  margin: 0;
}
.jobb .m-2 {
  margin: 0.5rem;
}
.jobb .m-4 {
  margin: 1rem;
}
.jobb .my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.jobb .my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.jobb .mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.jobb .mt-0 {
  margin-top: 0;
}
.jobb .mb-0 {
  margin-bottom: 0;
}
.jobb .mt-1 {
  margin-top: 0.25rem;
}
.jobb .mb-1 {
  margin-bottom: 0.25rem;
}
.jobb .mt-2 {
  margin-top: 0.5rem;
}
.jobb .mr-2 {
  margin-right: 0.5rem;
}
.jobb .mb-2 {
  margin-bottom: 0.5rem;
}
.jobb .ml-2 {
  margin-left: 0.5rem;
}
.jobb .mr-3 {
  margin-right: 0.75rem;
}
.jobb .mb-3 {
  margin-bottom: 0.75rem;
}
.jobb .mt-4 {
  margin-top: 1rem;
}
.jobb .mb-4 {
  margin-bottom: 1rem;
}
.jobb .mr-5 {
  margin-right: 1.25rem;
}
.jobb .mb-5 {
  margin-bottom: 1.25rem;
}
.jobb .mr-8 {
  margin-right: 2rem;
}
.jobb .mr-1\.6 {
  margin-right: 0.4375rem;
}
.jobb .mt-2\.1 {
  margin-top: 0.5625rem;
}
.jobb .-mb-1 {
  margin-bottom: -0.25rem;
}
.jobb .-mr-1\.6 {
  margin-right: -0.4375rem;
}
.jobb .first\:mt-0:first-child {
  margin-top: 0;
}
/* .jobb .max-w-3xl {
  max-width: 48rem;
} */
.jobb .p-1 {
  padding: 0.25rem;
}
.jobb .p-3 {
  padding: 0.75rem;
}
.jobb .px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.jobb .px-2\.5 {
  padding-left: 10px;
  padding-right: 10px;
}
.jobb .pb-2 {
  padding-bottom: 0.5rem;
}
.jobb .pr-3 {
  padding-right: 0.75rem;
}
.jobb .pb-4 {
  padding-bottom: 1rem;
}
.jobb .last\:pb-1:last-child {
  padding-bottom: 0.25rem;
}
.jobb .text-left {
  text-align: left;
}
.jobb .text-center {
  text-align: center;
}
.jobb .text-right {
  text-align: right;
}
.jobb .text-black {
  --text-opacity: 1;
  color: #24262d;
  color: rgba(36, 38, 45, var(--text-opacity));
}
.jobb .text-gray-100 {
  --text-opacity: 1;
  color: #f7fafc;
  color: rgba(247, 250, 252, var(--text-opacity));
}
.jobb .text-gray-200 {
  --text-opacity: 1;
  color: #edf2f7;
  color: rgba(237, 242, 247, var(--text-opacity));
}
.jobb .text-gray-400 {
  --text-opacity: 1;
  color: #a0aec0;
  color: rgba(160, 174, 192, var(--text-opacity));
}
.jobb .text-gray-500 {
  --text-opacity: 1;
  color: #718096;
  color: rgba(113, 128, 150, var(--text-opacity));
}
.jobb .text-gray-700 {
  --text-opacity: 1;
  color: #4a5568;
  color: rgba(74, 85, 104, var(--text-opacity));
}
.jobb .text-gray-900 {
  --text-opacity: 1;
  color: #1a202c;
  color: rgba(26, 32, 44, var(--text-opacity));
}
.jobb .text-red-500 {
  --text-opacity: 1;
  color: #f56565;
  color: rgba(245, 101, 101, var(--text-opacity));
}
.jobb .text-red-700 {
  --text-opacity: 1;
  color: #c53030;
  color: rgba(197, 48, 48, var(--text-opacity));
}
.jobb .text-red-800 {
  --text-opacity: 1;
  color: #9b2c2c;
  color: rgba(155, 44, 44, var(--text-opacity));
}
.jobb .text-green-500 {
  --text-opacity: 1;
  color: #48bb78;
  color: rgba(72, 187, 120, var(--text-opacity));
}
.jobb .text-green-800 {
  --text-opacity: 1;
  color: #276749;
  color: rgba(39, 103, 73, var(--text-opacity));
}
.jobb .text-blue-700 {
  --text-opacity: 1;
  color: #2b6cb0;
  color: rgba(43, 108, 176, var(--text-opacity));
}
.jobb .underline {
  text-decoration: underline;
}
.jobb .opacity-50 {
  opacity: 0.5;
}
.jobb .opacity-90 {
  opacity: 0.9;
}
.jobb .shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-shadow);
}
.jobb .shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -2px rgba(0, 0, 0, 0.1);
  box-shadow: var(--tw-shadow);
}
.jobb .hover\:bg-gray-200:hover {
  --bg-opacity: 1;
  background-color: #edf2f7;
  background-color: rgba(237, 242, 247, var(--bg-opacity));
}
.jobb .hover\:bg-gray-750:hover {
  --bg-opacity: 1;
  background-color: #343a40;
  background-color: rgba(52, 58, 64, var(--bg-opacity));
}
.jobb .hover\:bg-gray-900:hover {
  --bg-opacity: 1;
  background-color: #1a202c;
  background-color: rgba(26, 32, 44, var(--bg-opacity));
}
.jobb .hover\:border-gray-500:hover {
  --border-opacity: 1;
  border-color: #a0aec0;
  border-color: rgba(160, 174, 192, var(--border-opacity));
}
.jobb .hover\:border-gray-700:hover {
  --border-opacity: 1;
  border-color: #4a5568;
  border-color: rgba(74, 85, 104, var(--border-opacity));
}
.jobb .hover\:text-gray-100:hover {
  --text-opacity: 1;
  color: #f7fafc;
  color: rgba(247, 250, 252, var(--text-opacity));
}
.jobb .hover\:text-gray-200:hover {
  --text-opacity: 1;
  color: #edf2f7;
  color: rgba(237, 242, 247, var(--text-opacity));
}
.jobb .hover\:text-gray-500:hover {
  --text-opacity: 1;
  color: #718096;
  color: rgba(113, 128, 150, var(--text-opacity));
}
.jobb .hover\:text-red-700:hover {
  --text-opacity: 1;
  color: #c53030;
  color: rgba(197, 48, 48, var(--text-opacity));
}
.jobb .hover\:text-red-800:hover {
  --text-opacity: 1;
  color: #9b2c2c;
  color: rgba(155, 44, 44, var(--text-opacity));
}
.jobb .hover\:text-green-800:hover {
  --text-opacity: 1;
  color: #276749;
  color: rgba(39, 103, 73, var(--text-opacity));
}
.jobb .hover\:text-blue-500:hover {
  --text-opacity: 1;
  color: #4299e1;
  color: rgba(66, 153, 225, var(--text-opacity));
}
.jobb .focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.jobb .focus\:bg-gray-100:focus {
  --bg-opacity: 1;
  background-color: #f7fafc;
  background-color: rgba(247, 250, 252, var(--bg-opacity));
}
.jobb .focus\:border-gray-700:focus {
  --border-opacity: 1;
  border-color: #4a5568;
  border-color: rgba(74, 85, 104, var(--border-opacity));
}
.jobb .focus\:border-gray-900:focus {
  --border-opacity: 1;
  border-color: #1a202c;
  border-color: rgba(26, 32, 44, var(--border-opacity));
}
.jobb .focus\:ring {
  outline: 0;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}
.jobb .transition {
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.jobb .duration-150 {
  transition-duration: 150ms;
}
.jobb .duration-300 {
  transition-duration: 300ms;
}
.jobb .ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}
.jobb .ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
.jobb .animate-fadeIn {
  animation: fadeIn 0.2s ease-in-out;
}
.jobb .animate-fadeInFast {
  animation: fadeIn 0.05s ease-in-out;
}
.jobb .animate-fadeOut {
  animation: fadeOut 0.3s ease-in-out;
}
.jobb .animate-fadeOutFast {
  animation: fadeOut 0.1s ease-in-out;
}

.joblistcard .card {
min-height: 200px;
  height: 100%;
  border-radius: 10px;
  border: 0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
}
.joblistcard .w-20 {
  width: 20px !important;
  min-width: 20px;
}


.joblistcard .me-3 {
  margin-right: 1rem !important;
}
.joblistcard .card p {
  font-weight: 400 !important;
}
.joblistcard .w-100 {
  width: 100% !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.neww .yes {
  border-radius: 8px;
}
.proimg{
  object-fit: contain;
}

.jobImg {
  height: 110px;
  width: 110px;
  margin: auto;
}
.jobImg img{
width: 100%;
height: 100%;
object-fit: cover;
border-radius: 100%;

}
.neww .hhh {
  /* border: 2px solid lightgray; */
  border-radius: 100%;
  /* width: 110px;
  height: 110px; */
}

.neww .ggg {
  border: 2px solid lightgray;
  border-radius: 20px;
  box-shadow: 10px 10px 10px 0px lightgray, -10px 10px 10px 0px lightgray;
}

.neww p {
  font-size: 14px;
}

.neww .rr {
  border-radius: 10px;
  border: none;
}

.neww .hhtt {
  border: 2px solid lightgray;
  background: #ffffff;
  border-radius: 10px;
  min-height: 100px;
  box-shadow: 5px 5px 5px 0px lightgray, -5px 5px 5px 0px lightgray;
}

 /* .hhtt:hover {
  background: rgb(255, 211, 2);
  background: -moz-linear-gradient(211deg, rgba(255, 211, 2, 1) 0%, rgba(6, 76, 127, 1) 100%);
  background: -webkit-linear-gradient(211deg, rgba(255, 211, 2, 1) 0%, rgba(6, 76, 127, 1) 100%);
  background: linear-gradient(211deg, rgba(255, 211, 2, 1) 0%, rgba(6, 76, 127, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffd302",endColorstr="#064c7f",GradientType=1);
} */

.neww .page-caption {
  text-align: center;
  background-image: url('https://soliloquywp.com/wp-content/uploads/2017/09/image-slider-to-wordpress-template-page.jpg');
  /* z-index: 99; */
  height: 250px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.neww  .page-caption h1 {
  color: #ffffff;
}

.neww .page-caption p,
.neww .page-caption p a {
  color: #ffffff
}

.neww .page-caption p a:hover {
  color: #26ae61
}

.neww .page-caption p i {
  padding: 0 5px;
  position: relative;
  top: 0px;
  font-size: 12px;
  color: #ffffff;
}

@media screen and (min-device-width: 300px) and (max-device-width: 620px) {
  /* STYLES HERE */

  .neww .ggg {
    border: 1px solid lightgray;
    border-radius: 5px;
  }

  .neww  p {
    font-size: 10px;
  }

  .neww h1 {
    font-size: 1.5rem !important;
    color: #000000;
    font-weight: 600;
    margin-top: 20PX;
}

  .neww  .rr {
    border-radius: 10px;
    border: none;
  }




  .neww  .gyy {
    /* display: ; */
    margin-top: 0px;
    width: 55px;
    height: 55px;
    margin-left: 10px;
  }

  .neww  .htt {
    width: 142px;
    margin-left: 10px;
    margin-top: 0px;

  }

  .neww  .hed {
    display: none;
  }

  .neww  .htg {
    font-size: 12px;
    margin-top: px;
  }

  .neww  .htgg {
    font-size: 10px;
    /* margin-top: -10px; */
  }

  .neww   .but {
    font-size: 10px;
    /* margin-top: -10px; */

  }

  .neww  .grr {
    margin-left: -25px;
  }

  .neww  .my_button {
    width: 100%;
    font-size: 10px;
  }
}

.neww  .my_button {
  width: 100%;
  
}




/* serch */

.neww  .search-box {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        border-radius: 25px;
        /* overflow: hidden; */
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.76);
        background-color: white;
        padding: 20px;
    }

    .neww  .search-box .form-control,
    .neww  .search-box .btn {
        /* border: none; */
        border-radius: 0;
        outline: none;
        box-shadow: none;
    }

    .neww .search-box .form-control {
        flex-grow: 1;
        padding: 10px 20px;
    }

    .neww  .search-box .btn {
        padding: 10px 30px;
    }

    @media (min-width: 768px) {
      .neww   .search-box .form-control:first-child {
            border-top-left-radius: 25px;
            border-bottom-left-radius: 25px;
        }

        .neww .search-box .btn {
            border-top-right-radius: 25px;
            border-bottom-right-radius: 25px;
        }
    }
 .neww   .neww body {
font-family: Arial, sans-serif;
background-color: #f0f0f0;
}
.neww .job-table {
width: 100%;
border-collapse: collapse;
margin-top: 20px;
box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
background-color: #fff;
border-radius: 8px;
overflow: hidden;
}
.neww .job-table th, .job-table td {
padding: 12px 15px;
text-align: left;
border-bottom: 1px solid #ddd;
}
.neww  .job-table th {
background-color: #f2f2f2;
font-weight: bold;
color: #333;
}
.neww  .job-table tbody tr:nth-child(even) {
background-color: #f9f9f9;
}
.neww .job-table tbody tr:hover {
background-color: #f1f1f1;
}

.neww  .job-table .view-btn:hover {
background-color: #45a049;
transform: rotateY(180deg);
}

@keyframes blink {
0% { opacity: 1; }
100% { opacity: 0; }
}

@media screen and (max-width: 600px) {
.neww  .job-table th:nth-child(2), 
 .neww .job-table td:nth-child(2) {
    display: none;
  }

  .hometest .ps-deal-of-day .ps-product .ps-product__price {
    color: #690;
    font-size: 14px;
    font-weight: 600;
}


.neww .form-control {
  height: 38px;
  margin-top: 0px;
}

.jmt-5{
  margin-top: 5px;
}
}


.weddingbg{
  font-family: "Sofia", sans-serif;
  overflow: hidden;
}
.weddingbg .head{
  font-weight: lighter;
  color:#fc5e03;
  text-shadow: 5px 5px 5px #bd550f;
  font-size: 50px;
  font-family: "Sofia", sans-serif;
}
.weddingbg p{
  font-size: 15px;
  font-family: "Sofia", sans-serif;
}
.weddingbg h3{
 
  font-family: "Sofia", sans-serif;
}
.weddingbg button{
 font-size: 15px;
  font-family: "Sofia", sans-serif;
}
.weddingbg b{
 
  font-family: "Sofia", sans-serif;
}
.weddingbg  .pho{
  background-image: url('https://marketplace.canva.com/EAFXhX_Q-nY/1/0/1131w/canva-beige-pink-flowers-wedding-frame-page-border-mOc63DpgmbY.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  height: 850px;
  width: 650px;
  
}
.weddingbg  #borderimg { 
     border: 20px solid transparent;
     font-family: "Sofia", sans-serif;
     /* padding:px; */
     border-image: url(https://png.pngtree.com/png-clipart/20230522/original/pngtree-simple-golden-border-frame-png-image_9167281.png) 14% round;

  }

   @media screen and (min-device-width: 300px) and (max-device-width: 660px) { 
   /* STYLES HERE */

   .weddingbg .head{
  font-weight: lighter;
  font-family: "Sofia", sans-serif;
  color:#fc5e03;
  text-shadow: 5px 5px 5px #bd550f;
  font-size: 25px;

}
.weddingbg .pho{
  background-image: url('https://marketplace.canva.com/EAFXhX_Q-nY/1/0/1131w/canva-beige-pink-flowers-wedding-frame-page-border-mOc63DpgmbY.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  height: 550px;
  width: 500px;
  font-family: "Sofia", sans-serif;
  
}
.weddingbg .hhh{
  display: none;
}
.weddingbg .frame{
  width: 200px;
  height: 150px;
  font-family: "Sofia", sans-serif;
}






   }



   .bitrthdayBg  .card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.5s;
    border: 2px solid;
    border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet);
    border-image-slice: 1;
    position: relative;
}

   .bitrthdayBg  .birthday_user {
    width: 100%;
}


.bitrthdayBg span{
  font-family: "Sofia", sans-serif;
}
.bitrthdayBg p{
  font-family: "Sofia", sans-serif;
}
.bitrthdayBg .card1 {
  font-family: "Sofia", sans-serif;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.5s;
    border: 2px solid;
    border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet);
    border-image-slice: 1;
    position: relative;
    background-image: url('https://media.istockphoto.com/id/1623303770/photo/creative-background-image-is-blurred-evening-city-lights-and-light-snowfall.webp?b=1&s=170667a&w=0&k=20&c=PhWd1zt98e6K70KAAb1BjzZXGdQZZRwfqvdtJOwTfIw=');
    background-size: cover;
}

.bitrthdayBg .circle {

    position: relative;
    width: 180px;
    height: 180px;
    border-radius: 50%;
    border: 6px solid #ffef00;
    overflow: hidden;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    /* background-image: url('https://static.vecteezy.com/system/resources/thumbnails/027/614/770/small/happy-birthday-background-with-girl-free-photo.jpg'); */
    background-size: cover;
}
 
.bitrthdayBg .content {
    text-align: center;
    padding: 20px;
    font-family: "Sofia", sans-serif;
}

.bitrthdayBg .sender {
    display: inline-block;
    background: #ffef00;
    color: #0c0c0c;
    padding: 10px 20px;
    border-radius: 20px;
    font-style: italic;
    font-size: 16px;
    margin-top: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    font-family: "Sofia", sans-serif;
}

.bitrthdayBg .happy-birthday {
    font-size: 30px;
    color: #ffef00;
    font-family: "Sofia", sans-serif;
}

@media only screen and (max-width: 600px) {
  .bitrthdayBg   .happy-birthday {
        font-size: 25px;
        color: #ffef00;
        font-family: "Sofia", sans-serif;
    }

    .bitrthdayBg   .animated-text {
        font-size: 22px;
        font-family: "Sofia", sans-serif;
    }
}

.bitrthdayBg .cake_image {
    width: 30%;
}

/* Styling for share buttons */
.bitrthdayBg .share-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.bitrthdayBg .share-buttons button:hover {
    color: #0056b3;
    /* Change color on hover */
    transform: scale(1.2);
    /* Scale up on hover */
}

.bitrthdayBg .animated-text {
    animation: textAnimate 5s infinite;
    color: green;
    font-weight: bold;
    text-align: center;
}

.bitrthdayBg .animated-text span {
  font-family: "Sofia", sans-serif;
    display: inline-block;
    animation: textColorChange 5s infinite;
}

@keyframes textColorChange {

    0%,
    100% {
        color: green;
    }

    33% {
        color: white;
    }

    66% {
        color: orange;
    }
}

.bitrthdayBg .wish_cartoon {
    width: 79%;
}

.bitrthdayBg .second_card {
    background: linear-gradient(to right, #ff00008a, #3b0b4ded);
    position: relative;
}

.bitrthdayBg .speech-bubble {
    background: #fff;
    position: relative;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    color: #777;
    margin: 0 auto;
    margin-bottom: 5%;
}

.bitrthdayBg .speech-bubble:after {
    content: "";
    position: absolute;
    box-shadow: rgba(0, 0, 0, 0.5) 5px 5px 4px;
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    bottom: -20px;
    right: 50px;
    border-width: 20px;
    border-style: solid;
    border-color: #fff;
}

.bitrthdayBg .sender_image {
    width: 11%;
    border: 4px solid white;
    border-radius: 50%;
}

.bitrthdayBg .love-message {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-weight: bold;
    color: white;
    font-size: 18px;
}


.all .job-card {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  position: relative;
  background-color: #fff;
}

.all  .job-card .apply-btn {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.all  .job-card .apply-btn:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.all  .job-card .view-btn {
  color: #6c757d;
  background-color: #fff;
  border-color: #6c757d;
}

.all  .job-card .view-btn:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.all  .skills {
  display: flex;
  flex-wrap: wrap;
}

.all  .skills span {
  background-color: #e0f3f1;
  color: #17a2b8;
  padding: 5px 10px;
  border-radius: 20px;
  margin-right: 5px;
  margin-bottom: 5px;
}
.all .img-fluid {
    width: 100%;
    height: 40%;
    border-radius: 50%;
  }

@media only screen and (max-width: 600px) {
 .all .img-fluid {
    width: 25%;
    height: 90%;
    border-radius: 50%;
  }
}

/* css jaishree end */


/* neeraj sir footer */


.footerNew section {
  padding: 60px 0;
  min-height: 100vh;
}

.footerNew a,
.footerNew a:hover,
.footerNew a:focus,
.footerNew a:active {
  text-decoration: none;
  outline: none;
}

.footerNew a,
.footerNew a:active,
.footerNew a:focus {
  color: #6f6f6f;
  text-decoration: none;
  transition-timing-function: ease-in-out;
  -ms-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-duration: .2s;
  -ms-transition-duration: .2s;
  -moz-transition-duration: .2s;
  -webkit-transition-duration: .2s;
  -o-transition-duration: .2s;
}

.footerNew ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footerNew .footer_logo {
  max-width: 100%;
  height: auto;
}

.footerNew .footer-section {
  background-color: #233243;
  position: relative;
  overflow: hidden;
  font-size: 20px;
  z-index: 9;
}

.footerNew .footer-section:before {
  content: '';
  position: absolute;
  top: -146%;
  left: -18%;
  width: 44%;
  height: 257%;
  transform: rotate(54deg);
  background-color: rgb(31, 47, 64);
  -webkit-transform: rotate(54deg);
  -moz-transform: rotate(54deg);
  -ms-transform: rotate(54deg);
  -o-transform: rotate(54deg);
  z-index: -10;
}

.footerNew .footer-section:after {
  position: absolute;
  content: '';
  background-color: rgb(31, 47, 64);
  top: -24%;
  right: 4%;
  width: 26%;
  height: 264%;
  transform: rotate(44deg);
  -webkit-transform: rotate(44deg);
  -moz-transform: rotate(44deg);
  -ms-transform: rotate(44deg);
  -o-transform: rotate(44deg);
  z-index: -10;
}

.footerNew .footer-top {
  padding-top: 96px;
  padding-bottom: 50px;
}

.footerNew .footer-top p,
.company-footer-contact-list li {
  color: #ffffff;
}

.footerNew .company-footer-contact-list {
  margin-top: 10px;
}

.footerNew .company-footer-contact-list li {
  display: flex;
  display: -webkit-flex;
  align-items: center;
}

.footerNew .company-footer-contact-list li+li {
  margin-top: 5px;
}

.footerNew .company-footer-contact-list li i {
  margin-right: 10px;
  font-size: 20px;
  display: inline-block;
}

.footerNew .footer-top .site-logo {
  margin-bottom: 25px;
  display: block;
  max-width: 170px;
}

.footerNew .widget-title {
  text-transform: capitalize;
}

.footerNew .footer-top .widget-title {
  color: #ffffff;
  margin-bottom: 40px;
}

.footerNew .courses-link-list li+li {
  margin-top: 10px;
}

.footerNew .courses-link-list li a {
  color: #ffffff;
  text-transform: capitalize;
  font-family: var(--para-font);
  font-weight: 400;
}

.footerNew .courses-link-list li a:hover {
  color: #ffb606;
}

.footerNew .courses-link-list li i {
  margin-right: 5px;
}

.footerNew .footer-top .small-post-title a {
  font-family: var(--para-font);
  color: #ffffff;
  font-weight: 400;
}

.footerNew .small-post-item .post-date {
  color: #ffb606;
  margin-bottom: 3px;
  font-family: var(--para-font);
  font-weight: 400;
}

.footerNew .small-post-list li+li {
  margin-top: 30px;
}

.footerNew .news-letter-form {
  margin-top: 15px;
}

.footerNew .news-letter-form input {
  width: 100%;
  padding: 12px 25px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border: none;
}

.footerNew .news-letter-form input[type="submit"] {
  width: auto;
  border: none;
  background-color: #ffb606;
  padding: 9px 30px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  color: #ffffff;
  margin-top: 10px;
}

.footerNew .footer-bottom {
  padding: 13px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.149);
}

.footerNew .copy-right-text {
  color: #ffffff;
}

.footerNew .copy-right-text a {
  color: #ffb606;
}

.footerNew .terms-privacy li+li {
  margin-left: 30px;
}

.footerNew .terms-privacy li a {
  color: #ffffff;
  position: relative;
}

.footerNew .terms-privacy li a:after {
  position: absolute;
  content: '-';
  color: #ffffff;
  display: inline-block;
  top: 0;
  right: -18px;
}

.footerNew .terms-privacy li+li a:after {
  display: none;
}





.buttons {
    /* margin: 10%;
    text-align: center; */
}

.btn-hover {
  width: 190px;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  margin: 10px;
  height: 30px;
  text-align:center;
  border: none;
  background-size: 300% 100%;

  border-radius: 50px;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.btn-hover:hover {
  background-position: 100% 0;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.btn-hover:focus {
  outline: none;
}

.btn-hover.color-1 {
  background-image: linear-gradient(to right, #25aae1, #40e495, #30dd8a, #2bb673);
  box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
}
.btn-hover.color-2 {
  background-image: linear-gradient(to right, #f5ce62, #e43603, #fa7199, #e85a19);
  box-shadow: 0 4px 15px 0 rgba(229, 66, 10, 0.75);
}
.btn-hover.color-3 {
  background-image: linear-gradient(to right, #667eea, #764ba2, #6B8DD6, #8E37D7);
  box-shadow: 0 4px 15px 0 rgba(116, 79, 168, 0.75);
}
.btn-hover.color-4 {
  background-image: linear-gradient(to right, #fc6076, #ff9a44, #ef9d43, #e75516);
  box-shadow: 0 4px 15px 0 rgba(252, 104, 110, 0.75);
}
.btn-hover.color-5 {
  background-image: linear-gradient(to right, #0ba360, #3cba92, #30dd8a, #2bb673);
  box-shadow: 0 4px 15px 0 rgba(23, 168, 108, 0.75);
}
.btn-hover.color-6 {
  background-image: linear-gradient(to right, #009245, #FCEE21, #00A8C5, #D9E021);
  box-shadow: 0 4px 15px 0 rgba(83, 176, 57, 0.75);
}
.btn-hover.color-7 {
  background-image: linear-gradient(to right, #6253e1, #852D91, #A3A1FF, #F24645);
  box-shadow: 0 4px 15px 0 rgba(126, 52, 161, 0.75);
}
.btn-hover.color-8 {
  background-image: linear-gradient(to right, #29323c, #485563, #2b5876, #4e4376);
  box-shadow: 0 4px 15px 0 rgba(45, 54, 65, 0.75);
}
.btn-hover.color-9 {
  background-image: linear-gradient(to right, #25aae1, #4481eb, #04befe, #3f86ed);
  box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);
}
.btn-hover.color-10 {
      background-image: linear-gradient(to right, #ed6ea0, #ec8c69, #f7186a , #FBB03B);
  box-shadow: 0 4px 15px 0 rgba(236, 116, 149, 0.75);
}
.btn-hover.color-11 {
     background-image: linear-gradient(to right, #eb3941, #f15e64, #e14e53, #e2373f);  box-shadow: 0 5px 15px rgba(242, 97, 103, .4);
}


@media (max-width: 479px) {
  .hometest .ps-home-banner .ps-collection {
    padding: 0 5px;
    margin-bottom: 0;
  }

  .btn-hover {
    width: 170px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    margin: 4px;
    height: 30px;
    text-align:center;
    border: none;
    background-size: 300% 100%;
  
    border-radius: 50px;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
  }

  .blankcard {
    width: 300px;
    height: 330px;
    position: relative;
    border: 5px solid transparent;
    border-radius: 10px;
  }
  .hometest .ps-home-banner .ps-section__right {
    margin: 0 0px;
  }
  .categorycom img {
    width: 80px;
    height: 80px;
    margin: auto;
    margin-top: 4px;
  }

  .recentlisting {
    max-width: 300px;
    
  }
  .footerNew .courses-link-list li a {
    color: #ffffff;
    text-transform: capitalize;
    font-family: var(--para-font);
    font-weight: 400;
    font-size: 13px;
}
.footerNew .courses-link-list li+li {
  margin-top: 1px;
}
.animated_card {
  height: 260px !important;
  height: 200px;
  position: relative;
  border: 5px solid transparent;
  border-radius: 10px;
  animation: border-animation 2s infinite;
}
@keyframes border-animation {
  0% {
    border-color: orange;
  }

  25% {
    border-color: blue;
  }

  50% {
    border-color: green;
  }

  75% {
    border-color: yellow;
  }

  100% {
    border-color: blueviolet;
  }
}
  .seal {
    position: absolute;
    top: -5%;
    right: 0%;
    color: white;
    width: 70px;
    height: 80px;
    /* background-color: rgb(14, 206, 62); */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
}
.aboutusimgevender {
  width: 100%;
  height: 200px;
}

  .lostDate {
    margin-bottom: 1px;
    font-size: 11px;
    height: 20px;
  }

  .rainbow-btn {
    margin: 2px auto;
    width: 160px;
    text-align: center;
    position: relative;
    color: #ffffff;
    text-decoration: none;
    border-radius: 6px;
    box-sizing: border-box;
    display: block;
    z-index: 2;
    overflow: hidden;
    padding: 8px;
    height: 51px;
  }
  .rainbow-btn:hover {
    margin: 2px auto;
    width: 160px;
    text-align: center;
    position: relative;
    color: #ffffff;
    text-decoration: none;
    border-radius: 6px;
    box-sizing: border-box;
    display: block;
    z-index: 2;
    overflow: hidden;
    padding: 8px;
    height: 51px;
  }
  .winner-back .card {
    margin-top: 1px;
  }
  .ps-product .ps-product__badge {
    /* position: absolute; */
    top: 0px;
    right: 0;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    padding: 2px 15px;
    /* border-radius: 13px; */
    background-color: #f14705;
    /* border-top-right-radius: 1px; */
    /* border-top-left-radius: 4px; */
  }
  .win_pra p {
    font-size: 11px;
    margin: 3px;
  }
  .footerNew .footer-top p, .company-footer-contact-list li {
    color: #ffffff;
    font-size: 13px;
}
.btn-dark1 {      
  color: #fff;
  background-color: #256C34;
  border-color: #343a40;
  font-size: 9px;
}
  .ps-product__content p {
    font-size: 11px;
  }

  .ps-product--detail h1 {
    font-size: 20px;
  }

  .ps-product--detail .ps-product__price {
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: 600;
    color: #000;
  }
  .ps-product--detail .ps-product__desc {
    margin-bottom: 4px;
    border-bottom: 1px solid #e1e1e1;
  }
  .ps-product--detail .ps-product__desc p {
    color: #000;

    font-size: 15px;
    margin-bottom: 3px;
  }

  .p-25 {
    padding: 5px !important;
  }
  .productPage__left {
    /* height: 100vh; */
    top: 0px;
    /* width: 60vw; */
    display: flex;
    padding: 5px 0px !important;
    margin-top: 1vh;
    position: -webkit-sticky;
    position: sticky;
    /* background-color: #e4e4e4; */
    text-align: center;
    align-items: center;
    flex-direction: column;
  }
  .ps-product--detail .ps-product__variations figure {
    margin-bottom: 3px;
  }

  .ps-product--detail .ps-product__variations figure figcaption {
    margin-bottom: 3px;
    font-size: 14px;
    color: #666;
    font-weight: 400;
  }

  .overflowhidn {
    overflow: hidden;
  }
  .adsboxcus {
    margin-top: 5px;
    max-height: 200px;
    padding-left: 1px;
    padding-right: 1px;
  }
  .ps-product--detail .ps-product__variations {
    margin-bottom: 4px;
  }
  .hometest .ps-home-banner .ps-banner {
    min-height: 130px;
    margin-top: 5px;
  }
  
  .footerNew .footer-top .widget-title {
    color: #ffffff;
    margin-bottom: 10px;
}
.hometest .ps-home-banner .ps-banner.cateslider {
  min-height: 130px;
}

  .hometest .ps-deal-of-day {
    /* padding-bottom: 80px; */
    padding: 2px 0px;
}
.card.lost_found .card-text{
font-size: 14px;
}
.mt-30 {
margin-top: 5px !important;
}
.mb-30{
  margin-bottom: 5px !important;
}
.lostfound .ps-section__content{
  margin-top: 2px;
}
  .hometest .ps-home-banner .ps-section__left {
    max-width: 100%;
    padding-right: 0;
    margin-bottom: 1px;
  }
}

/*
Template Name: Matrimonial
File: Layout CSS
Author: TemplatesOnWeb
Author URI: https://www.templateonweb.com/
Licence: <a href="https://www.templateonweb.com/license">Website Template Licence</a>
*/

/*********************center_h****************/
/* .weddingportal .carousel-caption {
  background-color: rgba(0, 0, 0, .4) !important;
  bottom: 70px;
  left: 0;
  color: #fff;
  width: 85%;
  text-align: center;
  left: 10%;
  padding: 20px;
  height: 40%;

} */
.weddingportal .carousel-caption1 {
  left:57%;
}
.weddingportal .carousel-caption2 {
  left:5%;
}
.weddingportal .center_home .carousel-indicators {
  bottom:20px; 
}
/*********************center_h_end****************/

/*********************about_h****************/
.weddingportal .social_tag li a {
  border:1px solid #c78665;
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: #c78665;
  display: block;
  text-align: center;
  border-radius: 50%;
  font-size: 18px;
  transition: 0.3s;
}
.weddingportal .social_tag li a:hover {
  border:1px solid #c78665;
  background:#c78665;
  color:#fff;
}

.weddingportal .about_hm2 {
  top:0;
  left:-5%; 
}
.weddingportal .about_hm3 {
  bottom:0;
  right:-5%; 
}
.weddingportal .about_hm4 {
  bottom:40%;
  right:-5%; 
}
/*********************about_h_end****************/


/*********************attend****************/
.weddingportal .attend_1m {
  padding:50px 50px;
  border-radius:50% 50% 0 0; 
}
.weddingportal .attend_1m .form-control {
  border-top:0; 
  border-left:0; 
  border-right:0; 
}
.weddingportal .attend_1m .form-select {
  border-top:0; 
  border-left:0; 
  border-right:0; 
}
.weddingportal .attend_1l {
  padding-top:150px; 
}
/*********************attend_end****************/

/*********************bride****************/

/* .weddingportal #bride .about_h1l {
  width:75%; 
  margin-top:12%;
  margin-left:12%;
} */
.weddingportal #bride .about_h1l {
  width: 67%;
  margin-top: -88%;
  margin-left: 17%;
}
/*********************bride_end****************/



@media screen and (max-width : 767px){
  .weddingportal .carousel-caption {
      padding:30px 15px 70px 15px;
      position:static!important;
      width:100%;
      background:#333!important;
  }
  .weddingportal .carousel-caption1 {
      left:0%;
  }
  .weddingportal .carousel-caption2 {
      left:0%;
  }
  .weddingportal .center_home .carousel-indicators {
      bottom:15px; 
  }
  .weddingportal .carousel-control-prev {
      display:none;
  }
  .weddingportal .carousel-control-next {
      display:none;
  }

  .weddingportal .center_home p {
      text-align:left;
      font-size:14px;
  }
  .weddingportal .center_home br {
      display:none;
  }
  .weddingportal .center_home .font_60 {
      font-size:32px;
  }
  .weddingportal .center_home .button {
      padding:10px 15px;
  }
  .weddingportal .about_hm2 {
      display:none; 
  }
  .weddingportal .about_hm3 {
      display:none; 
  }
  .weddingportal .about_hm4 {
      display:none; 
  }
  .weddingportal .about_h1r {
      text-align:center!important; 
  }
  .weddingportal .about_h1r p {
      text-align:left; 
  }
  .weddingportal .about_h1l {
      margin-top:15px;
      margin-bottom:15px; 
  }
  .weddingportal .attend_1l {
      padding-top:0;
  }
  .weddingportal .attend_1m {
      padding: 20px 15px;
      border-radius:0;
      margin-top:15px;
      margin-bottom:15px;
  }
  .weddingportal .bride_1i {
      margin-top:15px;
      margin-bottom:15px; 
  }
  .weddingportal .attend_1l {
      display:none;
  }
  .weddingportal .bride_1i2n h1 {
      font-size:36px; 
  }
  .weddingportal .bride_1i2n h6 {
      font-size:24px; 
  }
}


@media (min-width:576px) and (max-width:767px) {
  .weddingportal .carousel-caption {
      bottom:50px;
  }
}

@media (min-width:768px) and (max-width:991px) {
  .weddingportal .center_home img {
      min-height:800px; 
  }
  .weddingportal .center_home .font_60 {
      font-size:36px;
  }
  .weddingportal .about_hm2 img {
      width:100px;
      height:100px; 
  }
  .weddingportal .about_hm3 img {
      width:100px;
      height:100px; 
  }
  .weddingportal .about_hm4 img {
      width:100px;
      height:100px;
  }
  .weddingportal .about_hm2 {
      left:0;
  }
  .weddingportal .about_hm4 {
      right:0;
  }
  .weddingportal .about_hm3 {
      right:0;
  }
  .weddingportal .attend_1m {
      padding: 20px 15px;
  }
}

@media (min-width:992px) and (max-width:1200px) {
  .weddingportal .center_home img {
      min-height:800px; 
  }
  .weddingportal .about_hm2 img {
      width:130px;
      height:130px; 
  }
  .weddingportal .about_hm3 img {
      width:130px;
      height:130px; 
  }
  .weddingportal .about_hm4 img {
      width:130px;
      height:130px;
  }
  .weddingportal .about_hm2 {
      left:0;
  }
  .weddingportal .about_hm4 {
      right:0;
  }
  .weddingportal .about_hm3 {
      right:0;
  }
}
@media (min-width:1201px) and (max-width:1280px) {
  .weddingportal .about_hm2 {
      left:0;
  }
  .weddingportal .about_hm4 {
      right:0;
  }
  .weddingportal .about_hm3 {
      right:0;
  }
  .weddingportal .about_hm2 img {
      width:130px;
      height:130px; 
  }
  .weddingportal .about_hm3 img {
      width:130px;
      height:130px; 
  }
  .weddingportal .about_hm4 img {
      width:130px;
      height:130px;
  }
}
@media (min-width:1400px) and (max-width:2000px) {
  .weddingportal .about_hm2 {
      left:0;
  }
  .weddingportal .about_hm4 {
      right:0;
  }
  .weddingportal .about_hm3 {
      right:0;
  }
}


/* global css */

.weddingportal body{
  font-family: 'Poppins', sans-serif;
  color:#333;
  }
  
  .weddingportal ul{
  list-style:none;
  padding:0;
  font-size:15px;
   }
   .weddingportal p{
  font-size:15px;
  line-height:1.8em; 
   }
   .weddingportal h1,.weddingportal h2,.weddingportal h3{
   font-family: "Dancing Script", cursive;  
   }
   .weddingportal h4,.weddingportal h5,.weddingportal h6{
  font-family: "Cormorant Garamond", serif; 
   }
   .weddingportal h1,.weddingportal h2, .weddingportal h3,.weddingportal h4,.weddingportal h5{
  font-weight:bold; 
   }
   .weddingportal h6{
  font-weight:bold;
  font-size:18px;
   }
   .weddingportal h5{
  font-size:20px;
   }
   .weddingportal h4{
  font-size:26px;
   }
   .weddingportal a{
  color: #333;
  text-decoration:none;
   }
   .weddingportal a:hover{
  text-decoration:none;
  color:#c78665;
   }
   .weddingportal a:focus{
  text-decoration:none;
  color: #c78665;
   }
   
   .weddingportal .a_tag:hover{
  color:#c78665!important;
   }
   .weddingportal .a_tag:focus{
  color: #c78665!important;
   }
   .weddingportal .a_tag1:hover{
  color:#fbb03b!important;
   }
   .weddingportal .a_tag1:focus{
  color: #fbb03b!important;
   }
  
   
   .weddingportal .button{
  transition: 0.3s;
  padding: 17px 50px 17px 50px;
  display: inline-block;
  font-size:18px;
  color:#fff;
  border-radius:3px;
  border:1px solid #fff;
   }
  
   .weddingportal .button:hover{
  background:#c78665;
  color:#fff;
  border:1px solid #c78665;
   }
  
   .weddingportal .button_1{
  transition: 0.3s;
  padding: 17px 50px 17px 50px;
  display: inline-block;
  font-size:18px;
  background:#c78665;
  color:#fff;
  border-radius:3px;
   }
  
   .weddingportal .button_1:hover{
  background:#121f38;
  color:#fff;
   }
  
   .weddingportal .button_2{
  transition: 0.3s;
  padding: 17px 50px 17px 50px;
  display: inline-block;
  font-size:18px;
  background:#121f38;
  color:#fff;
  border-radius:3px;
   }
  
   .weddingportal .button_2:hover{
  background:#c78665;
  color:#fff;
   }
   
   .weddingportal .grid figure {
  position: relative;
  float: left;
  overflow: hidden;
  width: 100%;
  text-align: center;
  cursor: pointer;
  }
  .weddingportal figure.effect-jazz figcaption::after,
  .weddingportal figure.effect-jazz img
  {
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  }
  figure.effect-jazz figcaption::after,
  figure.effect-jazz img
  {
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  }
  .weddingportal figure.effect-jazz:hover img {
  -webkit-transform: scale3d(1.05,1.05,1);
  transform: scale3d(1.05,1.05,1);
  }
  .weddingportal .col_brown{
  color:#c78665!important; 
   }
  .weddingportal .col_red{
  color:#ee0b42!important; 
   }
  .weddingportal .col_dark{
  color:#cd0b3a!important; 
   }
   .weddingportal
  figure.effect-jazz:hover img {
  -webkit-transform: scale3d(1.05,1.05,1);
  transform: scale3d(1.05,1.05,1);
  }
  .weddingportal .col_brown{
  color:#c78665!important; 
   }
   .weddingportal .col_red{
  color:#ee0b42!important; 
   }
   .weddingportal .col_dark{
  color:#cd0b3a!important; 
   }
  
   .weddingportal .bg_brown{
  background-color: #c78665!important;
   }
   .weddingportal .bg_light{
  background-color: #edf5f7!important;
   }
  
   .weddingportal .bg_back{
  background-color: rgba(0,0,0,.4)!important;
  }
  
  .weddingportal hr{
  opacity:1;
  background-color:#f9e5db;
  margin-top:15px;
  margin-bottom:20px;
   }
  
  
   .weddingportal .font_14{
  font-size:14px; 
   }
   .weddingportal .font_8{
  font-size:8px; 
   }
   .weddingportal .font_10{
  font-size:10px; 
   }
   .weddingportal .font_12{
  font-size:12px; 
   }
   .weddingportal .font_13{
  font-size:13px; 
   }
    .weddingportal .font_15{
  font-size:15px; 
   }
   .weddingportal .font_60{
  font-size:60px; 
   }
   .weddingportal .font_80{
  font-size:80px; 
   }
   .weddingportal .font_50{
  font-size:40px; 
  font-family: "Playwrite BE WAL", cursive;
  font-optical-sizing: auto;
  font-style: normal;
}

   
  
   .weddingportal .line {
  height:2px!important;
  width: 100px;
  background-color:#c78665;
  }
  
  .weddingportal .p_3{
  padding-top:30px;
  padding-bottom:30px; 
   }
  
   .weddingportal .border_1{
  border:23px solid #f9e5db;
   }
  
   .weddingportal .carousel_p .carousel-indicators {
  bottom: -60px!important;
  }
  .weddingportal .carousel_p {
  padding-bottom: 70px!important;
  }
  
  .weddingportal .dropdown:hover .dropdown-menu { 
  display: block; 
  } 
  .weddingportal .dropdown>.dropdown-toggle:active {
  /*Without this, clicking will make it sticky*/
  pointer-events: none;
  }
  
  .weddingportal .family_1{
   font-family: "Dancing Script", cursive; 
   }
   .weddingportal .family_2{
   font-family: 'Poppins', sans-serif;
   }
   .weddingportal .px_4{
  padding-left:40px;
  padding-right:40px; 
   }
   .weddingportal tbody, td, tfoot, th, thead, tr{
  border-color:#f9e5db; 
   }
   .weddingportal .drop_1i1l .fs-6{
  font-size:17px!important; 
   }
    .weddingportal .drop_1i1l .font_15{
  font-size:17px; 
   }
  
  /*********************header****************/
  
  /*********************top****************/
  .weddingportal .top_1r ul li a{
  width:30px;
  height:30px;
  line-height:30px;
  background:#ab7458;
  transition: 0.3s;
  border-radius:50%;
   }
   .weddingportal .top_1r ul li a:hover{
  background:#ee0b42;
   }
  /*********************top_end****************/
  .weddingportal .nav_hide:after{
  display:none; 
   }
   .weddingportal .navbar{
  box-shadow: 0px 0px 6.1px 3.9px rgb(28 31 86 / 6%);
  background:#fff;
   }
   .weddingportal .navbar-collapse {
  align-items:start;
  }
  
  .weddingportal #header .navbar-brand{
  font-size:42px;
  margin:0;
  line-height:0.3em;
  padding-bottom:5px!important;
   }
   .weddingportal #header .navbar-brand span{
  margin-left:60px;
   }
   .weddingportal #header .nav-link{
  padding: 20px 14px;
  color: #100c08;
  font-size:13px;
  text-transform:uppercase;
  font-weight:bold;
   }
    .weddingportal #header .nav-link:hover{
  background:none;
  color:#ee0b42;
  /* background: url("/img/wedding/1.png") center bottom no-repeat; */
 
   }
   .weddingportal #header .nav-link:focus{
  background:none;
  color:#ee0b42;
  /* background: url("/img/wedding/1.png") center bottom no-repeat; */
   }
   .weddingportal #header .active{
  background:none;
  color:#ee0b42;
  font-weight:bold;
  /* background: url("/img/wedding/1.png") center bottom no-repeat; */
   }
  
   .weddingportal #header .drop_1{
  min-width:200px;
  border-radius:0;
  margin-top:0px!important;
  border:none;
  background:#fff;
  padding:0;
  left:auto!important;
  right:0!important;
  box-shadow: 0px 0px 6.1px 3.9px rgb(28 31 86 / 6%);
  padding:0;
   }
   .weddingportal #header .drop_1 a{
  padding-top:10px;
  padding-bottom:10px;
  border-bottom:1px solid #f9e5db;
  color:#666;
  color: #121f38;
  background:#fff;
  font-size:14px;
   }
   .weddingportal #header .drop_1 a:hover{
  background:none;
  color:#ee0b42;
   }
  
   .weddingportal .sticky {
  position: fixed;
  top: 0;
  width: 100%!important;
  z-index:3;
  background-color:#fff!important;
  border-radius:0;
  }
  .weddingportal .sticky {
  top: -40px;
  transform: translateY(40px);
  transition: transform .3s;
  }
  
  .weddingportal .drop_cart{
  min-width:320px; 
  left:auto;
  right:0;
  padding:20px 15px;
  border-radius:0!important;
  left:auto!important;
  right:0!important;
  box-shadow: 0px 0px 6.1px 3.9px rgb(28 31 86 / 6%);
  background:#fff;
   }
   .weddingportal .drop_1i{
  border-bottom:1px solid #f9e5db;
  padding-bottom:10px;
   }
   .weddingportal .drop_1i1l span i{
  display:inline-block;
  text-align:center;
  font-size:20px;
  margin-top:10px;
  cursor:pointer; 
   }
   .weddingportal .drop_1i1l span i:hover{
  color:#c78665;
   }
   .weddingportal .drop_1i1{
  padding-top:10px;
  padding-bottom:10px;
  border-bottom:1px solid #f9e5db;
   }
   .weddingportal .drop_1i2{
  padding-top:10px;
  padding-bottom:10px; 
   }
   .weddingportal .drop_2 {
  min-width: 400px;
  padding: 10px;
  border-radius: 0;
  border: none;
  box-shadow: 0px 0px 6.1px 3.9px rgb(28 31 86 / 6%);
  right: 0!important;
  left: auto!important;
  }
  .weddingportal .drop_2 .form-control{
  border-radius:3px 0px 0px 3px; 
  font-family: arial;
   }
   .weddingportal .drop_2 .btn{
  border-radius:0px 3px 3px 0px; 
   }
  /*********************header_end****************/
  
  
  /*********************common****************/
  .weddingportal .pages ul li{
   display:inline-block;
   }
   .weddingportal .pages ul li a{
  display:block;
  color: #0c121d;
   margin: 0 2px;
   width:40px;
   height:40px;
   line-height:38px;
   text-align:center;
   border:1px solid #f9e5db;
   background:#fff;
   }
   .weddingportal .pages ul li a:hover{
   background: #c78665;
   border:1px solid #c78665;
   }
   .weddingportal .pages ul li a:hover{
   color: #fff!important;
   }
   .weddingportal .act{
  background:#c78665!important;
  border:1px solid #c78665!important;
  color:#fff!important;
   }
  
   /* footer social icons */
   .weddingportal ul.social-network {
  list-style: none;
  display: block;
  margin-left:0 !important;
  padding: 0;
  }
  .weddingportal ul.social-network li {
  display: inline-block;
  }
  
  /* footer social icons */
  .weddingportal .social-network a.icoRss:hover {
  background-color: #F56505;
  }
  .weddingportal .social-network a.icoFacebook:hover {
  background-color:#3B5998;
  }
  .weddingportal.social-network a.icoTwitter:hover {
  background-color:#33ccff;
  }
  .weddingportal.social-network a.icoGoogle:hover {
  background-color:#BD3518;
  }
  .weddingportal .social-network a.icoVimeo:hover {
  background-color:#0590B8;
  }
  .weddingportal .social-network a.icoLinkedin:hover {
  background-color:#007bb7;
  }
  .weddingportal .social-network a.icoRss:hover i, .social-network a.icoFacebook:hover i, .social-network a.icoTwitter:hover i,
  .weddingportal .social-network a.icoGoogle:hover i, .social-network a.icoVimeo:hover i, .social-network a.icoLinkedin:hover i {
  color:#fff;
  }
  .weddingportal a.socialIcon:hover, .socialHoverClass {
  color:#44BCDD;
  }
  
  .weddingportal .social-circle li a {
  display:inline-block;
  position:relative;
  margin:0 2px 0 2px;
  -moz-border-radius:50%;
  -webkit-border-radius:50%;
  border-radius:50%;
  text-align:center;
  width: 40px;
  height: 40px;
  }
  .weddingportal .social-circle li i {
  margin:0;
  line-height:40px;
  text-align: center;
  }
  
  .weddingportal .social-circle li a:hover i, .triggeredHover {
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -ms--transform: rotate(360deg);
  transform: rotate(360deg);
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  }
  .weddingportal .social-circle i {
  color: #fff;
  -webkit-transition: all 0.8s;
  -moz-transition: all 0.8s;
  -o-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  }
  .weddingportal .form-control:focus{
    box-shadow:none;
   border-color:#f9e5db; 
   }
   .weddingportal .form-select:focus{
   box-shadow:none;
   border-color:#f9e5db;  
   }
   .weddingportal .form-control{
    border-color:#f9e5db;
    box-shadow:none;
    border-radius:0;
    height:50px;
    font-size:14px;
   }
   .weddingportal .form-select{
  border-color:#f9e5db;
  box-shadow:none; 
  border-radius:0;
  height:50px;
  color:#777;
  font-size:14px;
   }
  
   .weddingportal .shadow_box{
  box-shadow: 0px 0px 6.1px 3.9px rgb(28 31 86 / 6%);
   }
   .weddingportal .carousel-indicators [data-bs-target] {
   background-color:#c78665; 
  }
  
  .weddingportal .weddingportal.input-group .form-control{
  height:auto; 
   }
   .weddingportal .input-group .form-control:focus{
  box-shadow:none;
   }
   .weddingportal .form-check .form-check-input:checked {
  background-color: #c78665;
  border-color: #c78665;
  }
  .weddingportal .form_text{
  height:150px!important; 
   }
  
  
   .weddingportal #center_bottom{
         /* background:url(/img/wedding/5.png) left top repeat-x; */
       position:absolute;
       bottom:-5px;
       width:100%;
       height:30px;
     }
     
     .weddingportal #center_top{
         /* background:url(/img/wedding/5.png) left top repeat-x; */
       position:absolute;
       top:-5px;
       width:100%;
       height:30px;
       -webkit-transform: rotate(180deg);
         transform: rotate(180deg);
     } 
     .weddingportal .icon_dove:after {
      display: inline-block;
      width: 10%;
      height: 2px;
      background-color: #c78665;
      vertical-align: middle;
      margin-left: 1%;
      content: "";
  }
  .weddingportal .icon_dove:before {
      display: inline-block;
      width: 10%;
      height: 2px;
      background-color: #c78665;
      vertical-align: middle;
      margin-right: 1%;
      content: "";
  }
  
  .weddingportal::placeholder {
    color: #777;
    opacity: 1; /* Firefox */
  }
  
  .weddingportal .tags a {
      border: 1px solid #f9e5db;
      color: #333;
      font-size: 14px;
      margin: 0px 2px 6px 0px;
      padding: 6px 16px 6px 16px;
      font-size: 14px;
      transition: 0.3s;
      display: block;
  }
  .weddingportal.tags a:hover {
      border: 1px solid #c78665;
      background:#c78665;
    color:#fff;
  }
  /*********************common_end****************/
  
  /*********************team_h****************/
  .weddingportal.team_h1i1i1 ul li a{
  width:35px;
  height:35px;
  line-height:35px; 
   }
   .weddingportal.team_h1i2ir span a{
  width:40px;
  height:40px;
  line-height:40px; 
   }
   .weddingportal.team_h1i1i1{
  display:none; 
  transition: 0.3s; 
   }
   .weddingportal.team_h1i:hover .team_h1i1i1{
  display:block;
  transition: 0.3s;  
   }
  /*********************team_h_end****************/
  
  /*********************price****************/
  .weddingportal.price_m3{
  bottom:0; 
  width:90%;
  left:5%;
  bottom:-26%;
   }
   .weddingportal #price{
  margin-bottom:120px; 
   }
  /*********************price_end****************/
  
  
  /*********************services****************/
  .weddingportal #serv_h {
  /* background-image: url(/img/wedding/36.jpg); */
  background-position: center center;
  background-size: cover;
  }
  .weddingportal .serv_h1i1 span{
  width:120px;
  height:120px;
  line-height:120px;
  border:2px dotted #c78665;
  display:inline-block; 
   }
   .weddingportal .serv_h1i2 span a{
  width:40px;
  height:40px;
  line-height:40px; 
  transition: 0.3s;
  background:#fff; 
   }
   .weddingportal .serv_h1i:hover .serv_h1i2 span a{
  background: #c78665;
  color:#fff;
  transition: 0.3s; 
   }
   .weddingportal .serv_h1i2{
  bottom:-10px; 
   }
   .weddingportal .serv_h1i:hover .serv_h1i1 span{
  color:#000!important;
  transition: 0.3s; 
   }
  /*********************services_end****************/
  
  /*********************story****************/
  .weddingportal  .timeline {
    list-style: none;
    padding: 20px 0 20px;
    position: relative;
  }
  .weddingportal .timeline:before {
    top: 0;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 3px;
    background-color: #eeeeee;
    left: 50%;
    margin-left: -1.5px;
  }
  .weddingportal .timeline > li {
    margin-bottom: 20px;
    position: relative;
  }
  .weddingportal .timeline > li:before,
  .weddingportal .timeline > li:after {
    content: " ";
    display: table;
  }
  .weddingportal .timeline > li:after {
    clear: both;
  }
  .weddingportal .timeline > li:before,
  .weddingportal .timeline > li:after {
    content: " ";
    display: table;
  }
   .weddingportal.timeline > li:after {
    clear: both;
  }
  .weddingportal .timeline > li > .timeline-panel {
    width: 50%;
    float: left;
    border: 1px solid #d4d4d4;
    border-radius: 2px;
    padding: 20px;
    position: relative;
    -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
  }
  .weddingportal .timeline > li.timeline-inverted + li:not(.timeline-inverted),
  .weddingportal .timeline > li:not(.timeline-inverted) + li.timeline-inverted {
  margin-top: -60px;
  }
  
  .weddingportal .timeline > li:not(.timeline-inverted) {
  padding-right:90px;
  }
  
  .weddingportal .timeline > li.timeline-inverted {
  padding-left:90px;
  }
  .weddingportal .timeline > li > .timeline-panel:before {
    position: absolute;
    top: 26px;
    right: -15px;
    display: inline-block;
    border-top: 15px solid transparent;
    border-left: 15px solid #ccc;
    border-right: 0 solid #ccc;
    border-bottom: 15px solid transparent;
    content: " ";
  }
  .weddingportal .timeline > li > .timeline-panel:after {
    position: absolute;
    top: 27px;
    right: -14px;
    display: inline-block;
    border-top: 14px solid transparent;
    border-left: 14px solid #fff;
    border-right: 0 solid #fff;
    border-bottom: 14px solid transparent;
    content: " ";
  }
  .weddingportal .timeline > li > .timeline-badge {
    color: #fff;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 1.4em;
    text-align: center;
    position: absolute;
    top: 16px;
    left: 50%;
    margin-left: -25px;
    background-color: #999999;
    z-index: 100;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
  }
  .weddingportal .timeline > li.timeline-inverted > .timeline-panel {
    float: right;
  }
  .weddingportal .timeline > li.timeline-inverted > .timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
  }
  .weddingportal .timeline > li.timeline-inverted > .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
  }
  .weddingportal .timeline-badge.primary {
    background-color: #2e6da4 !important;
  }
  .weddingportal .timeline-badge.success {
    background-color: #3f903f !important;
  }
  .weddingportal .timeline-badge.warning {
    background-color: #f0ad4e !important;
  }
  .weddingportal .timeline-badge.danger {
    background-color: #d9534f !important;
  }
  .weddingportal .timeline-badge.info {
    background-color: #5bc0de !important;
  }
  .weddingportal .timeline-title {
    margin-top: 0;
    color: inherit;
  }
  .weddingportal .timeline-body > p,
  .timeline-body > ul {
    margin-bottom: 0;
  }
  .weddingportal .timeline-body > p + p {
    margin-top: 5px;
  }
  /*********************story_end****************/
  
  /*********************moment****************/
  .weddingportal .moment_1li2{
  background:#ffffffbf;
  padding-top:55%; 
  width:95%;
  height:95%;
  top:2.6%;
  left:2.6%;
  display:none;
  transition: 0.3s;
   }
   .weddingportal .moment_1li:hover .moment_1li2{
  display:block;
  transition: 0.3s; 
   }
   .weddingportal #moment .modal-dialog {
    max-width: 750px;
  }
  .weddingportal .moment_1li2o{
  padding-top:25%; 
  width:95%;
  height:95%;
  top:2.6%;
  left:2.6%; 
   }
  /*********************moment_end****************/
  
  
  /*********************footer****************/
  .weddingportal .footer_1i h1{
  font-size:42px;
  line-height:0.3em;
   }
   .weddingportal .footer_1i h1 span{
  margin-left:60px;
   }
   .weddingportal #footer hr{
  background-color:#d3e9ef; 
   }
   .weddingportal .footer_2r {
  margin-top:5px; 
   }
   .weddingportal .footer_1i1l{
  padding:1px;
  }
  /*********************footer_end****************/
  
  
  
  @media screen and (max-width : 767px){
    .weddingportal .navbar-collapse{
  max-height:500px;
  overflow-y:scroll; 
  border-top:1px solid #f9e5db;
  margin-top:15px;
   }
   .weddingportal #header .nav-link{
  font-size:26px!important;
  border-bottom:1px solid #f9e5db;
  padding-top:10px;
  padding-bottom:10px;
  border-radius:0!important;
  border-right:none;
   }
  
   .weddingportal .navbar .navbar-toggler{
  box-shadow:none!important;
  background:#fafafa;
  border:none;
  border-radius:0;
  margin-top:0;
   }
   .weddingportal #header .drop_1{
  margin-top:0!important;
  min-width:100%; 
   }
   .weddingportal #header .drop_2{
  margin-top:0!important;
  min-width:100%; 
   }
   .weddingportal #header .drop_cart{
  margin-top:0!important;
  min-width:100%; 
   }
   .weddingportal #header .drop_1 a{
  font-size:22px; 
  padding-left:35px;
   }
   .weddingportal .navbar{
  padding-top:10px!important;
  padding-bottom:10px!important;
  padding-left:0!important;
  padding-right:0!important;
  width:100%;
  border-radius:0;
   }
  
   .weddingportal .sticky .drop_1{
  margin-top:0!important; 
   }
   .weddingportal.line{
  margin-left:auto;
  margin-right:auto; 
   }
  
  
   .weddingportal.pages ul li{
  margin-top:5px;
  margin-bottom:5px; 
   }
  
   .weddingportal.center_sm{
  text-align:center!important; 
   }
   .weddingportal.center_left{
  text-align:left!important; 
   }
   .weddingportal.font_60{
  font-size:45px; 
   }
   .weddingportal.font_50{
  font-size:36px; 
   }
   .weddingportal #top{
  display:none; 
   }
   .weddingportal #header .navbar-brand{
  font-size:28px;
   }
   .weddingportal #header .navbar-brand span{
  margin-left:40px;
  font-size:12px!important;
   }
   .weddingportal #header .nav-link:hover{
  background:none!important;
   }
   .weddingportal #header .nav-link:focus{
  background:none!important;
   }
   .weddingportal #header .active{
  background:none!important;
   }
   .weddingportal .px_4{
  padding-left:0;
  padding-right:0; 
   }
   .weddingportal.timeline-body  p{
  font-size:12px; 
   }
   .weddingportal .moment_1l{
  margin-top:15px; 
   } 
   .weddingportal.shop_h1l{
  margin-top:15px; 
  margin-bottom:15px;  
   }
   .weddingportal.price_m3{
  position:absolute!important;
  width:100%; 
   }
   .weddingportal.price_m1{
  position:static!important;
  padding-top:0!important; 
   }
   .weddingportal.price_2i{
  margin-top:8px; 
  margin-bottom:8px;  
   }
   .weddingportal #price {
  margin-bottom:0;
  }
  .weddingportal .blog_hm{
  margin-top:8px; 
  margin-bottom:8px;  
   }
   .weddingportal .blog_hm2{
  text-align:center; 
   }
   .weddingportal .serv_h1i{
  margin-top:15px; 
  margin-bottom:15px; 
   }
   .weddingportal .team_h1i{
  margin-top:8px; 
  margin-bottom:8px;   
   }
   .weddingportal .team_h1i2il{
  text-align:center;  
   }
   .weddingportal .team_h1i2ir{
  text-align:center!important;  
   }
   
   .weddingportal .footer_1i h1{
  font-size:28px;
   }
   .weddingportal .footer_1i h1 span{
  margin-left:50px;
  font-size:12px!important;
   }
   .weddingportal .footer_1i{
  margin-top:10px;
  margin-bottom:10px;
  text-align:center;
   }
   .weddingportal.footer_2r{
  text-align:center!important;
  margin-top:10px; 
   }
   .weddingportal .footer_2l{
  text-align:center;
   }
   .weddingportal .shop_1r1{
  text-align:center;  
   }
   .weddingportal .shop_1r1ir{
  margin-top:10px; 
   }
   }
  @media (min-width:576px) and (max-width:767px) {
    .weddingportal .shop_1r1ir{
  text-align:left;
  margin-top:0; 
   }
   }
  @media (min-width:768px) and (max-width:991px) {
    .weddingportal .navbar-expand-md {
  flex-wrap:wrap!important;
  }
  .weddingportal.drop_1{
  left:auto!important;
  right:0!important;
   }
  
   .weddingportal #header .navbar-brand{
  font-size:22px;
  line-height:0.8em;
  padding-bottom:0;
   }
   .weddingportal #header .navbar-brand span{
  margin-left:32px;
  font-size:10px;
   }
   .weddingportal #header .nav-link{
  padding: 20px 6px;
  font-size:12px;
   }
   .weddingportal #header .fs-5{
  font-size:12px!important;
   }
   .weddingportal .px_4{
  padding-left:0;
  padding-right:0; 
   }
   .weddingportal .price_2i .button_1{
  padding-left:20px;
  padding-right:20px;
  }

   .weddingportal .price_m1{
  padding-top:100px!important; 
   }
   .weddingportal #price {
  margin-bottom:0;
  }
  .weddingportal .blog_hm2 .button_1{
  padding-left:20px;
  padding-right:20px; 
   }
   .weddingportal .blog_hm2 h4{
  font-size:24px;
   }
   .weddingportal .team_h1i2il h4{
   font-size:24px;
   }
  
   }
  @media (min-width:871px) and (max-width:991px) {
    .weddingportal #header .nav-link{
  padding: 20px 10px;
   }
   }
  @media (min-width:992px) and (max-width:1200px) {
    .weddingportal.navbar-expand-md {
  flex-wrap:wrap!important;
  }
  .weddingportal .drop_1{
  left:auto!important;
  right:0!important;
   }
   .weddingportal #header .nav-link{
  #header .nav-link{
  padding: 20px 10px;
   }
   .weddingportal .px_4{
  padding-left:0;
  padding-right:0; 
   }
   .weddingportal .price_m3{
  position:static!important;
  width:100%; 
   }
   .weddingportal.price_m1{
  padding-top:100px!important; 
   }
   .weddingportal #price {
  margin-bottom:0;
  }
  .weddingportal .footer_1i h1{
  font-size:34px;
   }
   .weddingportal .footer_1i h1 span{
  margin-left:45px;
  font-size:12px!important;
   }
   .weddingportal.footer_1i p{
  font-size:14px!important;
   }
   .weddingportal.footer_1i li{
  font-size:14px!important;
   }
   .weddingportal.footer_1i h6{
  font-size:14px!important;
   }
   .weddingportal .footer_1i h5{
  font-size:17px;
   }
   }
  }
  @media (min-width:1201px) and (max-width:1300px) {
    .weddingportal.price_m3{
  bottom:-28%;
  
   }
   .weddingportal.px_4{
  padding-left:0;
  padding-right:0; 
   }
   }
  @media (min-width:1400px) and (max-width:2000px) {
    .weddingportal.price_m3 {
      bottom: -15%;
  }
  .weddingportal #price {
      margin-bottom: 90px;
  }
   }
  
  
  
   .weddingportal .image-container {
    position: relative;
    display: inline-block;
  }
  
  .weddingportal  .image-container img {
    display: block;
    width: 100%;
  }
  
  .weddingportal .image-container .overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%; /* Adjust as needed */
  }
  /* serch */
 

.search-bar {
  position: absolute;
  bottom: 49px;
  left: 50%;
  transform: translateX(-50%);
  background: rgb(37 31 31 / 80%);
  padding: 10px;
  
  width: 100%;
}

.search-bar select,
.search-bar input {
    margin: 5px;
}

.search-bar h2 {
    color: red;
}
.j .form-control{
  border-color: #f9e5db;
  box-shadow: none;
  border-radius: 0;
  height: 62px;
  font-size: 14px;
  width: 22%;
 }

@media (min-width:300px) and (max-width:667px) {
  .j .form-control {
    border-color: #f9e5db;
    box-shadow: none;
    border-radius: 0;
    height: 62px;
    font-size: 14px;
    width: 100%;
    margin-right: 91px;

 }
 .j.search-bar {
  position: absolute;
  bottom: 49px;
  left: 50%;
  transform: translateX(-50%);
  background: rgb(37 31 31 / 80%);
  padding: 10px;
  
  width: 100%;
}

}


  /* serch end */
  .jaishree {
    background: linear-gradient(to right, #ffdde1, #FFF);
    text-align: center;
    justify-content: center;
  }

  .jaishree h1 {
    font-family: 'Parisienne', cursive;
    font-size: 50px;
    text-shadow: 3px 3px 5px lightpink;
  }

  .jaishree .table {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px 0;
  }

  .jaishree .block {
    margin: 20px;
    width: calc(33.333% - 40px);
    min-height: 1px;
    background: #fff;
    color: #000;
    box-shadow: 3px 4px 5px 2px gray;
    border-radius: 20px;
    overflow: hidden;
    text-align: center;
  }

  .jaishree .popular .price {
    color: lightpink;
  }

  .jaishree .header {
    height: 150px;
    padding: 40px 20px;
    text-transform: uppercase;
  }

  .jaishree .heading {
    font-size: 1.4rem;
    font-weight: 700;
  }

  .jaishree .price {
    color: gray;
    padding: 20px;
  }

  .jaishree .price > span {
    display: inline-block;
  }

  .jaishree .currency {
    margin-top: 10px;
    vertical-align: top;
    font-size: 2rem;
    font-weight: 700;
    line-height: 1;
    opacity: 0.6;
  }

  .jaishree .value {
    font-size: 5rem;
    font-weight: 300;
    line-height: 1;
  }

  .jaishree .duration {
    margin-top: 10px;
    vertical-align: top;
    font-size: 1.4rem;
    line-height: 1;
    opacity: 0.5;
  }

  .jaishree .body ul {
    list-style: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }

  .jaishree .body ul li {
    display: block;
    padding: 10px 20px;
    margin: 0;
  }

  .jaishree .body ul li:nth-child(2n) {
    background: linear-gradient(to left, lightpink, pink, #fff);
  }

  .jaishree .body ul li:nth-child(2n+1) {
    background: linear-gradient(to left, #fff, lightpink);
  }

  .jaishree .footer {
    padding: 40px 0 0;
  }

  .jaishree .button,
  .jaishree .button:link,
  .jaishree .button:visited {
    display: block;
    padding: 20px;
    text-decoration: none;
    text-transform: uppercase;
    background: linear-gradient(to left, #fff, lightpink);
    color: gray;
  }

  .jaishree .button:hover {
    color: #fff;
    background: pink;
  }

  .jaishree footer a {
    text-decoration: none;
    text-align: center;
    font-size: 30px;
    color: #000;
    font-family: 'Parisienne', cursive;
    text-shadow: 3px 3px 5px lightpink;
  }

  .jaishree footer {
    position: fixed;
    left: 0;
    bottom: 10px;
    width: 100%;
    color: white;
    text-align: center;
  }

  @media (max-width: 767px) {
    .jaishree .block {
      width: 100%;
      margin: 20px 0;
    }
  }

  /* wedding form */
  .jaishreeshyam {
    background: linear-gradient(to right, #ffdde1, #FFF);
    text-align: center;
    font-family: 'Roboto Condensed', sans-serif;
  }

  .jaishreeshyam h1 {
    font-family: 'Parisienne', cursive;
    font-size: 50px;
    text-shadow: 3px 3px 5px lightpink;
  }

  .jaishreeshyam .form-container {
    background: #fff;
    padding: 20px;
    margin: 20px auto;
    width: 80%;
    max-width: 800px;
    box-shadow: 3px 4px 5px 2px gray;
    border-radius: 10px;
  }

  .jaishreeshyam.form-container h2 {
    font-family: 'Parisienne', cursive;
    color: lightpink;
    margin-bottom: 20px;
  }

  .jaishreeshyam.form-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 15px;
  }

  .jaishreeshyam .form-group {
    width: 48%;
    margin-bottom: 15px;
    text-align: left;
    padding: 10px;
    border-radius: 5px;
  }

  .jaishreeshyam.form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .jaishreeshyam  .form-group input,
  .jaishreeshyam .form-group select,
  .jaishreeshyam .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-family: 'Roboto Condensed', sans-serif;
  }

  .jaishreeshyam .form-group input:focus,
  .jaishreeshyam .form-group select:focus,
  .jaishreeshyam  .form-group textarea:focus {
    outline: none;
    border-color: lightpink;
  }

  .jaishreeshyam  .form-group:nth-child(2n) {
    background: linear-gradient(to left, lightpink, pink, #fff);
  }

  .jaishreeshyam  .form-group:nth-child(2n+1) {
    background: linear-gradient(to left, #fff, lightpink);
  }

  .jaishreeshyam .form-actions {
    text-align: center;
    margin-top: 20px;
  }

  .jaishreeshyam .form-actions button {
    padding: 10px 20px;
    background: lightpink;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s;
  }

  .jaishreeshyam  .form-actions button:hover {
    background: pink;
  }

  @media (max-width: 767px) {
      .jaishreeshyam .form-group {
      width: 100%;
    }
  }

/* wedding form end */

/* wedding all page css*/
.jamain {
  margin: 0;
  padding: 0;
  background-color: whitesmoke;
}
.jamain  .con{
  border: 1px solid burlywood;
}
/* range value css */
.jamain  .slider {
-webkit-appearance: none;
width: 85%;
height: 15px;
background: rgba(54, 4, 4, 0.9);
outline: none;
opacity: 0.7;
-webkit-transition: .2s;
border-radius: 15px;
transition: opacity .2s;
}

.jamain.slider:hover {
opacity: 1;
}

.jamain.slider::-webkit-slider-thumb {
-webkit-appearance: none;
appearance: none;
width: 15px;
height: 15px;
background: rgb(227, 170, 95);
cursor: pointer;
}

.jamain.slider::-moz-range-thumb {
width: 15px;
height: 15px;
background: rgba(54, 4, 4, 0.9);
cursor: pointer;
}
.jamain .box{
width: 100px;
height: 60px;
background-color: whitesmoke;
border: none;
border-bottom: 2px solid blue;

}
.jamain.drop{
border: 2px solid burlywood;
background-color: burlywood;
}
.jamain .but{
background-color: rgb(68, 12, 12);
border: 1px solid rgb(68, 12, 12);
width: 190px;

}
.jamain .put{
width: 220px;
}
.jamain.but-1{
font-size: 14px;
font-weight: 550;
border: 1px solid white;
color: black;
}

/* wedding about us */
.weddingportal .about_1l2{
  top:12%;
  right:0; 
   }
   .weddingportal .about_1l3{
  bottom:-82%;
  right:0; 
   }
   .weddingportal #about{
  padding-bottom:90px; 
   }
   .weddingportal .events_1l2{
  padding-top:46%;
  transition: 0.3s; 
   }
   .weddingportal .events_1l3{
  padding-top:46%;
  display:none; 
  transition: 0.3s;
   }
   .weddingportal .events_1l:hover .events_1l3{
  display:block; 
  transition: 0.3s;
   }
   .weddingportal .events_1l:hover .events_1l2{
  display:none; 
  transition: 0.3s;
   }
   .weddingportal .events_2l{
  border-right:1px solid #d3e9ef; 
   }
   
   .weddingportal .book_1l .progress-bar {
      background: #F9F9F9;
      border-radius: 0;
  }
  .weddingportal .book_1l .progress {
      background-color: #c78665;
      height: 6px;
      overflow: hidden;
      border-radius: 0;
  }
  /*********************about_end****************/
  
  
  
  @media screen and (max-width : 767px){
      .weddingportal .about_1l2{
  display:none; 
   }
   .weddingportal .about_1l3{
  display:none; 
   }
   .weddingportal .about_1l1 img{
  width:100%;
   }
   .weddingportal .about_1r{
  margin-top:15px;
  text-align:center;
   }
   .weddingportal .about_1r p{
  text-align:left;
   }
   .weddingportal #about {
  padding-bottom: 30px;
  }
  .weddingportal .events_1lm{
  margin-top:8px;
  margin-bottom:8px; 
   }
    .weddingportal .events_2l {
  margin-top:8px;
  margin-bottom:8px;
  border:none; 
   }
   .weddingportal .book_1l h1{
  text-align:center;
   }
   .weddingportal .book_1l{
  margin-bottom:15px; 
   }
   .weddingportal .book_1r h1{
  text-align:center;
   }
   .weddingportal .book_1ril .form-control{
  margin-top:10px;  
   }
   .weddingportal .recep_1l {
  margin-top:8px;
  margin-bottom:8px; 
   }
  
    .weddingportal .services_1l{
  margin-top:8px;
  margin-bottom:8px;
  text-align:center; 
   }
   .weddingportal .services_1lir{
  margin-top:10px; 
   }
   }
  
  
  @media (min-width:576px) and (max-width:767px) {
  
   }
   
  @media (min-width:768px) and (max-width:991px) {
       .weddingportal .about_1ril .font_60{
  font-size:40px; 
   }
   .weddingportal #about {
   padding-bottom: 30px;
  }
  .weddingportal .events_1l2  h4{
  font-size:18px; 
   }
   .weddingportal .events_1l2  h5{
  font-size:18px; 
   }
   .weddingportal .services_1lil span{
  font-size:26px!important;  
   }
   }
  
  @media (min-width:992px) and (max-width:1200px) {
  
   }
  @media (min-width:1400px) and (max-width:2000px) {
      .weddingportal .about_1l2 {
  right: 20%;
  }
  .weddingportal .about_1l3 {
  right: 5%;
  }
   }
  
  



/* weddig abou us end */
/* wedding listing */
.weddinglisting {
  margin: 0;
  padding: 0;
  font-family: cursive;
}
.weddinglisting .hhh {
  background-image: url('https://t4.ftcdn.net/jpg/03/27/01/01/360_F_327010124_kHdzh2rPB96wUm514wKYdziPsKiIYk78.jpg');
  height: 250px;
  opacity: 0.8;
  background-repeat: no-repeat;
  background-size: cover; 
}
.weddinglisting .profile-img {
  max-width: 180px;
  width: 100%;
}
.weddinglisting .btn-custom {
  background-color: #007bff;
  color: white;
}
.weddinglisting  .btn-custom:disabled {
  background-color: #6c757d;
  color: white;
}


/* wedding listing eng */

/* wedding all page css end */

/* weddings blog */
/*
Template Name: Matrimonial
File: Layout CSS
Author: TemplatesOnWeb
Author URI: https://www.templateonweb.com/
Licence: <a href="https://www.templateonweb.com/license">Website Template Licence</a>
*/

/*********************blog****************/
.weddingportal .blog_1l1i1{
  width:90%;
  left:5%;
  bottom:-22%; 
   }
   .weddingportal .blog_1l1o{
  margin-top:250px; 
   }
   .weddingportal .blog_1r1 h6 span{
  width:20px;
  height:20px;
  line-height:20px;
  font-size:12px;
  text-align:center; 
   }
   .weddingportal .blog_1dt12 .form-control{
  border-top:0; 
  border-right:0; 
  border-left:0; 
   }
   
   .weddingportal .contact_1lo hr{
  background-color:#d7906c;
   }
   .weddingportal .contact_2lir{
  border-left:1px solid #f9e5db; 
   }
  /*********************blog_end****************/
  
  
  
  @media screen and (max-width : 767px){
      .weddingportal .event_1l{
  margin-top:8px;
  margin-bottom:8px;
  text-align:center; 
   }
   .weddingportal .event_dt1 h6{
  float:none!important;
  text-align:center; 
   }
   .weddingportal .event_dt1 h2{
  text-align:center;  
   }
   .weddingportal .event_dt1il{
  margin-top:8px;
  margin-bottom:8px; 
   }
   .weddingportal .event_dt2il{
  margin-top:8px;
  margin-bottom:8px;
  text-align:center;  
   }
   .weddingportal.event_dt3il{
  margin-top:10px; 
   }
   .weddingportal .blog_1r{
  margin-top:15px; 
   }
   .weddingportal .blog_1r1  h4{
  text-align:center; 
   }
  
   .weddingportal .blog_1l1i1 {
  width: 100%;
  position:static!important;
  text-align:center;
  }
  .weddingportal .blog_1l1o {
  margin-top: 20px;
  }
  .weddingportal .blog_1dt1 h3{
  text-align:center; 
   }
   .weddingportal .blog_pgdt1i img{
  float:none!important;
  margin-right:0!important;
  width:100%; 
   }
   .weddingportal .blog_1dt1 h4{
  text-align:center; 
   }
   .weddingportal .blog_1dt2il {
  text-align:center;  
   }
   .weddingportal .blog_1dt2ir {
  text-align:center!important;  
   }
  
   .weddingportal .contact_1l {
  margin-top:8px;
  margin-bottom:8px;
  text-align:center; 
   }
   .weddingportal .contact_1 .p-0{
  padding-left:12px!important;
  padding-right:12px!important;  
   }
   .weddingportal .contact_2l{
  text-align:center;
   }
   .weddingportal .contact_2lir {
  border:none;
  }
   .weddingportal.contact_2lir {
  margin-top:10px; 
   }
   .weddingportal .contact_2r {
  margin-top:15px;
  text-align:center; 
   }
   .weddingportal .quote_2l .form-control{
  margin-top:10px; 
   }
   }
  
  
  @media (min-width:576px) and (max-width:767px) {
  
   }
   
  @media (min-width:768px) and (max-width:991px) {
      .weddingportal .event_1li  h4{
   font-size:18px;
   }
   .weddingportal .event_1li  h6{
   font-size:16px;
   }
   .weddingportal .event_dt3i  .button_1{
  padding-left:20px;
  padding-right:20px; 
   }
   .weddingportal .blog_1r1  .font_14{
   font-size:11px; 
   }
   .weddingportal .shop_1r1  li{
   font-size:12px; 
   }
   .weddingportal .blog_1l1o {
  margin-top:30px;
  }
  .weddingportal .blog_1l1i1 {
  width: 100%;
  position:static!important;
  }
  .weddingportal .contact_1l  h4{
   font-size:18px;  
   }
   .weddingportal .contact_1l p{
   font-size:13px;  
   }
   .weddingportal .contact_2lil  .font_50{
   font-size:30px;  
   }
   }
  
  @media (min-width:992px) and (max-width:1200px) {
  
   }
  @media (min-width:1201px) and (max-width:1320px) {
  
   }
  @media (min-width:1400px) and (max-width:2000px) {
      .weddingportal .blog_1l1o {
      margin-top: 280px;
  }
  .weddingportal .blog_pgdt1i  img{
  width:300px;
  height:300px; 
   }
   }
  
  
  

/* weddings blog css end */

/* profile css */

.wedding_porfiledetail{
  background-color: whitesmoke;
  font-family: "Philosopher", sans-serif;
  font-style: normal;
}
.wedding_porfiledetail .on2{
  font-size: 14px;
}
.wedding_porfiledetail .table td,.wedding_porfiledetail .table td {
border: none;
padding: 5px;
}
.wedding_porfiledetail .con{
font-size: 22px;
}
.wedding_porfiledetail .card{
border: none;
}
@media only screen and (max-width: 975px) {
.wedding_porfiledetail .view {
font-size: 15px;
}
.wedding_porfiledetail .count{
font-size: 11px;

}
}
@media only screen and (max-width: 767px) {
.wedding_porfiledetail .card-body {
text-align: center;
}
}
@media only screen and (max-width: 767px) {
.wedding_porfiledetail .table td, .wedding_porfiledetail .table td {
font-size: 14px;
}
}
@media only screen and (max-width: 500px) {
.wedding_porfiledetail .con {
font-size: 14px;
}
}
@media only screen and (max-width: 465px) {
.wedding_porfiledetail .table td, .wedding_porfiledetail  .table td {
font-size: 11px;
}
}
@media only screen and (max-width: 503px) {
.wedding_porfiledetail .dot-1{
font-size: 10px;
}
}
@media only screen and (max-width: 390px) {
.wedding_porfiledetail .table td, .wedding_porfiledetail .table td {
font-size: 9px;
}
.wedding_porfiledetail .dot-1{
font-size: 1px;
}
.wedding_porfiledetail .con{
font-size: 12px;
}
}
@media only screen and (max-width: 340px) {
.wedding_porfiledetail .table td, .wedding_porfiledetail .table td {
font-size: 8px;
}
.wedding_porfiledetail .on-2{
font-size: 20px;
}
.wedding_porfiledetail  .on-3{
font-size: 17px;
}
.wedding_porfiledetail .on-4{
font-size: 15px;
}
.wedding_porfiledetail .on-5{
font-size: 10px;
}
}
@media only screen and (max-width: 316px) {
.wedding_porfiledetail .table td,.wedding_porfiledetail .table td {
font-size: 7px;
}
}

/* profile css end */
/* jaishree css wedding end */



.brnew .card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.5s;
  border: 2px solid;
  border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet);
  border-image-slice: 1;
  position: relative;
}

.brnew .card1 {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.5s;
  border: 2px solid;
  /* border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet); */
  border-image-slice: 1;
  position: relative;
  background-image: url('https://www.ae.photojaanic.com/blog/wp-content/uploads/sites/2/2023/07/image3-1.jpg');
  background-size: cover;
}

.brnew .circle {
  position: relative;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  border: 6px solid #ffef00;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  margin: 0 auto;

}

.brnew .birthday_user {
  width: 100%;
}


.brnew  .content {
  text-align: center;
  padding: 20px;
}

.brnew .sender {
  display: inline-block;
  background: #ffef00;
  color: #0c0c0c;
  padding: 10px 20px;
  border-radius: 20px;
  font-style: italic;
  font-size: 16px;
  margin-top: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.brnew .happy-birthday {
  font-size: 30px;
  color: #ffef00;
  text-shadow: 6px 2px 4px rgb(9, 9, 9);
}

@media only screen and (max-width: 600px) {
  .brnew  .happy-birthday {
      font-size: 25px;
      color: #ffef00;
  }

  .brnew   .animated-text {
      font-size: 22px;
  }
}

.brnew .cake_image {
  width: 30%;
}

.brnew .share-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.brnew .share-buttons button {
  border-radius: 50%;
  /* Rounded borders */
  border: none;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s, color 0.3s;
}

.brnew .share-buttons button i {
  font-size: 24px;
  transition: transform 0.3s;
  animation: rotate 2s infinite linear;
  /* 3D rotation animation */
}

@keyframes rotate {
  from {
      transform: rotateY(0deg);
  }

  to {
      transform: rotateY(360deg);
  }
}

.brnew .share-buttons button:hover {
  color: #0056b3;
  transform: scale(1.2);
  /* Scale up on hover */
}


.brnew .animated-text {
  animation: textAnimate 5s infinite;
  color: green;
  font-weight: bold;
  text-align: center;
}

.brnew .animated-text span {
  display: inline-block;
  animation: textColorChange 5s infinite;
}

@keyframes textColorChange {

  0%,
  100% {
      color: green;
  }

  33% {
      color: white;
  }

  66% {
      color: orange;
  }
}

.brnew .image-container {
  text-align: center;
}

.brnew .wish_cartoon {
  width: 65%;
  padding-bottom: 10%;
}

.brnew .second_card {
  background: linear-gradient(to right, #ff00008a, #3b0b4ded);
  position: relative;
}



.brnew .sender_image {
  width: 100%;
  border: 4px solid #d79c0f;
  border-radius: 50%;
}


.brnew .sender_card_design {
  border-radius: 40px !important;
  border: none;
  background: linear-gradient(to right, #fc0, #e60d56);
}

.brnew a {
  color: #eab310;
  text-decoration: none;
  background-color: transparent;
  font-size: 25px;
  text-align: center;
}

.brnew .speech-bubble {
  color: #f7f9f1;
  text-shadow: 6px 2px 4px rgb(29, 2, 37);
}

.brnew .left_side_bloon {
  position: absolute;
  width: 66%;
  margin-left: -21%;
  margin-top: 1%;
}

.brnew .right_side_bloon {
  position: absolute;
  width: 66%;
  margin-left: 50%;
 
}

@media only screen and (max-width: 600px) {

  .brnew  .right_side_bloon {

      margin-top: 20%;
  }
  .brnew  .left_side_bloon {
      margin-top: 30%;
}
.footerNew .footer-top {
  padding-top: 25px;
  padding-bottom: 0px;
}

.footerNew .widget {
  position: relative;
  padding-bottom: 9px;
  margin-bottom: 7px;
}
}